const staticFilters = [
  {
    label: 'Child name', name: 'name',
  },
  {
    label: 'Embg', name: 'embg',
  },
  {
    label: 'Address', name: 'address',
  },
  {
    label: 'Parent name', name: 'parentName',
  },
  {
    label: 'Parent embg', name: 'parentEmbg',
  },
  {
    label: 'Contract number', name: 'contractNumber',
  },
  {
    label: 'Phone number', name: 'phoneNumber',
  },
];

export default staticFilters;
