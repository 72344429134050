/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { openModal } from '@store/modal';
import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { Button } from 'reactstrap';
import Sms from '../types/Sms';
import { SMS_STATUSES_ENUM, SMS_STATUSES_MAPPING } from '../types/StatusEnum';

const SmsColumns = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const handleMessageClick = (message: string) => {
    dispatch(openModal({
      Component: AnnouncementModal,
      componentProps: { content: message },
      title: t('Message'),
      open: true,
    }));
  };

  const columns: TableColumn<Sms>[] = [
    {
      name: t('Sender'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.createdBy.name} ${row.createdBy.surname}`}</p>
      ),
    },
    {
      name: t('Receiver'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.parent.name} ${row.parent.surname}`}</p>
      ),
    },
    {
      name: t('Message'),
      minWidth: '200px',
      cell: (row) => (
        <Button
          outline
          color="outline"
          onClick={() => handleMessageClick(row.message)}
          block
          style={{
            margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit', maxWidth: '200px',
          }}
        >
          <p
            className="cursor-pointer"
            style={{
              whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', textAlign: 'start', marginBottom: '0.5rem',
            }}
          >
            {row.message}
          </p>
        </Button>
      ),
    },
    {
      name: t('Phone number'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.phoneNumber}`}</p>
      ),
    },
    {
      name: t('Status'),
      minWidth: '100px',
      cell: (row) => (
        <p
          className="m-0"
          key={row.id}
        >
          {SMS_STATUSES_MAPPING[row.statusText] === SMS_STATUSES_ENUM.Delivered ? <span style={{ color: 'Green' }}>{t('Delivered')}</span> : ''}
          {SMS_STATUSES_MAPPING[row.statusText] === SMS_STATUSES_ENUM.Failed || !SMS_STATUSES_MAPPING[row.statusText] ? <span style={{ color: 'Red' }}>{t('Failed')}</span> : ''}
          {SMS_STATUSES_MAPPING[row.statusText] === SMS_STATUSES_ENUM.Pending ? <span style={{ color: 'Orange' }}>{t('Pending')}</span> : ''}
        </p>
      ),
    },
    {
      name: t('Sent at'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${moment(new Date(row.createdAt)).format('DD. MMM Y. HH:mm')}`}</p>
      ),
      sortable: true,
      sortFunction: (
        (a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
      ),
    },
  ];

  return columns;
};

export default SmsColumns;
