import * as yup from 'yup';
import CustomPhoneRegex from '@src/components/forms/CustomPhoneReg';
import { TFunction, useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import Tenant from '../types/Tenant';
import databaseValidationSchema from './DatabaseValidationSchema';
import welcomeEmailValidationSchema from './WelcomeEmailValidationScheme';

type ValidationFields = {
  [x: string]: any;
}

const getModifiedValidationFields = (commonValidationFields:ValidationFields,
  selectedIsOnNewSystem: SingleValue<SelectedOptions> | undefined) => {
  if (selectedIsOnNewSystem?.value) {
    delete commonValidationFields.tenantSubdomain;
    delete commonValidationFields.oldSystemId;
    delete commonValidationFields.databaseHost;
    delete commonValidationFields.databaseName;
    delete commonValidationFields.databasePassword;
    delete commonValidationFields.databasePort;
    delete commonValidationFields.databaseUsername;
  }

  return commonValidationFields;
};

const useCommonFields = (t: TFunction<'translation', undefined>) => {
  const databaseSchema = databaseValidationSchema(t);
  const welcomeEmailSchema = welcomeEmailValidationSchema(t);
  const uniqueValueMessage = t('Value must be unique');
  const commonValidationFields = {
    identificationNumber: yup
      .string()
      .required(t('Identification number is required')),
    taxId: yup
      .number()
      .typeError(t('This field is required and you can only enter positive numbers'))
      .positive(t('You can only enter positive numbers'))
      .test(
        'len',
        t('Must be exactly 9 characters'),
        (val) => (val && val >= 100000010 && val <= 999999999) || false,
      )
      .integer(),
    name: yup.string().required(t('Name is required')),
    contractNumber: yup.string().required(t('Contract number is required')),
    email: yup
      .string()
      .email(t('Enter email in valid format'))
      .required(t('Email is required')),
    phoneNumber: yup
      .string()
      .required(t('Phone number is required'))
      .matches(CustomPhoneRegex, t('Please enter contact number in valid format')),
    director: yup.string().required(t('Director is required')),
    ...databaseSchema,
    ...welcomeEmailSchema,
    website: yup.string().nullable().url(t('Please enter URL in valid format')),
    tenantSubdomain: yup
      .string()
      .required(t('Tenant subdomain is required'))
      .matches(
        /^(?:(?!-{2,})(?!.*\.\.)[a-z0-9](?:[-.]?[a-z0-9])*)?$/,
        t('Subdomain can only contain alphanumeric characters and set of special characters(. or -). ')
        + t('Additionally, you cannot enter 2 special characters in a row, or at the beginning and the end of the subdomain.'),
      ),
    address: yup.string().required(t('Address is required')),
    oldSystemId: yup
      .number()
      .integer(t('Positive number is required'))
      .typeError(t('Positive number is required'))
      .min(0, t('Positive number is required'))
      .required(t('External ID is required')),
    city: yup.string().required(t('City is required')),
    country: yup.string().required(t('Country is required')),
    payer: yup.string().required(t('Payer is required')).nullable(),
    payee: yup.string().required(t('Payee is required')).nullable(),
    purposeOfPayment: yup.string().required(t('Purpose of payment is required')).nullable(),
    maxNumberOfUsers: yup.number().typeError(t('This field is required')).integer(),
    maxNumberOfObjects: yup
      .number()
      .typeError(t('This field is required'))
      .integer(),
    privacyPolicyText: yup
      .string()
      .required(t('Privacy policy is required')),
    addressNumber: yup
      .string()
      .required(t('Address number is required')),
    municipality: yup
      .string()
      .required(t('Municipality is required')),
    postalCode: yup
      .string()
      .required(t('Postal code is required'))
      .matches(/^[1-3]\d{4}$/, t('Postal code must start with 1,2 or 3 and have 4 more digits')),
  };
  return { commonValidationFields, uniqueValueMessage };
};

const EditTenantValidationSchema = (tenants: Tenant[], checkDefault: Tenant,
  selectedIsOnNewSystem: SingleValue<SelectedOptions> | undefined) => {
  const { t } = useTranslation();
  const { commonValidationFields, uniqueValueMessage } = useCommonFields(t);
  return yup.object().shape({
    ...getModifiedValidationFields(commonValidationFields, selectedIsOnNewSystem),
    identificationNumber: commonValidationFields.identificationNumber.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some(
        (ten) => ten.identificationNumber === value
            && checkDefault.identificationNumber !== value,
      ),
    ),
    invoiceDueDateByNumber: yup
      .mixed()
      .nullable()
      .test('is-number-or-null', t('Number is required in range of 1 to 60'), (value) => value === null || value === '' || (value >= 1 && value <= 60)),
    taxId: commonValidationFields.taxId.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.taxId === value && checkDefault.taxId !== value),
    ),
    email: commonValidationFields.email.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.email === value && checkDefault.email !== value),
    ),
  });
};

const AddTenantValidationSchema = (tenants: Tenant[],
  selectedIsOnNewSystem: SingleValue<SelectedOptions> | undefined) => {
  const { t } = useTranslation();
  const { commonValidationFields, uniqueValueMessage } = useCommonFields(t);

  return yup.object().shape({
    ...getModifiedValidationFields(commonValidationFields, selectedIsOnNewSystem),
    identificationNumber: commonValidationFields.identificationNumber.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.identificationNumber === value),
    ),
    taxId: commonValidationFields.taxId.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.taxId === value),
    ),
    email: commonValidationFields.email.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.email === value),
    ),
    invoiceDueDateByNumber: yup
      .mixed()
      .nullable()
      .test('is-number-or-null', t('Number is required in range of 1 to 60'), (value) => value === null || value === '' || (value >= 1 && value <= 60)),
    isOnNewSystem: yup.mixed().test('required', t('Is on new system select is required'), () => selectedIsOnNewSystem?.value === 0 || selectedIsOnNewSystem?.value === 1),
  });
};

const UserEditTenantValidationSchema = (tenants: Tenant[], checkDefault: Tenant) => {
  const { t } = useTranslation();
  const { commonValidationFields, uniqueValueMessage } = useCommonFields(t);
  return yup.object().shape({
    name: commonValidationFields.name,
    address: commonValidationFields.address,
    city: commonValidationFields.city,
    director: commonValidationFields.director,
    phoneNumber: commonValidationFields.phoneNumber,
    website: commonValidationFields.website,
    payer: commonValidationFields.payer,
    payee: commonValidationFields.payee,
    purposeOfPayment: commonValidationFields.purposeOfPayment,
    invoiceDueDateByNumber: yup
      .mixed()
      .nullable()
      .test('is-number-or-null', t('Number is required in range of 1 to 60'), (value) => value === null || value === '' || (value >= 1 && value <= 60)),
    bank: yup.string().required(t('This field is required')),
    identificationNumber: commonValidationFields.identificationNumber.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some(
        (ten) => ten.identificationNumber === value
            && checkDefault.identificationNumber !== value,
      ),
    ),
    email: commonValidationFields.email.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.email === value && checkDefault.email !== value),
    ),
    taxId: commonValidationFields.taxId.test(
      'unique',
      uniqueValueMessage,
      (value) => !tenants.some((ten) => ten.taxId === value && checkDefault.taxId !== value),
    ),
    accountNumber: yup
      .number()
      .typeError(t('This field is required and you can only enter positive numbers'))
      .positive(t('You can only enter positive numbers'))
      .test(
        'unique',
        uniqueValueMessage,
        (value) => !tenants.some((ten) => ten.taxId === value && checkDefault.taxId !== value),
      )
      .integer(),
    vat: yup.number()
      .min(0, t('You can only enter positive numbers'))
      .max(100, t('VAT must be at most 100'))
      .transform((value, originalValue) => (originalValue === '' ? 0 : value)),
  });
};

export { UserEditTenantValidationSchema, EditTenantValidationSchema, AddTenantValidationSchema };
