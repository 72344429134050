import {
  Card, CardBody, CardHeader, CardSubtitle, CardTitle,
  Col,
  Progress,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  subtitle?: string;
  button?: JSX.Element;
  className?: string;
  maxNumberOfChildren: number;
  activeChildren: number;
  maxNumberOfUsers: number;
  activeUsers: number;
  activeObjects: number;
  maxNumberOfObjects: number;
}

const TenantFormHeader: React.FC<Props> = ({
  title, subtitle, button, className = '', maxNumberOfChildren, activeChildren, maxNumberOfUsers, activeUsers,
  activeObjects, maxNumberOfObjects,
}) => {
  const { t } = useTranslation();
  return (
    <Card className={className}>
      <CardHeader className="d-flex">
        <div>
          <CardTitle tag="h4">{title}</CardTitle>
          <CardSubtitle className="mt-1">{subtitle}</CardSubtitle>
        </div>
        {button}
      </CardHeader>
      <CardBody>
        <Row>
          <Col md={4}>
            <CustomLabel name={t('Max Number Of Children')} />
            <Col
              md={12}
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
                margin: 0,
                fontSize: '12px',
              }}
            >
              <div>{maxNumberOfChildren}</div>
            </Col>
            <Col md={12}><Progress style={{ height: '16px' }} min={0} max={maxNumberOfChildren} value={activeChildren} aria-label="Max number of children">{activeChildren}</Progress></Col>
          </Col>
          <Col md={4}>
            <CustomLabel name={t('Max Number Of Users')} />
            <Col
              md={12}
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
                margin: 0,
                fontSize: '12px',
              }}
            >
              <div>{maxNumberOfUsers}</div>
            </Col>
            <Col md={12}><Progress style={{ height: '16px' }} min={0} max={maxNumberOfUsers} value={activeUsers} aria-label="Max number of children">{activeUsers}</Progress></Col>
          </Col>
          <Col md={4}>
            <CustomLabel name={t('Max Number Of Objects')} />
            <Col
              md={12}
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'end',
                margin: 0,
                fontSize: '12px',
              }}
            >
              <div>{maxNumberOfObjects}</div>
            </Col>
            <Col md={12}><Progress style={{ height: '16px' }} min={0} max={maxNumberOfObjects} value={activeObjects} aria-label="Max number of objects">{activeObjects}</Progress></Col>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default TenantFormHeader;
