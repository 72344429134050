/* eslint-disable no-return-assign */
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from 'reactstrap';
import { Plus } from 'react-feather';
import { closeModal, openModal } from '@store/modal';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import toast from 'react-hot-toast';
import useFilter from '@src/utility/hooks/useFilter';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';

import HumanResourcesState from '../../types/HumanResourceState';
import {
  deleteWorkReport,
  getSchoolYears,
  getWorkReports,
  getWorkReportTypes,
  setWorkReports,
} from '../../store';
import WorkReportsColumns from './WorkReportsColumns';
import { WorkReport } from '../../types/portfolio/WorkReport';
import WorkReportForm from './WorkReportsForm';

const WorkReportsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const { onFilterChange, query } = useFilter();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [loading] = useState(false);

  useEffect(() => {
    dispatch(getSchoolYears());
    dispatch(getWorkReportTypes());
  }, []);

  const openExamplaryActivityFormModal = (
    workReport?: WorkReport,
  ) => {
    const urlQuery = new URLSearchParams(query);
    dispatch(openModal({
      Component: WorkReportForm,
      title: t('Add new work report'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        workReport,
        schoolYearIds: urlQuery.get('schoolYearIds'),
      },
    }));
  };

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Work report will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteWorkReport(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredWorkReports = humanResourceStore
                .workReports.filter((item) => item.id !== id);
              dispatch(setWorkReports(filteredWorkReports));
              toast.success(t('Work report successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      onFilterChange(
        'schoolYearIds',
        String(humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1].value),
      );
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (humanResourceStore.currentHumanResource.id) {
      onFilterChange('userId', String(humanResourceStore.currentHumanResource.id));
    }
  }, [humanResourceStore.currentHumanResource]);

  useEffect(() => {
    const urlQuery = new URLSearchParams(query);

    const schoolYearId = urlQuery.get('schoolYearIds');
    const userId = urlQuery.get('userId');

    if (schoolYearId && userId) {
      dispatch(getWorkReports(query));
    }
  }, [query]);

  return (
    <DataTableWrapper
      dataSource={humanResourceStore.workReports}
      title={t('Work reports')}
      subtitle={t('See, manage and filter work reports table')}
      columns={WorkReportsColumns(
        { handleDeleteClick, handleEditClick: openExamplaryActivityFormModal },
      )}
      dataLoaded={!loading}
      filters={[{
        label: 'School year', name: 'schoolYearIds', options: humanResourceStore.schoolYears, defaultValue: humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1],
      }]}
      onChange={onFilterChange}
      query={query}
      showSearch={false}
      functionButton={(
        isManagePermissionActive(humanResourcePermissions, 'portfolioWorkReports')
          ? (
            <Button
              color="primary"
              className="text-nowrap mb-1 mt-2"
              outline
              onClick={() => openExamplaryActivityFormModal()}
            >
              <div className="d-flex justify-content-center">
                <Plus size={15} />
                &nbsp;
                <span>{t('Add work report')}</span>
              </div>
            </Button>
          ) : <></>
      )}
    />
  );
};

export default WorkReportsTab;
