import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import CustomLabel from '@src/components/forms/CustomLabel';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import {
  createNewHumanResources,
  editHumanResources,
} from '../store';
import { HumanResourceData, HumanResourceSubmitData } from '../types/HumanResource';

const HumanResourceManagement = (
  { humanResourcesData } : { humanResourcesData: HumanResourceData },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<HumanResourceSubmitData>({
    reValidateMode: 'onChange',
    defaultValues: humanResourcesData,
  });

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values: HumanResourceSubmitData) => {
    try {
      const newHumanResource = {
        ...values,
        dateOfBirth: formatDateToShortDate(values.dateOfBirth),
      };

      if (humanResourcesData.id !== undefined) {
        await dispatch(editHumanResources(
          { id: +humanResourcesData.userId, data: newHumanResource },
        ))
          .then((res) => {
            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              toast.success(t('Staff successfully updated'), { position: 'top-right', duration: 3000 });
            }
          });
      } else {
        await dispatch(createNewHumanResources(newHumanResource)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Staff successfully added'), { position: 'top-right', duration: 3000 });
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Card>
        <CardBody>
          <Row>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Note')} />
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <Input id="note" invalid={!!errors.note} {...field} />
                )}
              />
              {errors?.note?.message
              && <CustomFormFeedback message={errors.note.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Education')} />
              <Controller
                name="education"
                control={control}
                render={({ field }) => (
                  <Input id="education" invalid={!!errors.education} {...field} />
                )}
              />
              {errors?.education?.message
              && <CustomFormFeedback message={errors.education.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Personal email')} />
              <Controller
                name="personalEmail"
                control={control}
                render={({ field }) => (
                  <Input id="personalEmail" invalid={!!errors.personalEmail} {...field} />
                )}
              />
              {errors?.personalEmail?.message
              && <CustomFormFeedback message={errors.personalEmail.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Business email')} />
              <Controller
                name="businessEmail"
                control={control}
                render={({ field }) => (
                  <Input id="businessEmail" invalid={!!errors.personalEmail} {...field} />
                )}
              />
              {errors?.personalEmail?.message
              && <CustomFormFeedback message={errors.personalEmail.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Address')} />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <Input id="address" invalid={!!errors.address} {...field} />
                )}
              />
              {errors?.address?.message
              && <CustomFormFeedback message={errors.address.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Municipality')} />
              <Controller
                name="municipality"
                control={control}
                render={({ field }) => (
                  <Input id="municipality" invalid={!!errors.municipality} {...field} />
                )}
              />
              {errors?.municipality?.message
              && <CustomFormFeedback message={errors.municipality.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('City')} />
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Input id="city" invalid={!!errors.city} {...field} />
                )}
              />
              {errors?.city?.message
              && <CustomFormFeedback message={errors.city.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Phone number')} />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <Input id="phoneNumber" invalid={!!errors.phoneNumber} {...field} />
                )}
              />
              {errors?.phoneNumber?.message
              && <CustomFormFeedback message={errors.phoneNumber.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Business phone number')} />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <Input id="businessPhoneNumber" invalid={!!errors.phoneNumber} {...field} />
                )}
              />
              {errors?.phoneNumber?.message
              && <CustomFormFeedback message={errors.phoneNumber.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Personal id')} />
              <Controller
                name="personalId"
                control={control}
                render={({ field }) => (
                  <Input id="personalId" invalid={!!errors.personalId} {...field} />
                )}
              />
              {errors?.personalId?.message
              && <CustomFormFeedback message={errors.personalId.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Embg')} />
              <Controller
                name="embg"
                control={control}
                render={({ field }) => (
                  <Input id="embg" invalid={!!errors.embg} {...field} />
                )}
              />
              {errors?.embg?.message
              && <CustomFormFeedback message={errors.embg.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Work position')} />
              <Controller
                name="workPosition"
                control={control}
                render={({ field }) => (
                  <Input id="workPosition" invalid={!!errors.workPosition} {...field} />
                )}
              />
              {errors?.workPosition?.message
              && <CustomFormFeedback message={errors.workPosition.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Licensure expirience')} />
              <Controller
                name="licensureExpirience"
                control={control}
                render={({ field }) => (
                  <Input id="licensureExpirience" invalid={!!errors.licensureExpirience} {...field} />
                )}
              />
              {errors?.licensureExpirience?.message
              && <CustomFormFeedback message={errors.licensureExpirience.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Work experience')} />
              <Controller
                name="workExpirience"
                control={control}
                render={({ field }) => (
                  <Input id="workExpirience" invalid={!!errors.workExpirience} {...field} />
                )}
              />
              {errors?.workExpirience?.message
              && <CustomFormFeedback message={errors.workExpirience.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('Foreign languages')} />
              <Controller
                name="foreignLanguages"
                control={control}
                render={({ field }) => (
                  <Input id="foreignLanguages" invalid={!!errors.foreignLanguages} {...field} />
                )}
              />
              {errors?.foreignLanguages?.message
              && <CustomFormFeedback message={errors.foreignLanguages.message} />}
            </Col>
            <Col md={4} className="mb-1">
              <CustomLabel name={t('IT literacy')} />
              <Controller
                name="itLiteracy"
                control={control}
                render={({ field }) => (
                  <Input id="itLiteracy" invalid={!!errors.itLiteracy} {...field} />
                )}
              />
              {errors?.itLiteracy?.message
              && <CustomFormFeedback message={errors.itLiteracy.message} />}
            </Col>
          </Row>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={!isDirty}
              style={{ minWidth: '250px' }}
            >
              {t('Save changes')}
            </Button>
          </div>
        </CardBody>
      </Card>
    </Form>
  );
};

export default HumanResourceManagement;
