import { TableColumn } from 'react-data-table-component';
import { Button, Row } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isManagePermissionActive, isDeletePermissionActive } from '@src/utility/context/ActivePermissions';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Child from '../types/Child';

const ChildrenColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (child: Child) => void, selectedChildren: Set<string>}) => {
  const { t } = useTranslation();
  const childrenPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const navigate = useNavigate();

  const columns: TableColumn<Child>[] = [
    {
      name: t('Name'),
      minWidth: '150px',
      cell: (row) => (
        <Link to={`/children/${row.id}`}>{`${row.name} ${row.surname}`}</Link>
      ),
      sortable: true,
      sortFunction: ((a, b) => (a.name > b.name ? 1 : -1)),
    },
    {
      name: t('Parent'),
      minWidth: '150px',
      cell: ({ parents }) => (
        <Row>
          {parents?.map((parent) => (
            <p className="m-0" key={parent.id}>{`${parent?.name ?? ''} ${parent?.surname ?? ''}`}</p>
          ))}
        </Row>
      ),
    },
    {
      name: t('Object'),
      minWidth: '150px',
      selector: ({ object }) => object.name,
      sortable: true,
      sortFunction: ((a, b) => (a.object.name > b.object.name ? 1 : -1)),
    },
    {
      name: t('Group'),
      minWidth: '150px',
      selector: ({ group }) => group.name,
      sortable: true,
      sortFunction: ((a, b) => (a.group.name > b.group.name ? 1 : -1)),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(childrenPermission, 'Children')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                onClick={() => { navigate(`/children/${row.id}/edit`); }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(childrenPermission, 'Children')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default ChildrenColumns;
