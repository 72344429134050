/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { HumanResource } from '../types/HumanResource';

const HumanResourcesColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (invoiceId: number) => void }) => {
  const { t } = useTranslation();
  const evaluationPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const navigate = useNavigate();

  const columns: TableColumn<HumanResource>[] = [
    {
      name: t('Name'),
      minWidth: '200px',
      cell: (row) => (
        <Link to={`/staff/${row.id}`}>{`${row.name} ${row.surname}`}</Link>
      ),
    },
    {
      name: t('Phone number'),
      minWidth: '100px',
      cell: (row) => (

        <p className="m-0" key={row.id}>
          {row.phoneNumber}
        </p>
      ),
    },
    {
      name: t('Email'),
      minWidth: '300px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {row.email}
        </p>
      ),
    },
    {
      name: t('Embg'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {row.humanResourceData?.embg ?? ''}
        </p>
      ),
    },
    {
      name: t('Address'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {row.humanResourceData?.address ?? ''}
        </p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(evaluationPermission, 'humanResources')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                size="sm"
                onClick={() => navigate(`/staff/${row.id}`)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(evaluationPermission, 'humanResources')
            ? (
              <Button
                size="sm"
                className="btn btn-icon"
                color="transparent"
                onClick={() => { handleDeleteClick(row.id ?? 0); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default HumanResourcesColumns;
