import { useTranslation } from 'react-i18next';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import useFilter from '@src/utility/hooks/useFilter';
import ChildrenState from '@src/views/children/types/ChildrenState';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { getAllChildren } from '@src/views/children/store';
import ShowModalButton from '@src/components/buttons/ShowModalButton';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import {
  allPayments,
  deletePayment,
  getAllPayments,
  getPaymentStatuses,
  selectedChildren,
  selectedPaymentStatuses,
} from '../store';
import PaymentColumns from '../components/PaymentColumns';
import PaymentState from '../types/PaymentState';
import PaymentForm from '../components/PaymentForm';
import Payment from '../types/Payment';
import PaymentsViaExcel from '../components/PaymentViaExcel';

const Payments = () => {
  const childStore = useSelector((state: ChildrenState) => state.children);
  const paymentStore = useSelector((state: PaymentState) => state.payments);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const { onFilterChange, query } = useFilter();
  const paymentPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  useEffect(() => {
    dispatch(getPaymentStatuses());
    dispatch(getAllChildren());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    const queryUrl = new URLSearchParams(query);
    const yearId = queryUrl.get('yearId')?.split(',');

    if (yearId) {
      setDateDisabled(true);
    } else if (dateDisabled) {
      setDateDisabled(false);
    }
  }, [query]);

  useEffect(() => {
    dispatch(selectedPaymentStatuses(paymentStore.paymentStatuses.map(
      (status) => ({ label: t(`${status}`), value: status }),
    )));
  }, [paymentStore.paymentStatuses]);

  useEffect(() => {
    dispatch(selectedChildren(childStore.allChildren.map(
      (child) => ({ label: `${child.name} ${child.surname}`, value: child.id }),
    )));
  }, [childStore.allChildren]);

  const handleEditClick = (data: Payment) => {
    try {
      dispatch(openModal({
        open: true,
        title: t('Edit payment'),
        Component: PaymentForm,
        modalProps: {
          size: 'lg',
        },
        componentProps: {
          children: childStore.allChildren,
          payment: data,
        },
      }));
    } catch (e) {
      toast.error('Error while deleting invoices');
    }
  };

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Payment will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deletePayment(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredPayemnts = paymentStore
                .allPayments.filter((item) => item.id !== id);
              dispatch(allPayments(filteredPayemnts));
              toast.success(t('Payment successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const PaymentManagementModalProps = {
    open: true,
    title: t('Add payment'),
    Component: PaymentForm,
    modalProps: {
      size: 'lg',
    },
    componentProps: {
      children: childStore.allChildren,
    },
  };

  const handleAddPaymentViaExcel = () => {
    try {
      dispatch(openModal({
        open: true,
        title: t('Add payments via excel'),
        Component: PaymentsViaExcel,
        modalProps: {
          size: 'lg',
        },
      }));
    } catch (e) {
      toast.error('Error while deleting invoices');
    }
  };

  const onFilterClick = () => dispatch(getAllPayments(query));

  return (
    <DataTableWrapper
      exportToExcel="payment-exports"
      dataSource={paymentStore.allPayments}
      title={t('Payments')}
      subtitle={t('See, manage and filter payments table')}
      columns={PaymentColumns({ handleDeleteClick, handleEditClick })}
      dataLoaded={dataLoaded}
      onChange={onFilterChange}
      query={query}
      showSearch={false}
      onFilterClick={onFilterClick}
      tools={[
        { title: t('Add payments via excel'), onClick: handleAddPaymentViaExcel },
      ]}
      filters={[{
        label: 'Status', name: 'status', options: paymentStore.selectedPaymentStatuses,
      },
      {
        label: 'Child', name: 'childId', options: paymentStore.selectedChildren,
      },
      {
        label: 'Invoice', name: 'referenceNumber',
      },
      {
        label: 'Date from', name: 'startDate', datePicker: 'full-date',
      },
      {
        label: 'Date to', name: 'endDate', datePicker: 'full-date',
      },
      ]}
      functionButton={(
        isManagePermissionActive(paymentPermission, 'Payments')
          ? (
            <ShowModalButton
              title={t('Add payment')}
              modalProps={PaymentManagementModalProps}
            />
          ) : <></>
      )}
    />
  );
};

export default Payments;
