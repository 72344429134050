/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { openModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import InventoryCategory from '../types/InventoryCategory';

const InventoryCategoryColumns = ({ handleDeleteClick, handleEditClick }:
  { handleEditClick: (row: InventoryCategory) => void,
     handleDeleteClick: (invoiceId: number) => void }) => {
  const { t } = useTranslation();
  const inventoryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const dispatch = useDispatch<AppDispatch>();

  const handleContentClick = (content: string) => {
    dispatch(openModal({
      Component: AnnouncementModal,
      title: t('Content'),
      componentProps: { content },
      open: true,
    }));
  };

  const columns: TableColumn<InventoryCategory>[] = [
    {
      name: t('Title'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.name}`}</p>
      ),
    },
    {
      name: t('Description'),
      minWidth: '300px',
      cell: (row) => (
        <Button
          color="outline"
          outline
          key={row.id}
          style={{
            margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
          }}
          onClick={() => handleContentClick(row.description)}
        >
          <p
            style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
            className="detail-wrapper cursor-pointer"
          >
            {row.description}
          </p>
        </Button>
      ),
    },
    {
      name: t('Status'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>
          {row.active ? <span style={{ color: 'Green' }}>{t('Active')}</span> : <></>}
          {!row.active ? <span style={{ color: 'Red' }}>{t('Inactive')}</span> : <></>}
        </p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(inventoryPermission, 'AdministrationInventoryCategories')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                size="sm"
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(inventoryPermission, 'AdministrationInventoryCategories')
            ? (

              <Button
                size="sm"
                className="btn btn-icon"
                color="transparent"
                onClick={() => { handleDeleteClick(row.id ?? 0); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default InventoryCategoryColumns;
