import { DateSelectArg } from '@fullcalendar/react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col, FormFeedback, Input, Label, Row,
} from 'reactstrap';
import { closeModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import CustomLabel from '@src/components/forms/CustomLabel';
import Flatpickr from 'react-flatpickr';
import { formatDate } from '@src/views/polls/components/PollsForm';
import { useMemo } from 'react';
import { getDatesInRange, getOneDayEarlierFormatted } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { successToast } from '@src/components/wrappers/ToastMessages';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import Select from 'react-select';
import { yupResolver } from '@hookform/resolvers/yup';

import { HumanResourceRequestFormData, HumanResourceRequestSubmitData, HumanResourceRequest } from '../types/HumanResourceDaysOff';
import { createDayOffRequest, getDaysOffRequest, updateDayOffRequest } from '../store';
import DaysOffRequestCategories from '../constants/DaysOffRequestCategories';
import { humanResourceRequestValidationSchema } from '../validation';

type Props = {
  selectedDates?: DateSelectArg,
  request?: HumanResourceRequest;
}

const HumanResourceDaysOffRequestForm: React.FC<Props> = ({ selectedDates, request }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const startDate = useMemo(() => {
    if (selectedDates) {
      return selectedDates.start;
    }
    if (request) {
      return request.date;
    }

    return new Date();
  }, []);

  const defaultValues = {
    note: request ? (request.note ?? '') : '',
    daysOffRequestCategory: request
      ? { label: request.daysOffRequestCategory, value: request.daysOffRequestCategory }
      : undefined,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<HumanResourceRequestFormData>({
    defaultValues,
    resolver: yupResolver(humanResourceRequestValidationSchema()),
  });

  const submitHandler = (data: HumanResourceRequestFormData) => {
    const submitData: HumanResourceRequestSubmitData = {
      ...data,
      daysOffRequestCategory: String(data.daysOffRequestCategory.value),
    };

    if (request) {
      submitData.id = request.id;
      submitData.date = formatDate(new Date(request.date).toISOString());
      dispatch(updateDayOffRequest(submitData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Request successfully updated'));
          dispatch(getDaysOffRequest());
          dispatch(closeModal());
        }
      });
    } else if (selectedDates) {
      const datesArray = getDatesInRange(
        selectedDates.start, new Date(getOneDayEarlierFormatted(selectedDates.end)),
      );

      submitData.dates = datesArray.map((date) => formatDate(date.toISOString()));
      dispatch(createDayOffRequest(submitData)).then((res) => {
        if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
          successToast(t('Request successfully added'));
          dispatch(getDaysOffRequest());
          dispatch(closeModal());
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <Row>
        <Col md={6}>
          <Label>{selectedDates?.endStr ? t('From') : t('Date')} </Label>
          <Flatpickr
            readOnly
            disabled
            className="form-control full-opacity"
            value={startDate}
          />
        </Col>
        {selectedDates?.endStr
        && (
        <Col md={6}>
          <CustomLabel name={t('To')} />
          <Flatpickr
            readOnly
            disabled
            className="form-control full-opacity"
            value={new Date(getOneDayEarlierFormatted(selectedDates.end))}
          />
        </Col>
        )}
      </Row>
      <Col md={6}>
        <CustomLabel name={t('Request category')} htmlFor="daysOffRequestCategory" />
        <Controller
          name="daysOffRequestCategory"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={DaysOffRequestCategories}
              className="react-select"
              isDisabled={request && request.daysOffRequestStatus !== 'Na čekanju'}
              classNamePrefix="select"
              placeholder={t('Select')}
            />
          )}
        />
        <CustomFormFeedback message={errors?.daysOffRequestCategory?.message} />
      </Col>
      <Col md={12} className="mt-1">
        <CustomLabel name={t('Note')} />
        <Controller
          name="note"
          control={control}
          render={({ field }) => (
            <Input
              type="textarea"
              rows="4"
              id="description"
              maxLength={250}
              invalid={errors.note !== undefined}
              {...field}
            />
          )}
        />
        {errors.note && <FormFeedback className="text-center">{errors.note.message}</FormFeedback>}
      </Col>
      <Col md={12} className="text-center mt-2 pt-50">
        <Button
          className="me-1"
          color="primary"
          type="submit"
        >
          {request ? t('Save changes') : t('Add')}
        </Button>
        <Button
          type="reset"
          color="secondary"
          outline
          onClick={() => dispatch(closeModal())}
        >
          {t('Close')}
        </Button>
      </Col>
    </form>
  );
};

export default HumanResourceDaysOffRequestForm;
