import { errorToast } from '@src/components/wrappers/ToastMessages';
import axios, { AxiosError } from 'axios';
import i18n from '@src/configs/i18n';
import toast, { ToastOptions } from 'react-hot-toast';
import { DefaultRoute } from '../router/routes';
import {formatDate as pollsFormatDate} from '../views/polls/components/PollsForm'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj: object) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num: number) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html: string) => html.replace(/<\/?[^>]+(>|$)/g, '');

export const DashboardRole = {
  SUPER_ADMIN: 0,
  UNIVERSITY_ADMIN: 1,
};

// ** Checks if the passed date is today
const isToday = (date: Date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value: string, formatting: Record<string, string>  = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value: string, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting: Record<string, string> = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

export const isUserLoggedIn = () => localStorage.getItem('userData');
export const getUserData = () => JSON.parse(localStorage.getItem('userData') || '{}');

export const getUserAccessTokenData = () => {
  const user = getUserData();

  if(user.accessToken){
    return JSON.parse(atob(user.accessToken.split('.')[1]))
  }
  return {};
}

export const getHomeRouteForLoggedInUser = () => {
  return DefaultRoute
};

// ** React Select Theme Colors
export const selectThemeColors = (theme:  Record<string, any> | any) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed', // for input hover border-color
  },
});

// ** Handle API error response
export const showErrorMessages = (messages: string[]): void => {
  if (messages.length) {
    const delay = 200;
    messages.forEach((message: string, index: number) => {
      const duration = 1500 * index;
      const options: ToastOptions = { duration, position:'top-center' };
      setTimeout(() => {
        toast.error(message, options);
      }, index * delay);
    });
  }
};

export const handleApiErrorResponse = (error: unknown): void => {
  if (error instanceof AxiosError && error.response?.data?.message) {
    const messages = error.response?.data?.message as string[];
    Array.isArray(messages) ? showErrorMessages(messages) : errorToast();
  }else {
    errorToast();
  }
};

export const extractFileName = (path: string) => {
    const parts = path.split('/');
    const lastPart = parts[parts.length - 1];
    const dashIndex = lastPart.indexOf('-');
    
    if (dashIndex !== -1) {
      return lastPart.substring(dashIndex + 1);
    } else {
      return lastPart;
    }
}

export const extractFileExtension = (path: string) => {
  const parts = path.split('.');
  const extensions = parts[parts.length - 1];
  
  return extensions;
}

export const isOneDayMore =(date1: Date, date2: Date) => {
  const midnightDate1 = new Date(date1);
  midnightDate1.setHours(0, 0, 0, 0);

  const midnightDate2 = new Date(date2);
  midnightDate2.setHours(0, 0, 0, 0);

  const timeDifference = midnightDate1.getTime() - midnightDate2.getTime();

  return Math.abs(timeDifference) === 24 * 60 * 60 * 1000;
}

export const getDatesInRange = (startDate: Date, endDate: Date): Date[] => {
  const datesArray: Date[] = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    datesArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return datesArray;
}

export const getOneDayEarlierFormatted = (date: Date) => {
  const oneDayInMillis = 24 * 60 * 60 * 1000;
  const previousDate = new Date(date.getTime() - oneDayInMillis);

  return pollsFormatDate(previousDate.toISOString());
};

export const getNumberOfWorkingDays = (dateFrom: Date, dateTo: Date, recurringNonWorkingDays: number[], nonWorkingDays: Date[]) => {
  const recurringNonWorkingDaysSet = new Set(recurringNonWorkingDays);
  const filteredNonWorkingDays = nonWorkingDays.filter((day: Date) => day<dateTo && dateFrom<day && !recurringNonWorkingDaysSet.has(day.getDay()));

  let dateFromMillis = dateFrom.getTime();
  let dateToMillis = dateTo.getTime();

  // Calculate the difference in milliseconds
  let differenceMillis = dateToMillis - dateFromMillis;

  // Convert the difference to days
  let differenceDays = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));

  while(dateFrom.getDay() !== 1 && dateFrom < dateTo){
    if(recurringNonWorkingDaysSet.has(dateFrom.getDay())) differenceDays--;
    dateFrom.setDate(dateFrom.getDate() + 1)
  }
  while(dateTo.getDay() !== 1 && dateFrom < dateTo){
    if(recurringNonWorkingDaysSet.has(dateTo.getDay())) differenceDays--;
    dateTo.setDate(dateTo.getDate() - 1)
  }
  dateFromMillis = dateFrom.getTime();
  dateToMillis = dateTo.getTime();
  differenceMillis = dateToMillis - dateFromMillis;
  let weeksDifference = Math.floor(Math.ceil(differenceMillis / (1000 * 60 * 60 * 24))/7);

  differenceDays -= weeksDifference * recurringNonWorkingDays.length;
  differenceDays -= filteredNonWorkingDays.length

  return differenceDays;
}

export const getHeaderVersion = () => {
  const { isOnNewSystem } = JSON.parse(localStorage.getItem('userData') ?? '{}');
  
  return isOnNewSystem ? 'new' : 'old';
}

export const formatDateToShortDate = (date: Date | string) => {
  const newDate = new Date(date);
  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(newDate.getDate()).padStart(2, '0');
  const dateString = `${year}-${month}-${day}`; // "yyyy-mm-dd"
  return dateString;
}

export const mapCyrilicTextToLatin = (text: string) => {
  const cyrillicToLatinMap: {
    [key:string]: string;
  } = {
    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D', 'Ђ': 'Đ', 'Е': 'E', 'Ж': 'Ž', 
    'З': 'Z', 'И': 'I', 'Ј': 'J', 'К': 'K', 'Л': 'L', 'Љ': 'Lj', 'М': 'M', 'Н': 'N', 
    'Њ': 'Nj', 'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T', 'Ћ': 'Ć', 'У': 'U', 
    'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Č', 'Џ': 'Dž', 'Ш': 'Š',
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd', 'ђ': 'đ', 'е': 'e', 'ж': 'ž',
    'з': 'z', 'и': 'i', 'ј': 'j', 'к': 'k', 'л': 'l', 'љ': 'lj', 'м': 'm', 'н': 'n', 
    'њ': 'nj', 'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'ћ': 'ć', 'у': 'u', 
    'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'č', 'џ': 'dž', 'ш': 'š'
  };

  return text.split('').map(char => cyrillicToLatinMap[char] || char).join('');
}

export const handleErrorMessage = (err: any) => {
  if (axios.isAxiosError(err) && err.response) {
    let errorResponse: any = err.response.data;
    let errorMessage: string | undefined;

    if (Array.isArray(errorResponse.message)) {
      errorMessage = errorResponse.message[0];
    } else if (typeof errorResponse.message === 'string') {
      errorMessage = errorResponse.message;
    }

    if (errorMessage) {
      errorToast(i18n.t(errorMessage));
      return;
    }
  } 
  errorToast();
};
