import {
  Button,
  Col, Form, Input, Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import axios from 'axios';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SelectedOptions from '@src/types/SelectedOptions';
import Select from 'react-select';
import { useState } from 'react';
import Switcher from '../switchers/Switcher';

const SendSmsModal = (
  {
    toIds = [], route, idName, options = [], hasBusiness = false,
  } : {
     toIds?: number[],
     route: string,
     idName: string,
     options?: SelectedOptions[],
     hasBusiness: boolean,
  },
) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const [sendToPersonal, setSendToPersonal] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ message: string, selectedToIds: SelectedOptions[] }>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().required(t('Message is required')),
      }),
    ),
  });

  const submitHandler = async (data: { message: string, selectedToIds: SelectedOptions[] }) => {
    const formattedData: any = {
      message: data.message,
    };
    const idColumnName = `${idName}Ids`;
    const selectedToIds = data.selectedToIds ? data
      .selectedToIds.map((selectedId) => Number(selectedId.value)) : [];
    const sendTo = [...toIds.map((toId) => Number(toId)), ...selectedToIds];
    formattedData[idColumnName] = sendTo;
    if (hasBusiness) formattedData.sendToPersonal = sendToPersonal ? '1' : '0';

    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}${route}`, formattedData);
    if (response.status === 201) {
      dispatch(closeModal());
      successToast(t('SMS sent successfully'));
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          {options.length ? (
            <Col md={6}>
              <CustomLabel required name={t('Parents')} />
              <Controller
                control={control}
                name="selectedToIds"
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={null}
                    options={options}
                    className="react-select"
                    classNamePrefix="select"
                    placeholder={t('Select')}
                    isMulti
                  />
                )}
              />
              <CustomFormFeedback message={errors?.selectedToIds?.message} />
            </Col>
          ) : <></>}
          <Col md={12}>
            <CustomLabel name={t('Message')} htmlFor="message" required />
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <Input id="message" type="textarea" invalid={!!errors.message} {...field} />
              )}
            />
            <CustomFormFeedback message={errors?.message?.message} />
          </Col>
          {hasBusiness
            ? (
              <Col md={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Switcher
                    id="sendToPersonal"
                    checked={sendToPersonal}
                    onChange={(val) => setSendToPersonal(val.target.checked)}
                  />
                  {t('Send to personal email')}
                </div>
              </Col>
            ) : <></>}
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button
              type="submit"
              className="me-1"
              color="primary"
            >
              {t('Send') }
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={() => dispatch(closeModal())}
            >
              {t('Close')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SendSmsModal;
