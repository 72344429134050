// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import { handleErrorMessage } from '@src/utility/Utils';
import SubmitData from '../types/SubmitData';
import TenantObject from '../types/TenantObject';

export const getAllTenantObjects = createAsyncThunk('goKinder/getTenantObjects', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/objects`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAllTenantObjectsAdministration = createAsyncThunk('goKinder/getAllTenantObjectsAdministration', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/objects/administration`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteTenantObject = createAsyncThunk('goKinder/deleteTenantObject', async (tenantObjectId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/objects/${tenantObjectId}`, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Object deleted successfully!']);
    return {
      data: { tenantObjectId, ...response.data },
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewTenantObject = createAsyncThunk('goKinder/addNewTenantObject', async (data: SubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/objects`, data, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['New object added!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editTenantObject = createAsyncThunk('goKinder/editTenantObject', async (data: TenantObject) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/objects/${data.id}`, data, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Object successfully updated!']);
    return {
      data: { ...data, ...response.data },
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const tenantObjectsSlice = createSlice({
  name: 'tenantObjects',
  initialState: {
    allTenantObjects: [] as TenantObject[],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllTenantObjects.fulfilled, (state, action) => {
      state.allTenantObjects = action.payload.data;
    });
    builder.addCase(getAllTenantObjectsAdministration.fulfilled, (state, action) => {
      state.allTenantObjects = action.payload.data;
    });
    builder.addCase(deleteTenantObject.fulfilled, (state, action) => {
      state.allTenantObjects = state.allTenantObjects.filter(
        (tenantObject) => tenantObject.id !== action.payload.data.tenantObjectId,
      );
    });
    builder.addCase(addNewTenantObject.fulfilled, (state, action) => {
      state.allTenantObjects = [...state.allTenantObjects, action.payload.data];
    });
    builder.addCase(editTenantObject.fulfilled, (state, action) => {
      const tenantObjectIndex = state.allTenantObjects.findIndex(
        (tenantObject) => tenantObject?.id === action?.payload?.data.id,
      );
      if (tenantObjectIndex >= 0) {
        state.allTenantObjects[tenantObjectIndex] = action?.payload?.data;
      }
    });
  },

});

export default tenantObjectsSlice.reducer;
