import moment from 'moment';
import {
  Card, CardBody, Col, Button,
} from 'reactstrap';
import {
  Edit, Delete,
  File,
  User,
} from 'react-feather';
import {
  CalendarTime, Heading,
} from 'tabler-icons-react';
import { extractFileName, getUserAccessTokenData } from '@src/utility/Utils';
import { useSkin } from '@src/utility/hooks/useSkin';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import { successToast } from '@src/components/wrappers/ToastMessages';

import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { deleteHumanResourcesFile, getHumanResourceFiles } from '../store';
import { HumanResourceFile } from '../types/HumanResourceFile';
import HumanResourceFileForm from './HumanResourceFileForm';

const HumanResourceFileCard = ({ details }: { details: HumanResourceFile }) => {
  const dispatch = useDispatch<AppDispatch>();
  const userTokenData = getUserAccessTokenData();
  const { skin } = useSkin();
  const { t } = useTranslation();

  const handleDeleteClick = (file: HumanResourceFile) => {
    dispatch(
      openModal({
        Component: ConfirmationModalWrapper,
        title: t('Are you sure?'),
        subtitle: t('File will be deleted'),
        open: true,
        componentProps: {
          handleConfirm: async () => {
            try {
              await dispatch(deleteHumanResourcesFile(file.id));
            } finally {
              successToast(t('File successfully deleted'));
              dispatch(getHumanResourceFiles(`userId=${details.userId}`));
              dispatch(closeModal());
            }
          },
        },
      }),
    );
  };

  const handleContentClick = (content: string) => {
    dispatch(openModal({
      Component: AnnouncementModal,
      title: t('Content'),
      componentProps: { content },
      open: true,
    }));
  };

  const canEdit = userTokenData.role === 'superAdmin' || userTokenData.sub === details.createdById;
  const disabledColor = skin === 'dark' ? '#444' : '#cccccc';

  return (
    <Col md={4}>
      <Card>
        <CardBody>
          <div>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><User size={20} /></span>
              {t('Created by')}:
              {`${details.createdBy.name}${details.createdBy.surname}`}
            </p>
            <p className="detail-wrapper">
              <span style={{ color: '#7DCBEC' }}><CalendarTime size={20} /></span>
              {t('Created at')}:
              {moment(new Date(details.createdAt)).format('DD. MMM Y. HH:mm')}
            </p>
            <div>
              <Button
                color="outline"
                outline
                block
                style={{
                  margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
                }}
                onClick={() => handleContentClick(details.name)}
              >
                <p
                  style={{
                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '0.5rem', textAlign: 'start',
                  }}
                  className="cursor-pointer"
                >
                  <span style={{ color: '#7DCBEC' }}><Heading size={20} /></span>
                  {t('Name')}:&nbsp;{details.name}
                </p>
              </Button>
            </div>
            <div>
              <Button
                color="outline"
                outline
                block
                style={{
                  margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
                }}
                onClick={() => handleContentClick(details.note)}
              >
                <p
                  style={{
                    whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '0.5rem', textAlign: 'start',
                  }}
                  className="cursor-pointer"
                >
                  <span style={{ color: '#7DCBEC' }}><Heading size={20} /></span>
                  {t('Note')}:&nbsp;{details.note}
                </p>
              </Button>
            </div>
          </div>
          <hr />
          <a
            target="_blank"
            style={{ gap: '10px', width: 'fit-content' }}
            rel="noreferrer"
            href={details.filePath}
            download
            className="d-flex align-items-center"
          >
            <File width={15} height={15} />{extractFileName(details.filePath)}
          </a>
          <hr />
          <div className="w-full d-flex justify-content-between">
            <Button
              style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
              color="outline m-0 p-0"
              disabled={!canEdit}
              onClick={() => {
                dispatch(openModal({
                  Component: HumanResourceFileForm,
                  componentProps: { file: details },
                  title: t('Edit file'),
                  open: true,
                  modalProps: { size: 'lg' },
                }));
              }}
            ><Edit />
            </Button>
            <Button
              style={{ border: 'none', color: !canEdit ? disabledColor : '' }}
              disabled={!canEdit}
              color="outline m-0 p-0"
              onClick={() => handleDeleteClick(details)}
            ><Delete color="red" />
            </Button>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default HumanResourceFileCard;
