/* eslint-disable no-return-assign */
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import Select, { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { handleApiErrorResponse } from '@src/utility/Utils';
import toast from 'react-hot-toast';
import RequestStatus from '@src/types/RequestStatus';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';

import HumanResourcesState from '../../types/HumanResourceState';
import {
  createDevelopmentReport,
  editDevelopmentReport,
  getDevelopmentReport,
  getSchoolYears,
} from '../../store';
import { DevelopmentReport, DevelopmentReportFormData } from '../../types/portfolio/DevelopmentReport';

const DevelopmentReportsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SingleValue<SelectedOptions>>();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const defaultValues: DevelopmentReport = {
    advancedKnowledge: '',
    realizedForms: '',
    unrealizedForms: '',
    titleAcquired: '',
    note: '',
  } as DevelopmentReport;

  useEffect(() => {
    dispatch(getSchoolYears());
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<DevelopmentReportFormData>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      setSelectedSchoolYear(
        humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1],
      );
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (selectedSchoolYear) {
      dispatch(getDevelopmentReport(
        `userId=${humanResourceStore.currentHumanResource.id}&schoolYearId=${selectedSchoolYear.value}`,
      ));
    }
  }, [selectedSchoolYear]);

  useEffect(() => {
    if (humanResourceStore.currentDevelopmentReport?.id) {
      reset({
        advancedKnowledge: humanResourceStore.currentDevelopmentReport.advancedKnowledge,
        realizedForms: humanResourceStore.currentDevelopmentReport.realizedForms,
        unrealizedForms: humanResourceStore.currentDevelopmentReport.unrealizedForms,
        titleAcquired: humanResourceStore.currentDevelopmentReport.titleAcquired,
        note: humanResourceStore.currentDevelopmentReport.note,
      });
    } else {
      reset(defaultValues);
    }
  }, [humanResourceStore.currentDevelopmentReport]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: DevelopmentReportFormData,
  ) => {
    try {
      const newInventoryItem: DevelopmentReport = {
        advancedKnowledge: values.advancedKnowledge,
        realizedForms: values.realizedForms,
        unrealizedForms: values.unrealizedForms,
        titleAcquired: values.titleAcquired,
        note: values.note,
        schoolYearId: selectedSchoolYear?.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as DevelopmentReport;

      if (humanResourceStore.currentDevelopmentReport?.id) {
        await dispatch(editDevelopmentReport(
          { id: +humanResourceStore.currentDevelopmentReport.id, data: newInventoryItem },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Development report successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getDevelopmentReport(`userId=${humanResourceStore.currentDevelopmentReport?.userId}&schoolYearId=${selectedSchoolYear?.value}`));
          }
        });
      } else {
        await dispatch(createDevelopmentReport(newInventoryItem)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Development report successfully added'), { position: 'top-right', duration: 3000 });
            const { data } = res.payload as { data: DevelopmentReport };
            dispatch(getDevelopmentReport(`userId=${data?.userId}&schoolYearId=${selectedSchoolYear?.value}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  const canEdit = isManagePermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopmentReport');

  return (
    <div>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <CardTitle tag="h4">{t('Development report')}</CardTitle>
          <div>
            <CustomLabel name={t('Year')} htmlFor="schoolYearId" />
            <Select
              options={humanResourceStore.schoolYears}
              className="react-select"
              classNamePrefix="select"
              placeholder={t('Select')}
              value={selectedSchoolYear}
              onChange={(value) => setSelectedSchoolYear(value)}
            />
          </div>
        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
            <Row>
              <Col md={6}>
                <CustomLabel name={t('Advanced knowledge')} for="advancedKnowledge" />
                <Controller
                  name="advancedKnowledge"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="advancedKnowledge" invalid={!!errors.advancedKnowledge} {...field} />
                  )}
                />
                {errors?.advancedKnowledge?.message
                && <CustomFormFeedback message={errors.advancedKnowledge.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Skills')} for="titleAcquired" />
                <Controller
                  name="titleAcquired"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="titleAcquired" invalid={!!errors.titleAcquired} {...field} />
                  )}
                />
                {errors?.titleAcquired?.message
                && <CustomFormFeedback message={errors.titleAcquired.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Realized forms')} for="realizedForms" />
                <Controller
                  name="realizedForms"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="realizedForms" type="textarea" invalid={!!errors.realizedForms} {...field} />
                  )}
                />
                {errors?.realizedForms?.message
                && <CustomFormFeedback message={errors.realizedForms.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Unrealized forms')} for="unrealizedForms" />
                <Controller
                  name="unrealizedForms"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="unrealizedForms" type="textarea" invalid={!!errors.unrealizedForms} {...field} />
                  )}
                />
                {errors?.unrealizedForms?.message
                && <CustomFormFeedback message={errors.unrealizedForms.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Note')} for="note" />
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="note" type="textarea" invalid={!!errors.note} {...field} />
                  )}
                />
                {errors?.note?.message
                && <CustomFormFeedback message={errors.note.message} />}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <Button
                  type="submit"
                  className="me-1"
                  color="primary"
                >
                  {t('Save changes')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default DevelopmentReportsTab;
