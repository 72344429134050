import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SelectedOptions from '@src/types/SelectedOptions';
import { SelectedGroupOptions } from '@src/views/polls/types';
import axios from 'axios';
import { handleErrorMessage } from '@src/utility/Utils';
import Album from '../types/Album';
import AlbumFormData from '../types/AlbumFormData';

export const getAllAlbums = createAsyncThunk('goKinder/getAllAlbums', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/gallery/dashboard`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewAlbum = createAsyncThunk('goKinder/addNewAlbum', async (data: AlbumFormData) => {
  try {
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('description', data.description);
    formData.append('date', data.date);

    if (data.groupIds) {
      data.groupIds.forEach((group: string) => {
        formData.append('groupIds[]', group);
      });
    }

    if (data.uplaodFiles) {
      data.uplaodFiles.forEach((file) => {
        formData.append('files', file, file.name);
      });
    }

    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/gallery`, formData, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editAlbum = createAsyncThunk('goKinder/editAlbum', async (data: AlbumFormData) => {
  try {
    const { id } = data;
    delete data.id;

    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/gallery/${id}`, data, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteAlbum = createAsyncThunk('goKinder/deleteAlbum', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/gallery/${id}`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAlbumById = createAsyncThunk('goKinder/getAlbumById', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/gallery/${id}`,
      { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const rotateImage = createAsyncThunk('goKinder/rotateImage', async (id: number) => {
  try {
    const response = await axios.patch(`${process.env.REACT_APP_URL_ENV}/album-files/${id}`, null, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteImage = createAsyncThunk('goKinder/deleteImage', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/album-files/${id}`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editImage = createAsyncThunk('goKinder/editImage', async (data: {id: number, description: string}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/album-files/${data.id}`, data, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewFiles = createAsyncThunk('goKinder/addNewFiles', async (data: {albumId: number; uploadFiles: File[]}) => {
  try {
    const formData = new FormData();

    if (data.uploadFiles) {
      data.uploadFiles.forEach((file) => {
        formData.append('files', file, file.name);
      });
    }

    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/album-files/${data.albumId}`, formData, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

const albumsSlice = createSlice({
  name: 'albums',
  initialState: {
    allAlbums: [] as Album[],
    currentAlbum: undefined,
    selectedObjects: [] as SelectedOptions[],
    selectedGroups: [] as SelectedGroupOptions[],
  },
  reducers: {
    setSelectedObjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
    setSelectedGroups: (state, action) => {
      state.selectedGroups = action.payload;
    },
    clearCurrentAlbum: (state) => {
      state.currentAlbum = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAlbums.fulfilled, (state, action) => {
      state.allAlbums = action.payload.data;
    });
    builder.addCase(getAlbumById.fulfilled, (state, action) => {
      state.currentAlbum = action.payload.data;
    });
  },
});

export const {
  setSelectedGroups,
  setSelectedObjects,
  clearCurrentAlbum,
} = albumsSlice.actions;

export default albumsSlice.reducer;
