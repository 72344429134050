/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AnnouncementModal from '@src/views/announcements/components/AnnouncementModal';
import { openModal } from '@store/modal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import EvaluationGroup from '../types/EvaluationGroup';

const EvaluationGroupColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (invoiceId: number) => void }) => {
  const { t } = useTranslation();
  const evaluationPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const formatEvaluationDescription = (text: string) => {
    if (text.length > 40) {
      return ` ${text.slice(0, 40)}...`;
    }

    return text;
  };

  const handleContentClick = (content: string) => {
    dispatch(openModal({
      Component: AnnouncementModal,
      title: t('Content'),
      componentProps: { content },
      open: true,
    }));
  };

  const columns: TableColumn<EvaluationGroup>[] = [
    {
      name: t('Title'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.name}`}</p>
      ),
    },
    {
      name: t('Description'),
      minWidth: '300px',
      cell: (row) => (
        <Button
          color="outline"
          outline
          key={row.id}
          style={{
            margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
          }}
          onClick={() => handleContentClick(row.description)}
        >
          <p
            style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
            className="detail-wrapper cursor-pointer"
          >
            {formatEvaluationDescription(row.description)}
          </p>
        </Button>
      ),
    },
    {
      name: t('Type'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${t(row.evaluationType)}`}</p>
      ),
    },
    {
      name: t('Groups'),
      minWidth: '150px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{t(`${row.tenantGroups.map((it) => it.name).join(', ')}`)}</p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(evaluationPermission, 'AdministrationEvaluations')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                size="sm"
                onClick={() => { navigate(`/evaluation-groups/edit/${row.id}`); }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(evaluationPermission, 'AdministrationEvaluations')
            ? (

              <Button
                size="sm"
                className="btn btn-icon"
                color="transparent"
                onClick={() => { handleDeleteClick(row.id); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default EvaluationGroupColumns;
