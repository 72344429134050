import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import { closeModal, openModal } from '@store/modal';
import { useTranslation } from 'react-i18next';
import useFilter from '@src/utility/hooks/useFilter';
import toast from 'react-hot-toast';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import RequestStatus from '@src/types/RequestStatus';
import RoleState from '@src/views/roles/types/RoleState';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import { getAllRoles } from '@src/views/roles/store';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';

import UserColumns from '../components/UserColumns';
import {
  deleteUser,
  getAllUsers,
  setAllUsers,
  setSelectedObjects,
  setSelectedRoles,
} from '../store';
import UserState from '../types/UserState';
import UserForm from '../components/UserForm';
import User from '../types/User';

const Users = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userStore = useSelector((store: UserState) => store.users);
  const AllRoles = useSelector((store: RoleState) => store.roles.allRoles);
  const AllTenantObjects = useSelector(
    (store: TenantObjectState) => store.tenantObjects.allTenantObjects,
  );
  const { t } = useTranslation();
  const rolePermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const { onFilterChange, query } = useFilter();
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getAllTenantObjects());
    dispatch(getAllRoles());
    setDataLoaded(true);
  }, []);

  useEffect(() => {
    dispatch(setSelectedObjects(AllTenantObjects.map(
      (item) => ({ label: t(String(item.name)), value: item.id }),
    )));
  }, [AllTenantObjects]);

  useEffect(() => {
    dispatch(setSelectedRoles(AllRoles.map(
      (item) => ({ label: t(String(item.name)), value: item.id }),
    )));
  }, [AllRoles]);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('User will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteUser(id));
            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredUsers = userStore
                .allUsers.filter((item) => item.id !== id);
              dispatch(setAllUsers(filteredUsers));
              dispatch(closeModal());
              toast.success(t('User successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } catch (e) {
            toast.error('Error while deleting users');
          }
        },
      },
    }));
  };

  const handleEditClick = (user: User) => dispatch(openModal({
    Component: UserForm,
    title: t('Edit user'),
    open: true,
    modalProps: { size: 'lg' },
    componentProps: {
      user,
    },
  }));

  const onFilterClick = () => dispatch(getAllUsers(query));

  return (
    <div>
      <DataTableWrapper
        dataSource={userStore.allUsers}
        title={t('Users')}
        subtitle={t('See, manage and filter users table')}
        columns={UserColumns({ handleEditClick, handleDeleteClick })}
        onChange={onFilterChange}
        showSearch={false}
        dataLoaded={dataLoaded}
        filters={[
          {
            label: t('Objects'), name: 'objectIds', options: userStore.selectedObjects, isMulti: true,
          },
          {
            label: t('Roles'), name: 'roleIds', options: userStore.selectedRoles, isMulti: true,
          },
        ]}
        onFilterClick={onFilterClick}
        functionButton={(
          isManagePermissionActive(rolePermission, 'AdministrationUsers')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => {
                  dispatch(openModal({
                    Component: UserForm,
                    title: t('Add user'),
                    open: true,
                    modalProps: { size: 'lg' },
                  }));
                }}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new user')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default Users;
