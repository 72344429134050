import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';

import {
  createWorkReport,
  editWorkReport,
  getWorkReports,
} from '../../store';
import HumanResourcesState from '../../types/HumanResourceState';
import { WorkReport, WorkReportFormData } from '../../types/portfolio/WorkReport';
import { humanResourceWorkReportsValidationSchema } from '../../validation';

const WorkReportForm = (
  { workReport, schoolYearIds }: {
    workReport?: WorkReport, schoolYearIds: number },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<WorkReportFormData>({
    reValidateMode: 'onChange',
    defaultValues: {
      date: workReport ? new Date(workReport.date) : new Date(),
      realizedActivity: workReport ? workReport.realizedActivity : '',
      numberOfPoints: workReport ? workReport.numberOfPoints : '',
      workReportType: workReport ? { label: workReport.workReportType, value: workReport.workReportType } : { label: '', value: '' },
    },
    resolver: yupResolver(
      humanResourceWorkReportsValidationSchema(),
    ),
  });

  useEffect(() => {
    dispatch(getAllTenantGroups());
    dispatch(getAllTenantObjects());
  }, []);

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      if (workReport) {
        const selectedSchoolYear = humanResourceStore.schoolYears.find(
          (value) => String(value.value) === String(workReport.schoolYearId),
        );
        if (selectedSchoolYear) setValue('schoolYearId', selectedSchoolYear);
      } else {
        setValue('schoolYearId', humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1]);
      }
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      if (workReport) {
        const selectedSchoolYear = humanResourceStore.schoolYears.find(
          (value) => String(value.value) === String(workReport.schoolYearId),
        );
        if (selectedSchoolYear) setValue('schoolYearId', selectedSchoolYear);
      } else {
        setValue('schoolYearId', humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1]);
      }
    }
  }, [humanResourceStore.schoolYears]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: WorkReportFormData,
  ) => {
    try {
      const newWorkReport: WorkReport = {
        date: formatDateToShortDate(values.date),
        realizedActivity: values.realizedActivity,
        numberOfPoints: values.numberOfPoints,
        workReportType: values.workReportType.value,
        schoolYearId: values.schoolYearId.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as WorkReport;

      if (workReport?.id) {
        await dispatch(editWorkReport(
          { id: +workReport.id, data: newWorkReport },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Work report successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getWorkReports(`userId=${workReport?.userId}&schoolYearIds=${schoolYearIds}`));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createWorkReport(newWorkReport)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Work report successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            const { data } = res.payload as {data: WorkReport};
            dispatch(getWorkReports(`userId=${data?.userId}&schoolYearIds=${schoolYearIds}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            required
            options={{ minDate: undefined }}
            defaultValue={watch('date')}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Year')} htmlFor="schoolYearId" required />
          <Controller
            name="schoolYearId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.schoolYears}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.schoolYearId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Type')} htmlFor="workReportType" required />
          <Controller
            name="workReportType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.workReportTypes}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.workReportType?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Number of points')} for="numberOfPoints" required />
          <Controller
            name="numberOfPoints"
            control={control}
            render={({ field }) => (
              <Input id="numberOfPoints" invalid={!!errors.numberOfPoints} {...field} />
            )}
          />
          {errors?.numberOfPoints?.message
          && <CustomFormFeedback message={errors.numberOfPoints.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Realized activity')} for="realizedActivity" required />
          <Controller
            name="realizedActivity"
            control={control}
            render={({ field }) => (
              <Input id="realizedActivity" type="textarea" invalid={!!errors.realizedActivity} {...field} />
            )}
          />
          {errors?.realizedActivity?.message
          && <CustomFormFeedback message={errors.realizedActivity.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {workReport?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default WorkReportForm;
