import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isManagePermissionActive, isDeletePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { HumanResourceDaysOffQuota } from '../types/HumanResourceDaysOffQuota';

const HumanResourceDaysOffQuotaColumns = ({ handleEditClick, handleDeleteClick }:
  {
    handleDeleteClick: (id: number) => void,
    handleEditClick: (item: HumanResourceDaysOffQuota) => void,
  }) => {
  const { t } = useTranslation();
  const childrenPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<HumanResourceDaysOffQuota>[] = [
    {
      name: t('Name'),
      minWidth: '150px',
      cell: ({ name }) => (
        <p className="m-0">{name}</p>
      ),
    },
    {
      name: t('Total days'),
      minWidth: '150px',
      selector: ({ numberOfDays }) => numberOfDays,
    },
    {
      name: t('Days used'),
      minWidth: '150px',
      selector: ({ numberOfDaysUsed }) => numberOfDaysUsed,
    },
    {
      name: t('Year'),
      minWidth: '150px',
      selector: ({ year }) => year.value,
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(childrenPermission, 'portfolioProfessionalDevelopmentEvaluations')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(childrenPermission, 'portfolioProfessionalDevelopmentEvaluations')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => handleDeleteClick(Number(row.id))}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default HumanResourceDaysOffQuotaColumns;
