/* eslint-disable no-restricted-syntax */
import { useTranslation } from 'react-i18next';
import { isViewPermissionActive } from '@src/utility/context/ActivePermissions';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Button, Col, Row } from 'reactstrap';
import { useMemo, useState } from 'react';
import ProfessionalDevelopmentTab from './portfolio/ProfessionalDevelopmentTab';
import PersonalDevelopmentPlanTab from './portfolio/PersonalDevelopmentPlanTab';
import DevelopmentReportsTab from './portfolio/DevelopmentReportsTab';
import ProfessionalPhilosophiesTab from './portfolio/ProfessionalPhilosophies';
import WorkEffectivenesTab from './portfolio/WorkEffectiveness';
import WorkEvaluationsTab from './portfolio/WorkEvaluations';
import ProfessionalDevelopmentEvaluationsTab from './portfolio/ProfessionalDevelopmentEvaluationsTab';
import ExamplaryActivitesTab from './portfolio/ExamplaryActivitiesTab';
import WorkReportsTab from './portfolio/WorkReportsTab';

const PorfolioTab = () => {
  const { t } = useTranslation();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [activeTab, setActiveTab] = useState('professional_development');

  const handleTabsChange = (newValue: string) => {
    setActiveTab(newValue);
  };

  const tabOptions: { route: string, name: string }[] = useMemo(() => [
    { route: 'professional_development', name: t('Professional development') },
    { route: 'personal_development_plan', name: t('Personal development plan') },
    { route: 'development_reports', name: t('Development report') },
    { route: 'professional_philosophies', name: t('Professional philosophies') },
    { route: 'work_effectiveness', name: t('Work effectiveness') },
    { route: 'work_evaluations', name: t('Work effectiveness evaluations') },
    { route: 'development_evaluations', name: t('Professional development evaluations') },
    { route: 'exemplary_activities', name: t('Exemplary activities') },
    { route: 'work_reports', name: t('Work reports') },
  ], []);

  return (
    <TabContext value={activeTab ?? 'professional_development'}>
      <Row>
        <Col md={3} style={{ paddingTop: '24px' }}>
          {tabOptions.map((tabOption) => (
            <Button
              outline={activeTab !== tabOption.route}
              color="primary"
              style={{ borderColor: '#6E6B7B 0 solid', marginBottom: '2px', textAlign: 'start' }}
              block
              className="no-border"
              onClick={() => handleTabsChange(tabOption.route)}
            >
              {tabOption.name}
            </Button>
          ))}
        </Col>
        <Col md={9}>
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopment')
            ? (
              <TabPanel value="professional_development" className="px-0 mx-0">
                <ProfessionalDevelopmentTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioPersonalDevelopment')
            ? (
              <TabPanel value="personal_development_plan" className="px-0 mx-0">
                <PersonalDevelopmentPlanTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopmentReport')
            ? (
              <TabPanel value="development_reports" className="px-0 mx-0">
                <DevelopmentReportsTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioProfessionalPhilosophies')
            ? (
              <TabPanel value="professional_philosophies" className="px-0 mx-0">
                <ProfessionalPhilosophiesTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioWorkEffectiveness')
            ? (
              <TabPanel value="work_effectiveness" className="px-0 mx-0">
                <WorkEffectivenesTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioWorkEvaluations')
            ? (
              <TabPanel value="work_evaluations" className="px-0 mx-0">
                <WorkEvaluationsTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopmentEvaluations')
            ? (
              <TabPanel value="development_evaluations" className="px-0 mx-0">
                <ProfessionalDevelopmentEvaluationsTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioExemplaryActivities')
            ? (
              <TabPanel value="exemplary_activities" className="px-0 mx-0">
                <ExamplaryActivitesTab />
              </TabPanel>
            ) : <></>
          }
          {
          isViewPermissionActive(humanResourcePermissions, 'portfolioWorkReports')
            ? (
              <TabPanel value="work_reports" className="px-0 mx-0">
                <WorkReportsTab />
              </TabPanel>
            ) : <></>
          }
        </Col>
      </Row>
    </TabContext>
  );
};

export default PorfolioTab;
