import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleErrorMessage } from '@src/utility/Utils';
import axios from 'axios';
import SelectedOptions from '@src/types/SelectedOptions';
import Payment from '../types/Payment';

export const getAllPayments = createAsyncThunk('goKinder/getAllPayments', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/payments${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getPaymentById = createAsyncThunk('goKinder/getPaymentById', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/payments/${id}`, { headers: { Version: 'new' } });

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewPayment = createAsyncThunk('goKinder/addNewPayment', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/payments`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editPayment = createAsyncThunk('goKinder/editPayment', async ({ id, data } : { id: number, data:any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/payments/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deletePayment = createAsyncThunk('goKinder/deletePayment', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/payments/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getPaymentStatuses = createAsyncThunk('goKinder/getPaymentStatuses', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/payments/payment-statuses`);

    return {
      data: response.data?.paymentStatuses,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addMultiplePayments = createAsyncThunk('goKinder/addMultiplePayments', async (formattedData: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/payments/create-mass-payment`, formattedData);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addPaymentsViaExcel = createAsyncThunk('goKinder/addPaymentsViaExcel', async (formattedData: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/payments/create-mass-payment-excel`, formattedData);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const paymentSlice = createSlice({
  name: 'payments',
  initialState: {
    allPayments: [] as Payment[],
    payment: {} as Payment,
    selectedYears: [] as SelectedOptions[],
    selectedInvoiceType: [] as SelectedOptions[],
    paymentStatuses: [] as string[],
    selectedPaymentStatuses: [] as SelectedOptions[],
    selectedChildren: [] as SelectedOptions[],
  },
  reducers: {
    selectedYears: (state, action) => {
      state.selectedYears = action.payload;
    },
    selectedInvoiceType: (state, action) => {
      state.selectedInvoiceType = action.payload;
    },
    selectedPaymentStatuses: (state, action) => {
      state.selectedPaymentStatuses = action.payload;
    },
    selectedChildren: (state, action) => {
      state.selectedChildren = action.payload;
    },
    allPayments: (state, action) => {
      state.allPayments = action.payload;
    },
    clearPayments: (state) => {
      state.allPayments = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPayments.fulfilled, (state, action) => {
      state.allPayments = action.payload.data;
    });
    builder.addCase(getPaymentById.fulfilled, (state, action) => {
      state.payment = action.payload.data;
    });
    builder.addCase(getPaymentStatuses.fulfilled, (state, action) => {
      state.paymentStatuses = action.payload.data;
    });
  },
});

export const {
  selectedInvoiceType,
  selectedYears,
  allPayments,
  selectedPaymentStatuses,
  selectedChildren,
  clearPayments,
} = paymentSlice.actions;

export default paymentSlice.reducer;
