/* eslint-disable no-return-assign */
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from 'reactstrap';
import { Plus } from 'react-feather';
import { closeModal, openModal } from '@store/modal';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import toast from 'react-hot-toast';
import useFilter from '@src/utility/hooks/useFilter';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';

import HumanResourcesState from '../types/HumanResourceState';
import {
  deleteDaysOffQuota,
  getDaysOffQuota,
  setHumanResourceDaysOffQuota,
} from '../store';
import HumanResourceDaysOffQuotaColumns from './HumanResourceDaysOffQuotaColumns';
import { HumanResourceDaysOffQuota } from '../types/HumanResourceDaysOffQuota';
import HumanResourceDaysOffQuotaForm from './HumanResourceDaysOffQuotaForm';

const HumanResourceDaysOffQuotaTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const { onFilterChange, query } = useFilter();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [loading] = useState(false);

  const openExamplaryActivityFormModal = (
    daysOffQuotaItem?: HumanResourceDaysOffQuota,
  ) => {
    dispatch(openModal({
      Component: HumanResourceDaysOffQuotaForm,
      title: t('Add new days off quota'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        daysOffQuotaItem,
      },
    }));
  };

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Days off quota will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteDaysOffQuota(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredWorkReports = humanResourceStore
                .allHumanResourceDaysOffQuota.filter((item) => item.id !== id);
              dispatch(setHumanResourceDaysOffQuota(filteredWorkReports));
              toast.success(t('Days off quota successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  useEffect(() => {
    if (humanResourceStore.currentHumanResource.id) {
      onFilterChange('userId', String(humanResourceStore.currentHumanResource.id));
    }
  }, [humanResourceStore.currentHumanResource]);

  useEffect(() => {
    const urlQuery = new URLSearchParams(query);

    const userId = urlQuery.get('userId');

    if (userId) {
      dispatch(getDaysOffQuota(query));
    }
  }, [query]);

  return (
    <DataTableWrapper
      dataSource={humanResourceStore.allHumanResourceDaysOffQuota}
      title={t('Days off quota')}
      subtitle={t('See, manage and filter days off quota table')}
      columns={HumanResourceDaysOffQuotaColumns(
        { handleDeleteClick, handleEditClick: openExamplaryActivityFormModal },
      )}
      dataLoaded={!loading}
      onChange={onFilterChange}
      query={query}
      showSearch={false}
      functionButton={(
        isManagePermissionActive(humanResourcePermissions, 'portfolioWorkReports')
          ? (
            <Button
              color="primary"
              className="text-nowrap mb-1 mt-2"
              outline
              onClick={() => openExamplaryActivityFormModal()}
            >
              <div className="d-flex justify-content-center">
                <Plus size={15} />
                &nbsp;
                <span>{t('Add days off')}</span>
              </div>
            </Button>
          ) : <></>
      )}
    />
  );
};

export default HumanResourceDaysOffQuotaTab;
