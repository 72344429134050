import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import SpinnerComponent from '@components/spinner/Fallback-spinner';
import {
  Card, CardBody, CardHeader, CardTitle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatDateLocalDateString } from '@src/views/invoice/components/InvoiceColumns';

import { getAllAnnouncements } from '../store';
import AnnouncementState from '../types/AnnouncementState';

const LatestAnnouncements = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const announcementStore = useSelector((state: AnnouncementState) => state.announcements);

  useEffect(() => {
    dispatch(getAllAnnouncements());
    setLoading(false);
  }, []);

  if (loading) {
    return <SpinnerComponent />;
  }
  return (
    <Card>
      <CardHeader className="d-flex">
        <div>
          <CardTitle tag="h4">{t('Latest announcements')}</CardTitle>
        </div>
      </CardHeader>
      <CardBody>
        {announcementStore.allAnnouncements.slice(0, 5).map((announcement) => (
          <div style={{ marginBottom: '8px' }} key={announcement.id}>
            <span
              style={{
                backgroundColor: '#7dcbec', padding: '0.2rem 0.5rem', borderRadius: '0.25rem', fontWeight: 'bold', color: 'white', marginRight: '8px',
              }}
            >
              {`${formatDateLocalDateString(announcement.date)} `}
            </span>
            <span
              style={{ display: 'inline' }}
              className="latest-announcements-list-items"
              dangerouslySetInnerHTML={{ __html: announcement.content }}
            />
          </div>
        ))}
        {announcementStore.allAnnouncements.length ? <></> : <p className="p-1 color-black" style={{ textAlign: 'center' }}>{t('There are no records to display')}</p>}
      </CardBody>
    </Card>
  );
};

export default LatestAnnouncements;
