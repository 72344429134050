import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import RequestStatus from '@src/types/RequestStatus';
import InventoryCategoryColumns from '../components/InventoryCategoryColumns';
import {
  deleteInventoryCategory,
  getAllInventoryCategories,
  setAllInventoryCategories,
} from '../store';
import InventoryState from '../types/InventoryState';
import InventoryCategoryForm from '../components/InventoryCategoryForm';
import InventoryCategory from '../types/InventoryCategory';

const InventoryCategories = () => {
  const dispatch = useDispatch<AppDispatch>();
  const inventoryStore = useSelector((store: InventoryState) => store.inventory);
  const { t } = useTranslation();
  const inventoryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllInventoryCategories());
    setDataLoaded(true);
  }, []);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Inventory category will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteInventoryCategory(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredEvaluationGroups = inventoryStore
                .allInventoryCategories.filter((item) => item.id !== id);
              dispatch(setAllInventoryCategories(filteredEvaluationGroups));
              toast.success(t('Inventory category successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const handleManageInventoryCategoryClick = (inventoryCategory?: InventoryCategory) => {
    dispatch(openModal({
      Component: InventoryCategoryForm,
      title: inventoryCategory ? t('Edit inventory category') : t('New inventory category'),
      open: true,
      componentProps: {
        inventory: inventoryCategory,
      },
    }));
  };

  return (
    <div>
      <DataTableWrapper
        dataSource={inventoryStore.allInventoryCategories}
        title={t('Inventory categories')}
        subtitle={t('See, manage and filter inventory categories table')}
        columns={InventoryCategoryColumns(
          { handleEditClick: handleManageInventoryCategoryClick, handleDeleteClick },
        )}
        showSearch
        dataLoaded={dataLoaded}
        functionButton={(
          isManagePermissionActive(inventoryPermission, 'AdministrationInventoryCategories')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => handleManageInventoryCategoryClick()}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new category')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default InventoryCategories;
