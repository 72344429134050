import {
  Button,
  Col, Form, Input, Row,
} from 'reactstrap';
import { useEffect } from 'react';
import CustomLabel from '@src/components/forms/CustomLabel';
import { Controller, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { useTranslation } from 'react-i18next';
import { closeModal } from '@store/modal';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@store/store';
import { successToast } from '@src/components/wrappers/ToastMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import RequestStatus from '@src/types/RequestStatus';
import { formatDateToShortDate } from '@src/utility/Utils';
import Select, { createFilter } from 'react-select';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { addToEducationalGroup, getEducationalGroups, getGroupData } from '../store';
import ChildrenState from '../types/ChildrenState';
import { GroupEnrolmentFormData, GroupEnrolmentSubmitData } from '../types/JispGroupEnrolment';
import Child from '../types/Child';
import { groupEnrolmentValidationSchema } from '../validation';

export const REPUBLIKA_SRBIJA_LABEL = 'Република Србија';

const ChildJispGroupModal = ({ child } : { child: Child }) => {
  const dispatch = useDispatch<AppDispatch>();
  const childrenStore = useSelector((store: ChildrenState) => store.children);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<GroupEnrolmentFormData>({
    defaultValues: {
      dateOfEnrollment: child.dateOfEnrollment ? formatDateToShortDate(
        child.dateOfEnrollment,
      ) : undefined,
      dateOfBirth: child.dateOfBirth ? formatDateToShortDate(child.dateOfBirth) : undefined,
      dateOfTermination: child.dateOfTermination ? formatDateToShortDate(
        child.dateOfTermination,
      ) : undefined,
      job: child.job,
      theRightOfAPersonalCompanion: { value: '0', label: 'No' },
      theRightToCareAndAssistance: { value: '0', label: 'No' },
      ppp: { value: '0', label: 'No' },
    },
    resolver: yupResolver(
      groupEnrolmentValidationSchema(),
    ),
  });

  useEffect(() => {
    if (!child) {
      dispatch(closeModal());
    } else {
      dispatch(getGroupData());
    }
  }, []);

  useEffect(() => {
    const schoolYear = watch('schoolYear');
    if (schoolYear) {
      dispatch(getEducationalGroups(Number(schoolYear.value)));
    }
  }, [watch('schoolYear')]);

  const submitHandler = async (data: GroupEnrolmentFormData) => {
    const formattedData: GroupEnrolmentSubmitData = {
      job: data.job,
      gender: data.gender?.label,
      genderId: Number(data.gender?.value),
      dateOfBirth: data.dateOfBirth,
      placeOfBirthAbroad: data.placeOfBirthAbroad,
      countryOfBirth: data.countryOfBirth?.label,
      countryOfBirthId: Number(data.countryOfBirth?.value),
      placeOfResidence: data.placeOfResidence?.label,
      placeOfResidenceId: Number(data.placeOfResidence?.value),
      placeOfResidenceAbroad: data.placeOfResidenceAbroad,
      municipalityOfResidence: data.municipalityOfResidence?.label,
      municipalityOfResidenceId: Number(data.municipalityOfResidence?.value),
      countryOfResidence: data.countryOfResidence?.label,
      countryOfResidenceId: Number(data.countryOfResidence?.value),
      nationalAffiliation: data.nationalAffiliation?.label,
      nationalAffiliationId: Number(data.nationalAffiliation?.value),
      motherTongue: data.motherTongue.map((mt) => mt?.label).join(', '),
      motherTongueIds: data.motherTongue.map((mt) => Number(mt?.value)),
      citizenship: data.citizenship.map((ctz) => ctz?.label).join(', '),
      citizenshipIds: data.citizenship.map((mt) => Number(mt?.value)),
      schoolYear: data.schoolYear?.label,
      schoolYearId: Number(data.schoolYear?.value),
      educationalGroup: data.educationalGroup?.label,
      educationalGroupId: Number(data.educationalGroup?.value),
      dateOfEnrollment: data.dateOfEnrollment,
      typeOfEnrollment: data.typeOfEnrollment?.label,
      typeOfEnrollmentId: Number(data.typeOfEnrollment?.value),
      ppp: data.ppp.value as '1' | '0',
      theRightToCareAndAssistance: data.theRightToCareAndAssistance?.value as '1' | '0',
      theRightOfAPersonalCompanion: data.theRightOfAPersonalCompanion?.value as '1' | '0',
      dateOfTermination: data.dateOfTermination,
    };
    console.log(formattedData);

    dispatch(addToEducationalGroup({ childId: child.id, data: formattedData })).then((res) => {
      if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
        dispatch(closeModal());
        successToast(t('Groups successfully retrieved'));
      }
    });
  };

  useEffect(() => {
    if (childrenStore.jispGroupInitialData.jispCountries) {
      const formData = getValues();
      const defaultCountry = childrenStore.jispGroupInitialData.jispCountries
        .find((country) => country.label === REPUBLIKA_SRBIJA_LABEL);

      reset({
        ...formData,
        countryOfBirth: defaultCountry,
        countryOfResidence: defaultCountry,
      });
    }
  }, [childrenStore.jispGroupInitialData]);

  return (
    <div>
      <Form onSubmit={handleSubmit(submitHandler)}>
        <Row>
          <Col md={6}>
            <CustomDatePicker
              label={t('Date of enrolment')}
              name="dateOfEnrollment"
              control={control}
              setValue={setValue}
              error={errors?.dateOfEnrollment?.message}
              required
              options={{ minDate: undefined }}
              defaultValue={watch('dateOfEnrollment')}
              useDefaultValue={false}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              label={t('Date of birth')}
              name="dateOfBirth"
              control={control}
              setValue={setValue}
              error={errors?.dateOfBirth?.message}
              required
              options={{ minDate: undefined }}
              defaultValue={watch('dateOfBirth')}
              useDefaultValue={false}
            />
          </Col>
          <Col md={6}>
            <CustomDatePicker
              label={t('Date of termination')}
              name="dateOfTermination"
              control={control}
              setValue={setValue}
              error={errors?.dateOfTermination?.message}
              options={{ minDate: undefined }}
              defaultValue={watch('dateOfTermination')}
              useDefaultValue={false}
            />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Gender')} />
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispGender}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.gender?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Country of birth')} />
            <Controller
              control={control}
              name="countryOfBirth"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispCountries}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.countryOfBirth?.message} />
          </Col>
          {watch('countryOfBirth')?.label === REPUBLIKA_SRBIJA_LABEL ? (
            <>
              <Col md={6}>
                <CustomLabel required name={t('Municipality of birth')} />
                <Controller
                  control={control}
                  name="municipalityOfBirth"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={childrenStore.jispGroupInitialData.jispMunicipality}
                      className="react-select"
                      classNamePrefix="select"
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.municipalityOfBirth?.message} />
              </Col>
              <Col md={6}>
                <CustomLabel required name={t('Place of birth')} />
                <Controller
                  control={control}
                  name="placeOfBirth"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={childrenStore.jispGroupInitialData.jispPlaceOfBirth}
                      className="react-select"
                      classNamePrefix="select"
                      placeholder={t('Select')}
                      filterOption={
                        createFilter({ ignoreAccents: false })
                      }
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.placeOfBirth?.message} />
              </Col>
            </>
          ) : (
            <Col md={6}>
              <CustomLabel name={t('Place of birth abroad')} htmlFor="placeOfBirthAbroad" />
              <Controller
                name="placeOfBirthAbroad"
                control={control}
                render={({ field }) => (
                  <Input id="placeOfBirthAbroad" invalid={!!errors.placeOfBirthAbroad} {...field} />
                )}
              />
              <CustomFormFeedback message={errors?.placeOfBirthAbroad?.message} />
            </Col>
          )}
          <Col md={6}>
            <CustomLabel required name={t('Country of residence')} />
            <Controller
              control={control}
              name="countryOfResidence"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispCountries}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.countryOfResidence?.message} />
          </Col>
          {watch('countryOfResidence')?.label === REPUBLIKA_SRBIJA_LABEL ? (
            <>
              <Col md={6}>
                <CustomLabel required name={t('Municipality of residence')} />
                <Controller
                  control={control}
                  name="municipalityOfResidence"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={childrenStore.jispGroupInitialData.jispMunicipality}
                      className="react-select"
                      classNamePrefix="select"
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.municipalityOfResidence?.message} />
              </Col>
              <Col md={6}>
                <CustomLabel required name={t('Place of residence')} />
                <Controller
                  control={control}
                  name="placeOfResidence"
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={childrenStore.jispGroupInitialData.jispMunicipality}
                      // this guy is left to check
                      className="react-select"
                      classNamePrefix="select"
                      placeholder={t('Select')}
                    />
                  )}
                />
                <CustomFormFeedback message={errors?.placeOfResidence?.message} />
              </Col>
            </>
          ) : (
            <Col md={6}>
              <CustomLabel name={t('Place of residence abroad')} htmlFor="placeOfResidenceAbroad" />
              <Controller
                name="placeOfResidenceAbroad"
                control={control}
                render={({ field }) => (
                  <Input id="placeOfResidenceAbroad" invalid={!!errors.placeOfResidenceAbroad} {...field} />
                )}
              />
              <CustomFormFeedback message={errors?.placeOfResidenceAbroad?.message} />
            </Col>
          )}
          <Col md={6}>
            <CustomLabel required name={t('Nationality')} />
            <Controller
              control={control}
              name="nationalAffiliation"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispNationality}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.nationalAffiliation?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Citizenship')} />
            <Controller
              control={control}
              name="citizenship"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispNationality}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                  isMulti
                />
              )}
            />
            <CustomFormFeedback message={errors?.citizenship?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Mother tongue')} />
            <Controller
              control={control}
              name="motherTongue"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispMotherTongue}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                  isMulti
                />
              )}
            />
            <CustomFormFeedback message={errors?.motherTongue?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('School year')} />
            <Controller
              control={control}
              name="schoolYear"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispSchoolYear}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.schoolYear?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Educational group')} />
            <Controller
              control={control}
              name="educationalGroup"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispEducationalGroup}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.educationalGroup?.message} />
          </Col>
          {/* vaspitna grupa */}
          <Col md={6}>
            <CustomLabel name={t('IOP')} />
            <Controller
              control={control}
              name="iop"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispIOPType}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.iop?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Right to a care and assistance')} />
            <Controller
              control={control}
              name="theRightToCareAndAssistance"
              render={({ field }) => (
                <Select
                  {...field}
                  options={[{ value: 0, label: 'No' }, { value: 1, label: 'Yes' }]}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.theRightToCareAndAssistance?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('The right of a personal companion')} />
            <Controller
              control={control}
              name="theRightOfAPersonalCompanion"
              render={({ field }) => (
                <Select
                  {...field}
                  options={[{ value: '0', label: 'No' }, { value: '1', label: 'Yes' }]}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.theRightOfAPersonalCompanion?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('The child attends PPP')} />
            <Controller
              control={control}
              name="ppp"
              render={({ field }) => (
                <Select
                  {...field}
                  options={[{ value: '0', label: 'No' }, { value: '1', label: 'Yes' }]}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.ppp?.message} />
          </Col>
          <Col md={6}>
            <CustomLabel required name={t('Type of entry')} />
            <Controller
              control={control}
              name="typeOfEnrollment"
              render={({ field }) => (
                <Select
                  {...field}
                  options={childrenStore.jispGroupInitialData.jispTypeOfEntry}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder={t('Select')}
                />
              )}
            />
            <CustomFormFeedback message={errors?.typeOfEnrollment?.message} />
          </Col>
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={!!Object.keys(errors).length}
            >
              {t('Add') }
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              onClick={() => dispatch(closeModal())}
            >
              {t('Close')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChildJispGroupModal;
