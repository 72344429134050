import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isManagePermissionActive, isDeletePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { ProfessionalDevelopmentEvaluations } from '../../types/portfolio/ProfessionalDevelopmentEvaluation';

const ProfessionalDevelopementEvaluationsColumns = ({ handleEditClick, handleDeleteClick }:
  {
    handleDeleteClick: (id: number) => void,
    handleEditClick: (item: ProfessionalDevelopmentEvaluations) => void,
  }) => {
  const { t } = useTranslation();
  const childrenPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<ProfessionalDevelopmentEvaluations>[] = [
    {
      name: t('Parent'),
      minWidth: 'Activity description',
      cell: ({ activityDescription }) => (
        <p className="m-0">{activityDescription}</p>
      ),
    },
    {
      name: t('Role'),
      minWidth: '150px',
      selector: ({ role }) => role,
    },
    {
      name: t('Number of points'),
      minWidth: '150px',
      selector: ({ numberOfPoints }) => numberOfPoints,
    },
    {
      name: t('Evidence'),
      minWidth: '150px',
      selector: ({ evidence }) => evidence,
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(childrenPermission, 'portfolioProfessionalDevelopmentEvaluations')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(childrenPermission, 'portfolioProfessionalDevelopmentEvaluations')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => handleDeleteClick(Number(row.id))}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default ProfessionalDevelopementEvaluationsColumns;
