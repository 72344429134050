import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isManagePermissionActive, isDeletePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { formatDateLocalDateString } from '@src/views/payments/components/PaymentColumns';
import { ExemplaryActivity } from '../../types/portfolio/ExemplaryActivites';

const ExamplaryActivitiesColumns = ({ handleEditClick, handleDeleteClick }:
  {
    handleDeleteClick: (id: number) => void,
    handleEditClick: (exemplaryActivity: ExemplaryActivity) => void,
  }) => {
  const { t } = useTranslation();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<ExemplaryActivity>[] = [
    {
      name: t('Evidence'),
      minWidth: '150px',
      cell: ({ evidence }) => (
        <p className="m-0">{evidence}</p>
      ),
    },
    {
      name: t('Object'),
      minWidth: '150px',
      selector: ({ tenantObject }) => tenantObject.name,
    },
    {
      name: t('Group'),
      minWidth: '150px',
      selector: ({ tenantGroup }) => tenantGroup.name,
    },
    {
      name: t('Date'),
      minWidth: '150px',
      selector: ({ date }) => formatDateLocalDateString(new Date(date)),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(humanResourcePermissions, 'portfolioExemplaryActivities')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(humanResourcePermissions, 'portfolioExemplaryActivities')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => handleDeleteClick(Number(row.id))}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default ExamplaryActivitiesColumns;
