import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleErrorMessage } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import { EventInput } from '@fullcalendar/react';
import { HumanResource } from '../types/HumanResource';
import { HumanResourceFile } from '../types/HumanResourceFile';
import { HumanResourceRequest, HumanResourceRequestChangeStatus } from '../types/HumanResourceDaysOff';
import { ProfessionalDevelopment, ProfessionalDevelopmentCategory } from '../types/portfolio/ProfessionalDevelopment';
import { PersonalDevelopmentPlan } from '../types/portfolio/PersonalDevelopmentPlan';
import { DevelopmentReport } from '../types/portfolio/DevelopmentReport';
import { ProfessionalPhilosophies } from '../types/portfolio/ProfessionalPhilosophies';
import { WorkEffectiveness } from '../types/portfolio/WorkEffectiveness';
import { WorkEvaluations } from '../types/portfolio/WorkEvaluations';
import { ProfessionalDevelopmentEvaluations } from '../types/portfolio/ProfessionalDevelopmentEvaluation';
import { ExemplaryActivity } from '../types/portfolio/ExemplaryActivites';
import { WorkReport } from '../types/portfolio/WorkReport';
import { HumanResourceDaysOffQuota } from '../types/HumanResourceDaysOffQuota';

export const getAllHumanResources = createAsyncThunk('goKinder/getAllHumanResources', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/human-resources${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getHumanResource = createAsyncThunk('goKinder/getHumanResource', async (id: number) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/human-resources/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createNewHumanResources = createAsyncThunk('goKinder/createNewHumanResources', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/human-resources`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editHumanResources = createAsyncThunk('goKinder/editHumanResources', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/human-resources/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteHumanResources = createAsyncThunk('goKinder/deleteHumanResources', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/human-resources/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getHumanResourceFiles = createAsyncThunk('goKinder/getHumanResourceFiles', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/human-resources/files${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewHumanResourceFile = createAsyncThunk('goKinder/addNewHumanResourceFile', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/human-resources/files`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editHumanResourceFile = createAsyncThunk('goKinder/editHumanResourceFile', async ({ id, data }: { id: number, data: any }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/human-resources/files/${id}`, data);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteHumanResourcesFile = createAsyncThunk('goKinder/deleteHumanResourcesFile', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/human-resources/files/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getDaysOffRequest = createAsyncThunk('goKinder/getDaysOffRequests', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-requests`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getDaysOffRequestsByHumanResource = createAsyncThunk('goKinder/getDaysOffRequestsByHumanResource', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-requests/administration${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createDayOffRequest = createAsyncThunk('goKinder/createDayOffRequest', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-requests`, { ...data });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const updateDayOffRequest = createAsyncThunk('goKinder/updateDayOffRequest', async (data: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-requests/${data.id}`, { ...data });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteDayOffRequest = createAsyncThunk('goKinder/deleteDayOffRequest', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-requests/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const changeDayOffRequestStatus = createAsyncThunk('goKinder/changeDayOffRequestStatus', async (data: HumanResourceRequestChangeStatus) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-requests/change-status`, { ...data });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getProfessionalDevelopmentCategories = createAsyncThunk('goKinder/getProfessionalDevelopmentCategories', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development/categories`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getSchoolYears = createAsyncThunk('goKinder/getSchoolYears', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/years/school-years`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getTypesOfParticipation = createAsyncThunk('goKinder/getTypesOfParticipation', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development/types-of-participation`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getProfessionalDevelopments = createAsyncThunk('goKinder/getProfessionalDevelopments', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createNewProfessionalDevelopment = createAsyncThunk('goKinder/createNewProfessionalDevelopment', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editProfessionalDevelopment = createAsyncThunk('goKinder/editProfessionalDevelopment', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteProfessionalDevelopment = createAsyncThunk('goKinder/deleteProfessionalDevelopment', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getPersonalDevelopmentPlan = createAsyncThunk('goKinder/getPersonalDevelopmentPlan', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/personal-development-plan${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createNewPersonalDevelopmentPlan = createAsyncThunk('goKinder/createNewPersonalDevelopmentPlan', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/personal-development-plan`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editPersonalDevelopmentPlan = createAsyncThunk('goKinder/editPersonalDevelopmentPlan', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/personal-development-plan/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getDevelopmentReport = createAsyncThunk('goKinder/getDevelopmentReport', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-report${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createDevelopmentReport = createAsyncThunk('goKinder/createDevelopmentReport', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-report`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editDevelopmentReport = createAsyncThunk('goKinder/editDevelopmentReport', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-report/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getProfessionalPhilosophies = createAsyncThunk('goKinder/getProfessionalPhilosophies', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-philosophies${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createProfessionalPhilosophies = createAsyncThunk('goKinder/createProfessionalPhilosophies', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-philosophies`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editProfessionalPhilosophies = createAsyncThunk('goKinder/editProfessionalPhilosophies', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-philosophies/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getWorkEffectiveness = createAsyncThunk('goKinder/getWorkEffectiveness', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/work-effectiveness${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createWorkEffectiveness = createAsyncThunk('goKinder/createWorkEffectiveness', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/work-effectiveness`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editWorkEffectiveness = createAsyncThunk('goKinder/editWorkEffectiveness', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/work-effectiveness/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getWorkEvaluations = createAsyncThunk('goKinder/getWorkEvaluations', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/work-evaluations${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createWorkEvaluations = createAsyncThunk('goKinder/createWorkEvaluations', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/work-evaluations`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editWorkEvaluations = createAsyncThunk('goKinder/editWorkEvaluations', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/work-evaluations/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getProfessionalDevelopmentEvaluations = createAsyncThunk('goKinder/getProfessionalDevelopmentEvaluations', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-evaluations${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createProfessionalDevelopmentEvaluations = createAsyncThunk('goKinder/createProfessionalDevelopmentEvaluations', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-evaluations`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editProfessionalDevelopmentEvaluations = createAsyncThunk('goKinder/editProfessionalDevelopmentEvaluations', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-evaluations/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteProfessionalDevelopmentEvaluations = createAsyncThunk('goKinder/deleteProfessionalDevelopmentEvaluations', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/portfolio/professional-development-evaluations/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getExemplaryActivities = createAsyncThunk('goKinder/getExemplaryActivities', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/exemplary-activities${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createExemplaryActivity = createAsyncThunk('goKinder/createExemplaryActivity', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/exemplary-activities`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editExemplaryActivity = createAsyncThunk('goKinder/editExemplaryActivity', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/exemplary-activities/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteExemplaryActivity = createAsyncThunk('goKinder/deleteExemplaryActivity', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/portfolio/exemplary-activities/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getWorkReportTypes = createAsyncThunk('goKinder/getWorkReportTypes', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/work-reports/types-of-work-reports`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getWorkReports = createAsyncThunk('goKinder/getWorkReports', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/portfolio/work-reports${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createWorkReport = createAsyncThunk('goKinder/createWorkReport', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/portfolio/work-reports`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editWorkReport = createAsyncThunk('goKinder/editWorkReport', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/portfolio/work-reports/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteWorkReport = createAsyncThunk('goKinder/deleteWorkReport', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/portfolio/work-reports/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getDaysOffQuota = createAsyncThunk('goKinder/getDaysOffQuota', async (query: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-quota${query ? '?' : ''}${query ?? ''}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createDaysOffQuota = createAsyncThunk('goKinder/createDaysOffQuota', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-quota`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editDaysOffQuota = createAsyncThunk('goKinder/editDaysOffQuota', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-quota/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteDaysOffQuota = createAsyncThunk('goKinder/deleteDaysOffQuota', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/human-resources/days-off-quota/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const humanResourcesSlice = createSlice({
  name: 'humanResources',
  initialState: {
    allHumanResources: [] as HumanResource[],
    currentHumanResource: {} as HumanResource,
    selectedInventoryCategories: [] as SelectedOptions[],
    selectedUsers: [] as SelectedOptions[],
    humanResourceFiles: [] as HumanResourceFile[],
    allHumanResourceDaysOffRequests: [] as HumanResourceRequest[],
    allHumanResourceDaysOffQuota: [] as HumanResourceDaysOffQuota[],
    calendarEvents: [] as EventInput[],
    professionalDevelopmentCategories: [] as ProfessionalDevelopmentCategory[],
    selectedProfessionalDevelopmentCategories: [] as SelectedOptions[],
    professionalDevelopments: [] as ProfessionalDevelopment[],
    schoolYears: [] as SelectedOptions[],
    typesOfParticipation: [] as SelectedOptions[],
    currentPersonalDevelopmentPlan: {} as PersonalDevelopmentPlan,
    currentDevelopmentReport: {} as DevelopmentReport,
    currentProfessionalPhilosophies: {} as ProfessionalPhilosophies,
    currentWorkEffectiveness: {} as WorkEffectiveness,
    currentWorkEvaluations: {} as WorkEvaluations,
    professionalDevelopmentEvaluations: [] as ProfessionalDevelopmentEvaluations[],
    exemplaryActivites: [] as ExemplaryActivity[],
    selectedTenantObjects: [] as SelectedOptions[],
    selectedTenantGroups: [] as SelectedOptions[],
    workReports: [] as WorkReport[],
    workReportTypes: [] as SelectedOptions[],
  },
  reducers: {
    setAllHumanResources: (state, action) => {
      state.allHumanResources = action.payload;
    },
    clearCurrentHumanResource: (state) => {
      state.currentHumanResource = {} as HumanResource;
    },
    setSelectedUsers: (state, action) => {
      state.selectedUsers = action.payload;
    },
    setSelectedInventoryCategories: (state, action) => {
      state.selectedInventoryCategories = action.payload;
    },
    setAllHumanResourceDaysOffRequests: (state, action) => {
      state.allHumanResourceDaysOffRequests = action.payload;
    },
    setCalendarEvents: (state, action) => {
      state.calendarEvents = action.payload;
    },
    setProfessionalDevelopments: (state, action) => {
      state.professionalDevelopments = action.payload;
    },
    setSelectedProfessionalDevelopmentCategories: (state, action) => {
      state.selectedProfessionalDevelopmentCategories = action.payload;
    },
    setProfessionalDevelopmentEvaluations: (state, action) => {
      state.professionalDevelopmentEvaluations = action.payload;
    },
    setExemplaryActivities: (state, action) => {
      state.exemplaryActivites = action.payload;
    },
    setSelectedTenantObjects: (state, action) => {
      state.selectedTenantObjects = action.payload;
    },
    setSelectedTenantGroups: (state, action) => {
      state.selectedTenantGroups = action.payload;
    },
    setWorkReports: (state, action) => {
      state.workReports = action.payload;
    },
    setHumanResourceDaysOffQuota: (state, action) => {
      state.allHumanResourceDaysOffQuota = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllHumanResources.fulfilled, (state, action) => {
      state.allHumanResources = action.payload.data;
    });
    builder.addCase(getHumanResource.fulfilled, (state, action) => {
      state.currentHumanResource = action.payload.data;
    });
    builder.addCase(getHumanResourceFiles.fulfilled, (state, action) => {
      state.humanResourceFiles = action.payload.data;
    });
    builder.addCase(getDaysOffRequestsByHumanResource.fulfilled, (state, action) => {
      state.allHumanResourceDaysOffRequests = action.payload.data;
    });
    builder.addCase(getDaysOffRequest.fulfilled, (state, action) => {
      state.allHumanResourceDaysOffRequests = action.payload.data;
    });
    builder.addCase(getProfessionalDevelopmentCategories.fulfilled, (state, action) => {
      state.professionalDevelopmentCategories = action.payload.data;
    });
    builder.addCase(getSchoolYears.fulfilled, (state, action) => {
      const transformedSchoolYears = action.payload.data.map(
        (object: { id: number, value: string }) => ({ label: object.value, value: object.id }),
      );
      state.schoolYears = transformedSchoolYears;
    });
    builder.addCase(getTypesOfParticipation.fulfilled, (state, action) => {
      const transformedTypesOfParticipation = action.payload.data.typesOfParticipation.map(
        (object: string) => ({ label: object, value: object }),
      );
      state.typesOfParticipation = transformedTypesOfParticipation;
    });
    builder.addCase(getProfessionalDevelopments.fulfilled, (state, action) => {
      state.professionalDevelopments = action.payload.data;
    });
    builder.addCase(getPersonalDevelopmentPlan.fulfilled, (state, action) => {
      state.currentPersonalDevelopmentPlan = action.payload.data;
    });
    builder.addCase(getDevelopmentReport.fulfilled, (state, action) => {
      state.currentDevelopmentReport = action.payload.data;
    });
    builder.addCase(getProfessionalPhilosophies.fulfilled, (state, action) => {
      state.currentProfessionalPhilosophies = action.payload.data;
    });
    builder.addCase(getWorkEffectiveness.fulfilled, (state, action) => {
      state.currentWorkEffectiveness = action.payload.data;
    });
    builder.addCase(getWorkEvaluations.fulfilled, (state, action) => {
      state.currentWorkEvaluations = action.payload.data;
    });
    builder.addCase(getProfessionalDevelopmentEvaluations.fulfilled, (state, action) => {
      state.professionalDevelopmentEvaluations = action.payload.data;
    });
    builder.addCase(getExemplaryActivities.fulfilled, (state, action) => {
      state.exemplaryActivites = action.payload.data;
    });
    builder.addCase(getWorkReports.fulfilled, (state, action) => {
      state.workReports = action.payload.data;
    });
    builder.addCase(getWorkReportTypes.fulfilled, (state, action) => {
      const transformedTypesOfWorkReports = action.payload.data.TypeOfWorkReports.map(
        (object: string) => ({ label: object, value: object }),
      );
      state.workReportTypes = transformedTypesOfWorkReports;
    });
    builder.addCase(getDaysOffQuota.fulfilled, (state, action) => {
      state.allHumanResourceDaysOffQuota = action.payload.data;
    });
  },
});

export const {
  setAllHumanResources,
  clearCurrentHumanResource,
  setSelectedInventoryCategories,
  setSelectedUsers,
  setAllHumanResourceDaysOffRequests,
  setCalendarEvents,
  setProfessionalDevelopments,
  setSelectedProfessionalDevelopmentCategories,
  setProfessionalDevelopmentEvaluations,
  setExemplaryActivities,
  setSelectedTenantGroups,
  setSelectedTenantObjects,
  setWorkReports,
  setHumanResourceDaysOffQuota,
} = humanResourcesSlice.actions;

export default humanResourcesSlice.reducer;
