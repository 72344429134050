import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Row,
} from 'reactstrap';
import PostPagination from '@src/views/posts/components/PostPagination';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '@store/modal';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { AppDispatch } from '@store/store';
import { getHumanResourceFiles } from '../store';
import HumanResourceFileCard from './HumanResourceFileCard';
import { HumanResourceFile } from '../types/HumanResourceFile';
import HumanResourcesState from '../types/HumanResourceState';
import HumanResourceFileForm from './HumanResourceFileForm';

const HumanResourceFiles = () => {
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const { t } = useTranslation();

  const [files, setFiles] = useState<HumanResourceFile[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [loadOnce, setLoadOnce] = useState(false);
  const postPerPage = 6;

  useEffect(() => {
    setFiles(humanResourceStore?.humanResourceFiles.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
    if ((activePage - 1) * postPerPage === humanResourceStore?.humanResourceFiles.length) {
      setActivePage(activePage === 1 ? 1 : activePage - 1);
    } else if (loadOnce) {
      setActivePage(1);
    }
    if (humanResourceStore?.humanResourceFiles.length) {
      setLoadOnce(true);
    }
  }, [humanResourceStore?.humanResourceFiles]);

  useEffect(() => {
    setFiles(humanResourceStore?.humanResourceFiles.slice(((activePage - 1) * postPerPage),
      postPerPage * activePage));
  }, [activePage]);

  useEffect(() => {
    if (humanResourceStore.currentHumanResource.id) {
      dispatch(getHumanResourceFiles(`userId=${humanResourceStore.currentHumanResource.id}`));
    }
  }, [humanResourceStore.currentHumanResource.id]);

  return (
    <>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <div className="mt-2">
            <CardTitle tag="h4">{t('Staff\'s files overview')}</CardTitle>
            <CardSubtitle className="mt-1">{t('View, add and edit files')}</CardSubtitle>
          </div>
          <Button
            color="primary"
            className="text-nowrap mb-1 mt-2"
            outline
            onClick={() => {
              dispatch(openModal({
                Component: HumanResourceFileForm,
                title: t('Add new file'),
                open: true,
                modalProps: { size: 'lg' },
              }));
            }}
          >
            <div className="d-flex justify-content-center">
              <Plus size={15} />
              &nbsp;
              <span>{t('Add file')}</span>
            </div>
          </Button>
        </CardHeader>
      </Card>
      <Row>
        {files.map((item) => <HumanResourceFileCard key={item.id} details={item} />)}
      </Row>
      {humanResourceStore?.humanResourceFiles?.length > 0
        && (
          <PostPagination
            totalPages={
              Number(Math.ceil(humanResourceStore?.humanResourceFiles?.length / postPerPage) || 1)
            }
            activePage={activePage}
            setActive={setActivePage}
          />
        )}
    </>
  );
};

export default HumanResourceFiles;
