import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const humanResourceValidationSchema = (answer: string, answerEng: string) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    question: yup.string().required(t('Question (rs) is required')),
    answer: yup.mixed().test('required', t('Answer (rs) is required'), () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(answer, 'text/html');
      const cleanTitle = doc.body.textContent ?? '';
      return cleanTitle.trim().length !== 0;
    }),
    questionEng: yup.string().required(t('Question (en) is required')),
    answerEng: yup.mixed().test('required', t('Answer (en) is required'), () => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(answerEng, 'text/html');
      const cleanTitle = doc.body.textContent ?? '';
      return cleanTitle.trim().length !== 0;
    }),
  });
  return validation;
};

const humanResourceFileValidationSchema = (
  files: File[],
  isEdit: boolean,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    file: yup.mixed().test('required', t('File is required'), () => isEdit || files.length === 1),
  });
  return validation;
};

const humanResourceRequestValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    daysOffRequestCategory: yup.mixed().test('required', t('Category is required'), (category) => !!category?.value),
  });
  return validation;
};

const humanResourceProfessionalDevelopmentValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    date: yup.string().required(t('Date is required')),
    categoryId: yup.mixed().test('required', t('Category is required'), (category) => !!category?.value),
    schoolYearId: yup.mixed().test('required', t('Year is required'), (schoolYear) => !!schoolYear?.value),
    participationType: yup.mixed().test('required', t('Participation type is required'), (participationType) => !!participationType?.value),
    title: yup.string().required(t('Name is required')),
  });
  return validation;
};

const humanResourceProfessionalDevelopmentEvaluationValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    date: yup.string().required(t('Date is required')),
    activityDescription: yup.string().required(t('Activity description is required')),
    role: yup.string().required(t('Role is required')),
    numberOfPoints: yup.string().required(t('Number of points is required')),
    evidence: yup.string().required(t('Evidence is required')),
    schoolYearId: yup.mixed().test('required', t('Year is required'), (schoolYear) => !!schoolYear?.value),
  });
  return validation;
};

const humanResourceExemplaryActivitiesValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    date: yup.string().required(t('Date is required')),
    schoolYearId: yup.mixed().test('required', t('Year is required'), (schoolYear) => !!schoolYear?.value),
    tenantObjectId: yup.mixed().test('required', t('Object is required'), (schoolYear) => !!schoolYear?.value),
    tenantGroupId: yup.mixed().test('required', t('Group is required'), (schoolYear) => !!schoolYear?.value),
  });
  return validation;
};

const humanResourceWorkReportsValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    date: yup.string().required(t('Date is required')),
    schoolYearId: yup.mixed().test('required', t('Year is required'), (schoolYear) => !!schoolYear?.value),
    realizedActivity: yup.string().required(t('Realized activities is required')),
    numberOfPoints: yup.string().required(t('Number of points is required')),
    workReportType: yup.mixed().test('required', t('Type is required'), (schoolYear) => !!schoolYear?.value),
  });
  return validation;
};

export {
  humanResourceValidationSchema,
  humanResourceFileValidationSchema,
  humanResourceRequestValidationSchema,
  humanResourceProfessionalDevelopmentValidationSchema,
  humanResourceProfessionalDevelopmentEvaluationValidationSchema,
  humanResourceExemplaryActivitiesValidationSchema,
  humanResourceWorkReportsValidationSchema,
};
