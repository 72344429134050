/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import InventoryItem from '../types/InventoryItem';

const InventoryItemColumns = ({ handleDeleteClick, handleEditClick }:
  { handleEditClick: (row: InventoryItem) => void,
     handleDeleteClick: (invoiceId: number) => void }) => {
  const { t } = useTranslation();
  const inventoryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<InventoryItem>[] = [
    {
      name: t('Title'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.name}`}</p>
      ),
    },
    {
      name: t('Code'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.code}`}</p>
      ),
    },
    {
      name: t('Object'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.tenantObject.name}`}</p>
      ),
    },
    {
      name: t('Categories'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.inventoryCategories.map((cat) => cat.name).join(', ')}`}</p>
      ),
    },
    {
      name: t('Initial quantity'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.quantity}`}</p>
      ),
    },
    {
      name: t('Current quantity'),
      minWidth: '100px',
      cell: (row) => (
        <p className="m-0" key={row.id}>{`${row.currentQuantity}`}</p>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(inventoryPermission, 'Inventory')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                size="sm"
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(inventoryPermission, 'Inventory')
            ? (
              <Button
                size="sm"
                className="btn btn-icon"
                color="transparent"
                onClick={() => { handleDeleteClick(row.id ?? 0); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default InventoryItemColumns;
