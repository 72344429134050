import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import Select from 'react-select';

import { inventoryValidationSchema } from '../validation/index';

import {
  createNewInventoryItem,
  editInventoryItem,
  getAllInventoryCategories,
  getAllInventoryItems,
  setSelectedInventoryCategories,
  setSelectedTenantObjects,
} from '../store';
import { InventoryItemFormData, InventoryItemSubmitData } from '../types/InventoryItemSubmitData';
import InventoryItem from '../types/InventoryItem';
import InventoryState from '../types/InventoryState';

const InventoryItemForm = ({ inventory }: { inventory?: InventoryItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const AllTenantObjects = useSelector(
    (store: TenantObjectState) => store.tenantObjects.allTenantObjects,
  );
  const inventoryStore = useSelector((store: InventoryState) => store.inventory);
  const defaultValues: InventoryItemFormData = inventory ? {
    name: inventory.name,
    code: inventory.code,
    quantity: inventory.quantity,
    tenantObjectId: { value: inventory.tenantObjectId, label: inventory.tenantObject.name },
    categoryIds: inventory.inventoryCategories
      .map((category) => ({ label: category.name, value: category.id })),
  } as InventoryItemFormData : {
    name: '',
    code: '',
    quantity: 1,
  } as InventoryItemFormData;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<InventoryItemFormData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      inventoryValidationSchema(),
    ),
  });

  useEffect(() => {
    dispatch(getAllInventoryCategories());
    dispatch(getAllTenantObjects());
  }, []);

  useEffect(() => {
    if (AllTenantObjects.length) {
      dispatch(setSelectedTenantObjects(AllTenantObjects.map(
        (object) => ({ label: t(object.name), value: object.id }),
      )));
    }
  }, [AllTenantObjects]);

  useEffect(() => {
    if (inventoryStore.allInventoryCategories.length) {
      dispatch(setSelectedInventoryCategories(inventoryStore.allInventoryCategories.map(
        (object) => ({ label: t(object.name), value: object.id }),
      )));
    }
  }, [inventoryStore.allInventoryCategories]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values: InventoryItemFormData) => {
    try {
      const newInventoryItem: InventoryItemSubmitData = {
        name: values.name,
        code: values.code,
        quantity: values.quantity,
        tenantObjectId: Number(values.tenantObjectId.value),
        currentQuantity: values.currentQuantity,
        note: values.note,
      } as InventoryItemSubmitData;

      if (values.categoryIds?.length) {
        newInventoryItem.categoryIds = values.categoryIds.map((category) => Number(category.value));
      }

      if (inventory?.id) {
        await dispatch(editInventoryItem(
          { id: +inventory.id, data: newInventoryItem },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Inventory item successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getAllInventoryItems());
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createNewInventoryItem(newInventoryItem)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Inventory item successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            dispatch(getAllInventoryItems());
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomLabel name={t('Name')} for="name" required />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input id="name" invalid={!!errors.name} {...field} />
            )}
          />
          {errors?.name?.message
          && <CustomFormFeedback message={errors.name.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Object')} htmlFor="tenantObjectId" required />
          <Controller
            name="tenantObjectId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={inventoryStore.selectedTenantObjects}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.tenantObjectId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Categories')} htmlFor="categoryIds" />
          <Controller
            name="categoryIds"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={inventoryStore.selectedInventoryCategories}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
                isMulti
              />
            )}
          />
          <CustomFormFeedback message={errors?.categoryIds?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Initial quantity')} for="quantity" required />
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <Input id="quantity" type="number" invalid={!!errors.quantity} {...field} />
            )}
          />
          {errors?.quantity?.message
          && <CustomFormFeedback message={errors.quantity.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Current quantity')} for="currentQuantity" required />
          <Controller
            name="currentQuantity"
            control={control}
            render={({ field }) => (
              <Input id="currentQuantity" type="number" invalid={!!errors.currentQuantity} {...field} />
            )}
          />
          {errors?.currentQuantity?.message
          && <CustomFormFeedback message={errors.currentQuantity.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Code')} for="code" />
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <Input id="code" invalid={!!errors.code} {...field} />
            )}
          />
          {errors?.code?.message
          && <CustomFormFeedback message={errors.code.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Note')} for="note" />
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <Input id="note" type="textarea" invalid={!!errors.note} {...field} />
            )}
          />
          {errors?.note?.message
          && <CustomFormFeedback message={errors.note.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {inventory?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InventoryItemForm;
