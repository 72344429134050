import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleErrorMessage } from '@src/utility/Utils';
import { EventInput } from '@fullcalendar/react';
import axios from 'axios';
import NonWorkingDay from '../types/NonWorkingDay';
import NonWorkingDaySubmitData from '../types/NonWorkingDaySubmitData';
import EditNonWorkingDaySubmitData from '../types/EditNonWorkingDaySubmitData';

export const getAllNonWorkingDays = createAsyncThunk('goKinder/getAllNonWorkingDays', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/non-working-days`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createNonWorkingDay = createAsyncThunk('goKinder/createNonWorkingDay', async (data: NonWorkingDaySubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/non-working-days/`, { ...data });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteNonWorkingDay = createAsyncThunk('goKinder/deleteNonWorkingDay', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/non-working-days/${id}`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const updateNonWorkingDay = createAsyncThunk('goKinder/updateNonWorkingDay', async (data: EditNonWorkingDaySubmitData) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/non-working-days/${data.id}`, { ...data });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createMultipleNonWorkingDays = createAsyncThunk('goKinder/createMultipleNonWorkingDays', async (data: NonWorkingDaySubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/non-working-days/multiple`, { ...data });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const nonWorkingDaysSlice = createSlice({
  name: 'nonWorkingDays',
  initialState: {
    allNonWorkingDays: [] as NonWorkingDay[],
    calendarEvents: [] as EventInput[],
  },
  reducers: {
    setCalendarEvents: (state, action) => {
      state.calendarEvents = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNonWorkingDays.fulfilled, (state, action) => {
      state.allNonWorkingDays = action.payload.data;
    });
  },
});

export const { setCalendarEvents } = nonWorkingDaysSlice.actions;

export default nonWorkingDaysSlice.reducer;
