import CustomFilter from '@src/components/forms/CustomFilter';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import Filter from '@src/types/Filter';
import FilterValue from '@src/types/FilterValue';
import { formatDateToShortDate } from '@src/utility/Utils';
import { useState } from 'react';
import {
  FieldPath,
  PathValue,
  useForm,
  FieldValues,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Col, Input, Row,
} from 'reactstrap';

type Props = {
  filters: Filter[];
  onChange: (
    name: string,
    value: FilterValue,
  ) => void;
  onFilterClick?: () => void,
  setSearched?: (searched: boolean) => void,
}

// eslint-disable-next-line
let debounceTimeout: NodeJS.Timeout;

const PageFilters: React.FC<Props> = ({
  filters, onChange, onFilterClick, setSearched,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState('filters');

  const {
    control,
    setValue,
  } = useForm();

  const toggle = (id: string) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id);
    }
  };

  const debouncedOnChange = (name: string, value: string) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      onChange(name, value);
    }, 300);
  };

  const getDateFormat = (datePickerFormat: 'year' |'month-year'| 'full-date') => {
    if (datePickerFormat === 'year') {
      return 'Y';
    }
    if (datePickerFormat === 'month-year') {
      return 'M Y';
    }
    return 'd. m. Y.';
  };

  return (
    <>
      {/* eslint-ignore-next-line
     @ts-ignore */}
      <Accordion open={open} toggle={toggle} style={{ zIndex: 2 }}>
        <AccordionItem>
          <AccordionHeader targetId="filters">{t('Filters')}</AccordionHeader>
          <AccordionBody accordionId="filters">
            <Row className="px-1 mt-50">
              {filters.filter(
                (filter) => filter.options && filter.options.length > 0,
              ).map((filter) => (
                <Col md={4} key={filter.name} className="pb-50">
                  <CustomLabel name={t(filter.label)} />
                  <CustomFilter
                    options={filter?.options ?? []}
                    name={filter.name}
                    onChange={onChange}
                    isMulti={filter.isMulti}
                    defaultValue={filter.defaultValue}
                  />
                </Col>
              ))}
              {filters.filter((filter) => filter.datePicker).map((filter) => {
                if (filter.datePicker !== 'year') {
                  return (
                    <Col md={4} key={filter.name} className="pb-50">
                      <CustomDatePicker
                        label={t(filter.label)}
                        name={filter.name}
                        control={control}
                        error=""
                        useDefaultValue={false}
                        options={{ minDate: undefined, dateFormat: getDateFormat(filter?.datePicker ?? 'full-date') }}
                        setValue={(name, val) => {
                          let value: string | Date = '';
                          if (val) value = formatDateToShortDate(new Date(val));
                          onChange(
                            name, value,
                          );
                          setValue(name,
                            value as unknown as PathValue<FieldValues, FieldPath<FieldValues>>);
                        }}
                        isMonthSelect={filter.datePicker === 'month-year'}
                        clear
                      />
                    </Col>
                  );
                }

                return (
                  <Col md={4} key={filter.name} className="pb-50">
                    <CustomLabel name={t(filter.label)} />
                    <Input
                      onChange={(val) => {
                        debouncedOnChange(filter.name, val.target.value);
                      }}
                      name={filter.name}
                      type="number"
                    />
                  </Col>
                );
              })}

              {filters.filter((
                filter,
              ) => !filter.options && !filter.datePicker).map(
                (filter) => (
                  <Col md={4} key={filter.name} className="pb-50">
                    <CustomLabel name={t(filter.label)} />
                    <Input
                      onChange={(val) => {
                        debouncedOnChange(filter.name, val.target.value);
                      }}
                      name={filter.name}
                      type={filter.type ? filter.type : 'text'}
                    />
                  </Col>
                ),
              )}
            </Row>
            {onFilterClick
            && (
            <Row className="d-flex justify-content-center mt-1 px-1">
              <Col md={4}>
                <Button color="primary" onClick={() => { onFilterClick(); if (setSearched) setSearched(true); }} style={{ width: '100%' }}>{t('Search')}</Button>
              </Col>
            </Row>
            )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <hr className="my-0" />
    </>
  );
};

export default PageFilters;
