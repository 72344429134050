import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import TenantGroupForm from './TenantGroupForm';
import TenantGroup from '../types/TenantGroup';

const TenantGroupColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (tenantObject: TenantGroup) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const tenantGroupPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<TenantGroup>[] = [
    {
      name: t('Name'),
      minWidth: '150px',
      selector: ({ name }) => name,
    },
    {
      name: t('Description'),
      minWidth: '150px',
      center: true,
      selector: ({ description }) => description,
    },
    {
      name: t('Object'),
      minWidth: '150px',
      center: true,
      selector: ({ object }) => object.name,
    },
    {
      name: t('Persons in charge'),
      minWidth: '150px',
      center: true,
      selector: ({ personsInCharge }) => personsInCharge.map((person) => person.name).join(', '),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(tenantGroupPermission, 'AdministrationGroups')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit group')}
                onClick={() => {
                  dispatch(openModal({
                    Component: TenantGroupForm,
                    title: t('Edit'),
                    open: true,
                    componentProps: {
                      tenantGroup: row,
                      edit: true,
                    },
                  }));
                }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(tenantGroupPermission, 'AdministrationGroups')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];
  return columns;
};

export default TenantGroupColumns;
