import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import ChildrenState from '@src/views/children/types/ChildrenState';
import Switcher from '@src/components/switchers/Switcher';

import { inventoryCategoryValidationSchema } from '../validation/index';
import InventoryCategory from '../types/InventoryCategory';
import { createNewInventoryCategory, editInventoryCategory, getAllInventoryCategories } from '../store';

const InventoryCategoryForm = ({ inventory }: { inventory?: InventoryCategory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const childId = useSelector((store: ChildrenState) => store.children.currentChild.id);

  const defaultValues: InventoryCategory = {
    name: '',
    description: '',
    active: '1',
  } as InventoryCategory;

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<InventoryCategory>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      inventoryCategoryValidationSchema(),
    ),
  });

  useEffect(() => {
    if (inventory) {
      reset({
        name: inventory.name,
        description: inventory.description,
        active: String(inventory.active) as '1' | '0',
      });
    }
  }, []);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values: InventoryCategory) => {
    try {
      const newInventoryCategory = {
        ...values,
      };

      if (inventory?.id) {
        await dispatch(editInventoryCategory(
          { id: +inventory.id, data: newInventoryCategory },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Inventory category successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getAllInventoryCategories(childId ? `childId=${childId}` : ''));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createNewInventoryCategory(newInventoryCategory)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Inventory category successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            dispatch(getAllInventoryCategories());
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomLabel name={t('Title')} for="name" required />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input id="name" invalid={!!errors.name} {...field} />
            )}
          />
          {errors?.name?.message
          && <CustomFormFeedback message={errors.name.message} />}
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Description')} for="description" />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Input id="description" type="textarea" invalid={!!errors.description} {...field} />
            )}
          />
          {errors?.description?.message
          && <CustomFormFeedback message={errors.description.message} />}
        </Col>
        <Col md={4}>
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Switcher
                  id="active"
                  checked={field.value === '1'}
                  onChange={(val) => setValue('active', val.target.checked ? '1' : '0', { shouldDirty: true })}
                />
                {t('Active')}
              </div>
            )}
          />
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {inventory?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default InventoryCategoryForm;
