import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

const inventoryCategoryValidationSchema = () => {
  const { t } = useTranslation();

  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    active: yup.mixed().test('required', t('Answer (en) is required'), (val) => val === '1' || val === '0'),
  });
  return validation;
};

const inventoryValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    quantity: yup.number().required(t('Quantity is required')).min(0, t('Quantity must be greater or equal to 0')),
    currentQuantity: yup.number().required(t('Quantity is required')).min(0, t('Quantity must be greater or equal to 0')),
    tenantObjectId: yup.mixed().test('required', t('Tenant object is required'), (val) => val?.value > 0),
  });
  return validation;
};

export {
  inventoryCategoryValidationSchema,
  inventoryValidationSchema,
};
