/* eslint-disable no-return-assign */
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AccordionBody, AccordionHeader, AccordionItem,
  Button,
  Card,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';
import { Edit, Plus, Trash } from 'react-feather';
import { closeModal, openModal } from '@store/modal';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import CustomLabel from '@src/components/forms/CustomLabel';
import Select, { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import RequestStatus from '@src/types/RequestStatus';
import toast from 'react-hot-toast';

import HumanResourcesState from '../../types/HumanResourceState';
import {
  deleteProfessionalDevelopment,
  getProfessionalDevelopmentCategories,
  getProfessionalDevelopments,
  getSchoolYears,
  getTypesOfParticipation,
  setProfessionalDevelopments,
} from '../../store';
import { ProfessionalDevelopment } from '../../types/portfolio/ProfessionalDevelopment';
import ProfessionalDevelopmentForm from './ProfessionalDevelopmentForm';

const ProfessionalDevelopmentTab = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [professionalDevelopmentsByCategory, setProfessionalDevelopmentsByCategory] = useState<
  { [key: string]: ProfessionalDevelopment[] }>({});
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SingleValue<SelectedOptions>>();

  useEffect(() => {
    dispatch(getProfessionalDevelopmentCategories());
    dispatch(getSchoolYears());
    dispatch(getTypesOfParticipation());
  }, []);

  useEffect(() => {
    if (humanResourceStore.professionalDevelopmentCategories.length) {
      const newProfessionalDevelopments: { [key: string]: ProfessionalDevelopment[] } = {};
      humanResourceStore.professionalDevelopments
        .forEach((item) => {
          const oldValues = newProfessionalDevelopments[String(item.categoryId)];
          newProfessionalDevelopments[String(item.categoryId)] = oldValues
            ? [...oldValues, item] : [item];
        });
      setProfessionalDevelopmentsByCategory(newProfessionalDevelopments);
    }
  },
  [
    humanResourceStore.professionalDevelopments,
    humanResourceStore.professionalDevelopmentCategories,
  ]);

  const openProfessionalDevelopmentFormModal = (
    professionalDevelopmentItem?: ProfessionalDevelopment,
  ) => {
    dispatch(openModal({
      Component: ProfessionalDevelopmentForm,
      title: professionalDevelopmentItem ? t('Edit professional development') : t('Add new professional development'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        professionalDevelopmentItem,
        schoolYearIds: selectedSchoolYear?.value,
      },
    }));
  };

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Professional development will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteProfessionalDevelopment(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredProfessionalDevelopments = humanResourceStore
                .professionalDevelopments.filter((item) => item.id !== id);
              dispatch(setProfessionalDevelopments(filteredProfessionalDevelopments));
              toast.success(t('Professional development successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      setSelectedSchoolYear(
        humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1],
      );
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (selectedSchoolYear) {
      dispatch(getProfessionalDevelopments(
        `userId=${humanResourceStore.currentHumanResource.id}&schoolYearIds=${selectedSchoolYear.value}`,
      ));
    }
  }, [selectedSchoolYear]);

  const isSerbian = i18n.language === 'rs';

  return (
    <div>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <CardTitle tag="h4">{t('Professional development')}</CardTitle>
          <div>
            <CustomLabel name={t('Year')} htmlFor="schoolYearId" />
            <Select
              options={humanResourceStore.schoolYears}
              className="react-select"
              classNamePrefix="select"
              placeholder={t('Select')}
              value={selectedSchoolYear}
              onChange={(value) => setSelectedSchoolYear(value)}
            />
          </div>
        </CardHeader>
      </Card>
      <UncontrolledAccordion open="">
        {humanResourceStore.professionalDevelopmentCategories
          .map((category) => (
            <AccordionItem key={category.id} style={{ backgroundColor: 'white' }}>
              <AccordionHeader targetId={String(category.id)}>
                {isSerbian ? category.name : category.nameEng}&nbsp;
                ({professionalDevelopmentsByCategory[String(category.id)]
                  ? professionalDevelopmentsByCategory[String(category.id)].length : 0})
              </AccordionHeader>
              <AccordionBody accordionId={String(category.id)}>
                {professionalDevelopmentsByCategory[String(category.id)]
                  ? professionalDevelopmentsByCategory[String(category.id)].map((item) => (
                    <Row>
                      <Col md={10}>
                        <p>{item.title} - {item.participationType}</p>
                      </Col>
                      {/* <Col xs={4} className="text-center">
                        <Button
                          onClick={() => openProfessionalDevelopmentFormModal(item)}
                          type="button"
                          className="me-1"
                          color="primary"
                        >
                          {t('Edit') }
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          outline
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          {t('Delete')}
                        </Button> */}
                      <Col md={2} style={{ padding: '0' }}>
                        <div style={{ textAlign: 'center' }}>
                          {isManagePermissionActive(humanResourcePermissions, 'portfolioExemplaryActivities')
                            ? (
                              <Button
                                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                                size="sm"
                                color="transparent"
                                className="btn btn-icon"
                                title={t('Edit')}
                                onClick={() => openProfessionalDevelopmentFormModal(item)}
                              >
                                <Edit className="text-center" size={15} />
                              </Button>
                            ) : <></>}
                          {isDeletePermissionActive(humanResourcePermissions, 'portfolioExemplaryActivities')
                            ? (
                              <Button
                                size="sm"
                                color="transparent"
                                className="btn btn-icon"
                                onClick={() => handleDeleteClick(item.id)}
                              >
                                <Trash className="text-center" size={15} />
                              </Button>
                            ) : <></>}
                        </div>
                      </Col>
                    </Row>
                  )) : <p>{t('No records')}</p>}
              </AccordionBody>
            </AccordionItem>
          ))}
      </UncontrolledAccordion>
      {isManagePermissionActive(humanResourcePermissions, 'portfolioProfessionalDevelopment')
        ? (
          <Col xs={12} className="text-center mt-2 pt-50">
            <Button
              color="primary"
              className="text-nowrap"
              onClick={() => openProfessionalDevelopmentFormModal()}
            >
              <div className="d-flex justify-content-center">
                <Plus size={15} />
                &nbsp;
                <span>{t('Add professional development')}</span>
              </div>
            </Button>
          </Col>
        ) : <></>}
    </div>
  );
};

export default ProfessionalDevelopmentTab;
