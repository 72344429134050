import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import Select from 'react-select';
import { getYears, selectedYears } from '@src/views/invoice/store';
import InvoiceState from '@src/views/invoice/types/InvoiceState';

import HumanResourcesState from '../types/HumanResourceState';
import { HumanResourceDaysOffQuota, HumanResourceDaysOffQuotaFormData, HumanResourceDaysOffQuotaSubmitData } from '../types/HumanResourceDaysOffQuota';
import { createDaysOffQuota, editDaysOffQuota, getDaysOffQuota } from '../store';

const HumanResourceDaysOffQuotaForm = (
  { daysOffQuotaItem }: {
    daysOffQuotaItem?: HumanResourceDaysOffQuota },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const invoiceStore = useSelector(
    (state: InvoiceState) => state.invoice,
  );

  const defaultValues: HumanResourceDaysOffQuotaFormData = {
    name: daysOffQuotaItem ? daysOffQuotaItem.name : '',
    numberOfDays: daysOffQuotaItem ? daysOffQuotaItem.numberOfDays : '',
    numberOfDaysUsed: daysOffQuotaItem ? daysOffQuotaItem.numberOfDaysUsed : '',
  } as HumanResourceDaysOffQuotaFormData;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<HumanResourceDaysOffQuotaFormData>({
    reValidateMode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    dispatch(selectedYears(invoiceStore.years.map(
      (item) => ({ label: String(item.value), value: item.id }),
    )));
  }, [invoiceStore.years]);

  useEffect(() => {
    dispatch(getYears());
  }, []);

  useEffect(() => {
    if (invoiceStore.selectedYears.length) {
      if (daysOffQuotaItem) {
        const selectedYear = invoiceStore.selectedYears.find(
          (value) => String(value.value) === String(daysOffQuotaItem.year.id),
        );
        if (selectedYear) setValue('yearId', selectedYear);
      }
    }
  }, [humanResourceStore.schoolYears]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: HumanResourceDaysOffQuotaFormData,
  ) => {
    try {
      const newExemplaryActivity: HumanResourceDaysOffQuotaSubmitData = {
        name: values.name,
        yearId: values.yearId.value,
        numberOfDays: Number(values.numberOfDays),
        numberOfDaysUsed: Number(values.numberOfDaysUsed),
        userId: humanResourceStore.currentHumanResource.id,
      } as HumanResourceDaysOffQuotaSubmitData;

      if (daysOffQuotaItem?.id) {
        await dispatch(editDaysOffQuota(
          { id: +daysOffQuotaItem.id, data: newExemplaryActivity },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Days off quota successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getDaysOffQuota(`userId=${daysOffQuotaItem?.userId}`));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createDaysOffQuota(newExemplaryActivity)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Days off quota successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            const { data } = res.payload as {data: HumanResourceDaysOffQuota};
            dispatch(getDaysOffQuota(`userId=${data?.userId}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomLabel name={t('Year')} htmlFor="yearId" required />
          <Controller
            name="yearId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={invoiceStore.selectedYears}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.yearId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Name')} for="name" />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input id="name" invalid={!!errors.name} {...field} />
            )}
          />
          {errors?.name?.message
          && <CustomFormFeedback message={errors.name.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Total days')} for="numberOfDays" />
          <Controller
            name="numberOfDays"
            control={control}
            render={({ field }) => (
              <Input id="numberOfDays" type="number" invalid={!!errors.numberOfDays} {...field} />
            )}
          />
          {errors?.numberOfDays?.message
          && <CustomFormFeedback message={errors.numberOfDays.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Days used')} for="numberOfDaysUsed" />
          <Controller
            name="numberOfDaysUsed"
            control={control}
            render={({ field }) => (
              <Input id="numberOfDaysUsed" type="number" invalid={!!errors.numberOfDaysUsed} {...field} />
            )}
          />
          {errors?.numberOfDaysUsed?.message
          && <CustomFormFeedback message={errors.numberOfDaysUsed.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {daysOffQuotaItem?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default HumanResourceDaysOffQuotaForm;
