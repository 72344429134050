import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { Button } from 'reactstrap';
import { Plus } from 'react-feather';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import RequestStatus from '@src/types/RequestStatus';
import {
  deleteInventoryItem,
  getAllInventoryItems,
  setAllInventoryItems,
} from '../store';
import InventoryState from '../types/InventoryState';
import InventoryItemColumns from '../components/InventoryItemColumns';
import InventoryItem from '../types/InventoryItem';
import InventoryItemForm from '../components/InventoryItemForm';

const Inventory = () => {
  const dispatch = useDispatch<AppDispatch>();
  const inventoryStore = useSelector((store: InventoryState) => store.inventory);
  const { t } = useTranslation();
  const inventoryPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    dispatch(getAllInventoryItems());
    setDataLoaded(true);
  }, []);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Inventory item will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteInventoryItem(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredEvaluationGroups = inventoryStore
                .allInventoryItems.filter((item) => item.id !== id);
              dispatch(setAllInventoryItems(filteredEvaluationGroups));
              toast.success(t('Inventory item successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const handleManageInventoryCategoryClick = (inventoryCategory?: InventoryItem) => {
    dispatch(openModal({
      Component: InventoryItemForm,
      title: inventoryCategory ? t('Edit inventory item') : t('New inventory item'),
      open: true,
      modalProps: {
        size: 'lg',
      },
      componentProps: {
        inventory: inventoryCategory,
      },
    }));
  };

  return (
    <div>
      <DataTableWrapper
        dataSource={inventoryStore.allInventoryItems}
        title={t('Inventory items')}
        subtitle={t('See, manage and filter inventory items table')}
        columns={InventoryItemColumns(
          { handleEditClick: handleManageInventoryCategoryClick, handleDeleteClick },
        )}
        showSearch
        dataLoaded={dataLoaded}
        functionButton={(
          isManagePermissionActive(inventoryPermission, 'Inventory')
            ? (
              <Button
                color="primary"
                className="text-nowrap mb-1 mt-2"
                outline
                onClick={() => handleManageInventoryCategoryClick()}
              >
                <div className="d-flex justify-content-center">
                  <Plus size={15} />
                  &nbsp;
                  <span>{t('Add new inventory item')}</span>
                </div>
              </Button>
            ) : <></>
        )}
      />
    </div>
  );
};

export default Inventory;
