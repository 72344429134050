import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { handleErrorMessage } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import InventoryCategory from '../types/InventoryCategory';
import InventoryItem from '../types/InventoryItem';
import { InventoryItemSubmitData } from '../types/InventoryItemSubmitData';

export const getAllInventoryCategories = createAsyncThunk('goKinder/getAllInventoryCategories', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/inventory-categories${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createNewInventoryCategory = createAsyncThunk('goKinder/createNewInventoryCategory', async (data: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/inventory-categories`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editInventoryCategory = createAsyncThunk('goKinder/editInventoryCategory', async ({ id, data } :{id: number, data: any}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/inventory-categories/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteInventoryCategory = createAsyncThunk('goKinder/deleteInventoryCategory', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/inventory-categories/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAllInventoryItems = createAsyncThunk('goKinder/getAllInventoryItems', async (query?: string) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/inventories${query ? '?' : ''}${query ?? ''}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const createNewInventoryItem = createAsyncThunk('goKinder/createNewInventoryItem', async (data: InventoryItemSubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/inventories`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editInventoryItem = createAsyncThunk('goKinder/editInventoryItem', async ({ id, data } :{id: number, data: InventoryItemSubmitData}) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/inventories/${id}`, data);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteInventoryItem = createAsyncThunk('goKinder/deleteInventoryItem', async (id: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/inventories/${id}`);

    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const evaluationGroupsSlice = createSlice({
  name: 'evaluations',
  initialState: {
    allInventoryCategories: [] as InventoryCategory[],
    allInventoryItems: [] as InventoryItem[],
    currentInventoryItem: {} as InventoryItem,
    selectedInventoryCategories: [] as SelectedOptions[],
    selectedTenantObjects: [] as SelectedOptions[],
  },
  reducers: {
    setAllInventoryCategories: (state, action) => {
      state.allInventoryCategories = action.payload;
    },
    setAllInventoryItems: (state, action) => {
      state.allInventoryItems = action.payload;
    },
    clearCurrentInventoryItem: (state) => {
      state.currentInventoryItem = {} as InventoryItem;
    },
    setSelectedTenantObjects: (state, action) => {
      state.selectedTenantObjects = action.payload;
    },
    setSelectedInventoryCategories: (state, action) => {
      state.selectedInventoryCategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInventoryCategories.fulfilled, (state, action) => {
      state.allInventoryCategories = action.payload.data;
    });
    builder.addCase(getAllInventoryItems.fulfilled, (state, action) => {
      state.allInventoryItems = action.payload.data;
    });
    // builder.addCase(getEvaluationById.fulfilled, (state, action) => {
    //   state.currentEvaluation = action.payload.data;
    // });
  },
});

export const {
  setAllInventoryCategories,
  setAllInventoryItems,
  clearCurrentInventoryItem,
  setSelectedInventoryCategories,
  setSelectedTenantObjects,
} = evaluationGroupsSlice.actions;

export default evaluationGroupsSlice.reducer;
