import { useEffect, useMemo, useState } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import Select from 'react-select';
import CustomLabel from '@src/components/forms/CustomLabel';
import PackageState from '@src/views/packages/types/PackageState';
import { getAllPackages } from '@src/views/packages/store';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Row,
  Spinner,
} from 'reactstrap';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronLeft } from 'react-feather';
import {
  addNewInvoice,
  editInvoice,
  getCreateInvoiceByChild,
  getInvoiceById,
  getInvoiceTypes,
  getYears,
  selectedInvoiceType,
  selectedYears,
} from '../store';
import InvoiceItems from '../components/InvoiceItems';
import InvoiceState from '../types/InvoiceState';
import CreateInvoicePackage from '../types/CreateInvoicePackage';
import { invoiceValidationSchema } from '../validation/InvoiceValidation';
import Invoice from '../types/Invoice';

type SubmitData = {
  dateIssued: string;
  servicePeriodFrom: string;
  servicePeriodTo: string;
  dueDate: string;
  totalAmount: string;
  note?: string;
  invoiceNumber: number;
  model: number;
  childId: number;
  invoiceType: string,
  yearId: number,
  packages: CreateInvoicePackage[];
}

type ShowData = {
  childName: string;
  childSurname: string;
  childCity: string;
  childStreet: string;
  childPostalCode: number;
  childEMBG: string;
  tenantVat: string;
}

const InvoiceManagement = () => {
  const currentDate = useMemo(() => new Date(new Date().toDateString()), []);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const invoiceStore = useSelector((store: InvoiceState) => store.invoice);
  const { childId, invoiceId } = useParams();
  const packageStore = useSelector((store: PackageState) => store.packages);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [showValues, setShowValues] = useState<ShowData>({
    childName: '',
    childSurname: '',
    childCity: '',
    childStreet: '',
    childPostalCode: 0,
    childEMBG: '',
    tenantVat: '0',
  });

  const modelEnum = useMemo(
    () => ([{ label: '97', value: 97 }, { label: '0', value: 0 }]), [],
  );

  const defaultValues: SubmitData = {
    dateIssued: currentDate.toISOString(),
    servicePeriodFrom: new Date(new Date().setDate(1)).toISOString(),
    servicePeriodTo: new Date(new Date(new Date().setMonth(currentDate.getMonth() + 1))
      .setDate(0)).toISOString(),
    dueDate: currentDate.toISOString(),
    totalAmount: '0',
    note: '',
    referenceNumber: '302',
    invoiceNumber: 0,
    model: 0,
    childId: 0,
    invoiceType: '',
    packages: [],
    yearId: 0,
  } as SubmitData;

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm<SubmitData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      invoiceValidationSchema(),
    ),
  });

  useEffect(() => {
    dispatch(getAllPackages());
    dispatch(getInvoiceTypes());
    dispatch(getYears());
    if (invoiceId) {
      dispatch(getInvoiceById(+invoiceId));
    } else if (childId) {
      dispatch(getCreateInvoiceByChild(+childId));
    }
  }, []);

  const getNumDaysAhead = (date: string | Date, num: number) => {
    const baseDate = new Date(date);
    const newDate = new Date(baseDate);

    newDate.setDate(baseDate.getDate() + num);

    return newDate;
  };

  useEffect(() => {
    if (invoiceStore.createInvoiceData?.tenant && !invoiceId) {
      reset({
        note: invoiceStore.createInvoiceData.tenant.invoiceNote || '',
        dueDate: getNumDaysAhead(currentDate,
          invoiceStore.createInvoiceData.tenant.invoiceDueDateByNumber).toISOString(),
        dateIssued: currentDate.toISOString(),
        model: undefined,
        invoiceNumber: Number(invoiceStore.createInvoiceData.invoiceNumber),
        invoiceType: '',
        totalAmount: '0',
        packages: [],
        servicePeriodFrom: new Date(new Date().setDate(1)).toISOString(),
        childId: childId ? +childId : 0,
        servicePeriodTo: new Date(
          new Date(new Date().setMonth(currentDate.getMonth() + 1)).setDate(0),
        ).toISOString(),
      });
      setShowValues({
        childName: invoiceStore.createInvoiceData.child.name,
        childSurname: invoiceStore.createInvoiceData.child.surname,
        childCity: invoiceStore.createInvoiceData.child.city,
        childStreet: invoiceStore.createInvoiceData.child.street,
        childPostalCode: invoiceStore.createInvoiceData.child.postalCode,
        childEMBG: invoiceStore.createInvoiceData.child.embg,
        tenantVat: invoiceStore.createInvoiceData.tenant.vat,
      });
      setLoading(false);
    }
  }, [invoiceStore.createInvoiceData]);

  useEffect(() => {
    if (invoiceStore.invoice.id && invoiceId) {
      reset({
        note: invoiceStore.invoice.note,
        dueDate: invoiceStore.invoice.dueDate,
        dateIssued: invoiceStore.invoice.dateIssued,
        servicePeriodFrom: invoiceStore.invoice.servicePeriodFrom,
        servicePeriodTo: invoiceStore.invoice.servicePeriodTo,
        model: invoiceStore.invoice.model,
        invoiceNumber: Number(invoiceStore.invoice.invoiceNumber),
        totalAmount: invoiceStore.invoice.totalAmount,
        packages: invoiceStore.invoice.packages,
        yearId: invoiceStore.invoice.yearId,
        invoiceType: invoiceStore.invoice.invoiceType,
      });
      setShowValues({
        childName: invoiceStore.invoice.childName,
        childSurname: invoiceStore.invoice.childSurname,
        childCity: invoiceStore.invoice.childCity,
        childStreet: invoiceStore.invoice.childStreet,
        childPostalCode: invoiceStore.invoice.childPostalCode,
        childEMBG: invoiceStore.invoice.childEmbg,
        tenantVat: invoiceStore.invoice.tenantVat,
      });
      setLoading(false);
    }
  }, [invoiceStore.invoice]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (values: SubmitData) => {
    try {
      const newInvoice = {
        ...invoiceStore.invoice,
        ...values,
        dateIssued: formatDateToShortDate(getValues('dateIssued')),
        dueDate: formatDateToShortDate(getValues('dueDate')),
        servicePeriodFrom: formatDateToShortDate(getValues('servicePeriodFrom')),
        servicePeriodTo: formatDateToShortDate(getValues('servicePeriodTo')),
        totalAmount: String(values.totalAmount),
        id: undefined,
        invoiceNumber: Number(values.invoiceNumber),
      };

      if (invoiceId !== undefined) {
        await dispatch(editInvoice({ id: +invoiceId, data: newInvoice })).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Invoice successfully updated'), { position: 'top-right', duration: 3000 });
            navigate(`/invoice/preview/${invoiceId}`);
          }
        });
      } else {
        await dispatch(addNewInvoice(newInvoice)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Invoice successfully added'), { position: 'top-right', duration: 3000 });
            const { data } = res.payload as { data: Invoice };

            navigate(`/invoice/preview/${data.id}`);
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  useEffect(() => {
    if (!invoiceId) {
      const newDueDate = getNumDaysAhead(getValues('dateIssued'), invoiceStore.createInvoiceData?.tenant?.invoiceDueDateByNumber || 1);
      setValue('dueDate', newDueDate.toISOString());
    }
  }, [watch('dateIssued')]);

  useEffect(() => {
    dispatch(selectedInvoiceType(invoiceStore.invoiceTypes.map(
      (type) => ({ label: t(`${type}`), value: type }),
    )));
  }, [invoiceStore.invoiceTypes]);

  useEffect(() => {
    dispatch(selectedYears(invoiceStore.years.map(
      (year) => ({ label: String(year.value), value: year.id }),
    )));
  }, [invoiceStore.years]);

  if (loading) {
    return (
      <Spinner
        size="lg"
        className="mx-auto my-4 text-center d-block"
        type="border"
        color="primary"
        style={{ width: '4rem', height: '4rem' }}
      />
    );
  }

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Card>
        <CardHeader>
          <div
            className="mt-2"
            style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%',
            }}
          >
            <Button
              color="outline"
              outline
              style={{
                margin: 0, padding: 0, color: 'inherit', fontWeight: 'inherit',
              }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeft size={21} />
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Col className="m-sm-4">
            <h1 className="mb-2">{invoiceId ? t('Edit invoice') : t('Add new invoice')}</h1>
            <Col md={7}>
              <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Full name')}</span>: {showValues.childName} {showValues.childSurname}</p>
              <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('EMBG')}</span>: {showValues.childEMBG}</p>
              <p className="mb-1"><span style={{ fontWeight: 'bold' }}>{t('Address')}</span>: {`${showValues.childStreet ?? ''}${showValues.childStreet ? ',' : ''} ${showValues.childPostalCode ?? ''} ${showValues.childCity ?? ''}`}</p>
            </Col>
          </Col>
          <hr />
          <Row className="m-sm-4">
            <Col md={3} className="mb-1">
              <CustomDatePicker<SubmitData>
                control={control}
                required
                label={t('Date Issued')}
                error={errors?.dateIssued?.message}
                setValue={setValue}
                name="dateIssued"
                defaultValue={getValues('dateIssued')}
                options={{ minDate: undefined }}
              />
              {errors?.dateIssued?.message
              && <CustomFormFeedback message={errors.dateIssued.message} />}
              <Col md={3} />
            </Col>
            <Col md={3} className="mb-1">
              <CustomDatePicker<SubmitData>
                control={control}
                required
                label={t('Due Date')}
                error={errors?.dueDate?.message}
                setValue={setValue}
                name="dueDate"
                options={{ minDate: getValues('dateIssued') }}
                defaultValue={getValues('dueDate')}
              />
              {errors?.dueDate?.message
              && <CustomFormFeedback message={errors.dueDate.message} />}
              <Col md={3} />
            </Col>
            <Col md={3} className="mb-1">
              <CustomDatePicker<SubmitData>
                control={control}
                required
                label={t('Service Period From')}
                error={errors?.servicePeriodFrom?.message}
                setValue={setValue}
                name="servicePeriodFrom"
                options={{ maxDate: new Date(getValues('servicePeriodTo')) }}
                defaultValue={getValues('servicePeriodFrom')}
              />
              {errors?.servicePeriodFrom?.message
              && <CustomFormFeedback message={errors.servicePeriodFrom.message} />}
              <Col md={3} />
            </Col>
            <Col md={3} className="mb-1">
              <CustomDatePicker<SubmitData>
                control={control}
                required={!!1}
                error={errors?.servicePeriodTo?.message}
                setValue={setValue}
                name="servicePeriodTo"
                label={t('Service Period To')}
                options={{ minDate: new Date(new Date(getValues('servicePeriodFrom')).toDateString()) }}
                defaultValue={getValues('servicePeriodTo')}
              />
              {errors?.servicePeriodTo?.message
              && <CustomFormFeedback message={errors.servicePeriodTo.message} />}
              <Col md={3} />
            </Col>
            <Col md={3} className="mb-1">
              <CustomLabel name={t('Invoice number')} required />
              <Controller
                name="invoiceNumber"
                control={control}
                render={({ field }) => (
                  <Input id="invoiceNumber" type="number" invalid={!!errors.invoiceNumber} {...field} />
                )}
              />
              {errors?.invoiceNumber?.message
              && <CustomFormFeedback message={errors.invoiceNumber.message} />}
              <Col md={3} />
            </Col>
            <Col md={3}>
              <CustomLabel name={t('Invoice year')} required />
              <Select
                name="object"
                className="react-select"
                classNamePrefix="select"
                options={invoiceStore.selectedYears}
                value={invoiceStore.selectedYears
                  ?.find((opt) => opt.value === watch('yearId'))}
                onChange={(val) => setValue('yearId', Number(val?.value))}
              />
              {errors?.yearId?.message
              && <CustomFormFeedback message={errors.yearId.message} />}
            </Col>
            <Col md={3}>
              <CustomLabel name={t('Invoice type')} required />
              <Select
                name="object"
                className="react-select"
                classNamePrefix="select"
                options={invoiceStore.selectedInvoiceType}
                value={invoiceStore.selectedInvoiceType
                  .find((opt) => opt.value === watch('invoiceType'))}
                onChange={(val) => setValue('invoiceType', String(val?.value) || '')}
              />
              {errors?.invoiceType?.message
              && <CustomFormFeedback message={errors.invoiceType.message} />}
            </Col>
            <Col md={3}>
              <CustomLabel name={t('Model')} required />
              <Select
                name="object"
                className="react-select"
                classNamePrefix="select"
                options={modelEnum}
                value={modelEnum.find((opt) => opt.value === watch('model'))}
                onChange={(val) => setValue('model', val?.value || 0)}
              />
              {errors?.model?.message
              && <CustomFormFeedback message={errors.model.message} />}
            </Col>
            <Col md={6} className="mb-1">
              <Col className="d-flex align-items-center md-align-items-end">
                <span className="fw-normal">{t('Invoice note')}</span>
              </Col>
              <Col>
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <Input id="note" type="textarea" invalid={!!errors.note} {...field} />
                  )}
                />
                {errors?.note?.message
                && <CustomFormFeedback message={errors.note.message} />}
              </Col>
              <Col md={3} />
            </Col>
          </Row>
          <hr />
          <Row className="m-sm-4">
            <InvoiceItems
              packages={packageStore.allPackages}
              vat={showValues.tenantVat}
              setValue={setValue}
              defaultValue={getValues('packages') || []}
            />
            {errors?.packages?.message
              && <CustomFormFeedback message={errors.packages.message} />}
          </Row>
          <Col className="m-sm-4">
            <p style={{ textAlign: 'end' }}><span style={{ fontWeight: 'bold' }}>{t('Total')}:</span> {watch('totalAmount')}</p>
          </Col>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              type="submit"
              className="me-1"
              color="primary"
              disabled={false}
              style={{ minWidth: '250px' }}
            >
              {invoiceId ? t('Save changes') : t('Add')}
            </Button>
          </div>
        </CardBody>
      </Card>
    </Form>
  );
};

export default InvoiceManagement;
