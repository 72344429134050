import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash } from 'tabler-icons-react';
import { isManagePermissionActive, isDeletePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { formatDateLocalDateString } from '@src/views/payments/components/PaymentColumns';
import { WorkReport } from '../../types/portfolio/WorkReport';

const WorkReportsColumns = ({ handleEditClick, handleDeleteClick }:
  {
    handleDeleteClick: (id: number) => void,
    handleEditClick: (exemplaryActivity: WorkReport) => void,
  }) => {
  const { t } = useTranslation();
  const humanPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<WorkReport>[] = [
    {
      name: t('Realized activity'),
      minWidth: '150px',
      cell: ({ realizedActivity }) => (
        <p className="m-0">{realizedActivity}</p>
      ),
    },
    {
      name: t('Number of points'),
      minWidth: '150px',
      cell: ({ numberOfPoints }) => (
        <p className="m-0">{numberOfPoints}</p>
      ),
    },
    {
      name: t('Type'),
      minWidth: '150px',
      cell: ({ workReportType }) => (
        <p className="m-0">{workReportType}</p>
      ),
    },
    {
      name: t('Date'),
      minWidth: '150px',
      selector: ({ date }) => formatDateLocalDateString(new Date(date)),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(humanPermission, 'portfolioWorkReports')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                onClick={() => handleEditClick(row)}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(humanPermission, 'portfolioWorkReports')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => handleDeleteClick(Number(row.id))}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];

  return columns;
};

export default WorkReportsColumns;
