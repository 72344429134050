import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import { closeModal, openModal } from '@store/modal';
import ConfirmationModalWrapper from '@src/components/wrappers/ConfirmationModalWrapper';
import DataTableWrapper from '@src/components/wrappers/DataTableWrapper';
import RequestStatus from '@src/types/RequestStatus';
import SendEmailModal from '@src/components/message-senders/SendEmailModal';
import SendSmsModal from '@src/components/message-senders/SendSmsModal';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';

import HumanResourcesColumns from '../components/HumanResourceColumns';
import {
  deleteHumanResources,
  getAllHumanResources,
  setAllHumanResources,
} from '../store';
import HumanResourcesState from '../types/HumanResourceState';

const HumanResources = () => {
  const dispatch = useDispatch<AppDispatch>();
  const humanResourcesStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const { t } = useTranslation();
  const [dataLoaded, setDataLoaded] = useState(false);
  const selectedStaff = new Set<string>();
  const staffPermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  useEffect(() => {
    dispatch(getAllHumanResources());
    setDataLoaded(true);
  }, []);

  const handleDeleteClick = (id: number) => {
    dispatch(openModal({
      Component: ConfirmationModalWrapper,
      title: t('Are you sure?'),
      subtitle: t('Staff member will be deleted'),
      open: true,
      componentProps: {
        handleConfirm: async () => {
          try {
            const res = await dispatch(deleteHumanResources(id));

            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              const filteredHumanResources = humanResourcesStore
                .allHumanResources.filter((item) => item.id !== id);
              dispatch(setAllHumanResources(filteredHumanResources));
              toast.success(t('Staff successfully deleted'), { position: 'top-right', duration: 3000 });
            }
          } finally {
            dispatch(closeModal());
          }
        },
      },
    }));
  };

  const sendEmail = () => {
    dispatch(openModal({
      Component: SendEmailModal,
      title: t('Send email'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        toIds: [...selectedStaff],
        route: '/emails/users',
        idName: 'user',
        hasBusiness: true,
      },
    }));
  };

  const sendSms = () => {
    dispatch(openModal({
      Component: SendSmsModal,
      title: t('Send sms'),
      open: true,
      modalProps: { size: 'lg' },
      componentProps: {
        toIds: [...selectedStaff],
        route: '/sms/users',
        idName: 'user',
        hasBusiness: true,
      },
    }));
  };

  const tools = useMemo(() => {
    const arr = [{ title: t('Send email'), onClick: sendEmail }];
    if (isManagePermissionActive(staffPermissions, 'administrationSms')) arr.push({ title: t('Send sms'), onClick: sendSms });

    return arr;
  }, [staffPermissions]);

  return (
    <div>
      <DataTableWrapper
        dataSource={humanResourcesStore.allHumanResources}
        title={t('Staff')}
        subtitle={t('See, manage and filter staff table')}
        columns={HumanResourcesColumns(
          { handleDeleteClick },
        )}
        selectedColumns={selectedStaff}
        tools={tools}
        showSearch={false}
        dataLoaded={dataLoaded}
      />
    </div>
  );
};

export default HumanResources;
