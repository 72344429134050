import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { getAllTenantGroups } from '@src/views/tenantGroups/store';
import { getAllTenantObjects } from '@src/views/tenantObjects/store';
import TenantObjectState from '@src/views/tenantObjects/types/TenantObjectState';
import TenantGroupState from '@src/views/tenantGroups/types/TenantGroupState';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';

import {
  createExemplaryActivity,
  editExemplaryActivity,
  getExemplaryActivities,
  setSelectedTenantGroups,
  setSelectedTenantObjects,
} from '../../store';
import HumanResourcesState from '../../types/HumanResourceState';
import { ExemplaryActivity, ExemplaryActivityFormData } from '../../types/portfolio/ExemplaryActivites';
import { humanResourceExemplaryActivitiesValidationSchema } from '../../validation';

const ExemplaryActivityForm = (
  { exemplaryActivity, schoolYearIds }: {
    exemplaryActivity?: ExemplaryActivity, schoolYearIds: number },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const allTenantObjects = useSelector(
    (state: TenantObjectState) => state.tenantObjects.allTenantObjects,
  );
  const allTenantGroups = useSelector(
    (state: TenantGroupState) => state.tenantGroups.allTenantGroups,
  );
  const defaultValues: ExemplaryActivityFormData = {
    evidence: exemplaryActivity ? exemplaryActivity.evidence : '',
    date: exemplaryActivity ? new Date(exemplaryActivity.date) : new Date(),
  } as ExemplaryActivityFormData;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ExemplaryActivityFormData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      humanResourceExemplaryActivitiesValidationSchema(),
    ),
  });

  useEffect(() => {
    dispatch(getAllTenantGroups());
    dispatch(getAllTenantObjects());
  }, []);

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      if (exemplaryActivity) {
        const selectedSchoolYear = humanResourceStore.schoolYears.find(
          (value) => String(value.value) === String(exemplaryActivity.schoolYearId),
        );
        if (selectedSchoolYear) setValue('schoolYearId', selectedSchoolYear);
      } else {
        setValue('schoolYearId', humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1]);
      }
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      if (exemplaryActivity) {
        const selectedSchoolYear = humanResourceStore.schoolYears.find(
          (value) => String(value.value) === String(exemplaryActivity.schoolYearId),
        );
        if (selectedSchoolYear) setValue('schoolYearId', selectedSchoolYear);
      } else {
        setValue('schoolYearId', humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1]);
      }
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    dispatch(setSelectedTenantObjects(allTenantObjects.map(
      (item) => ({ label: item.name, value: item.id }),
    )));
  }, [allTenantObjects]);

  useEffect(() => {
    dispatch(setSelectedTenantGroups(allTenantGroups.map(
      (item) => ({ label: item.name, value: item.id }),
    )));
  }, [allTenantGroups]);

  useEffect(() => {
    if (humanResourceStore.selectedTenantGroups.length && exemplaryActivity) {
      const selectedTenantGroup = humanResourceStore.selectedTenantGroups.find(
        (value) => String(value.value) === String(exemplaryActivity.tenantGroupId),
      );
      if (selectedTenantGroup) setValue('tenantGroupId', selectedTenantGroup);
    }
  }, [humanResourceStore.selectedTenantGroups]);

  useEffect(() => {
    if (humanResourceStore.selectedTenantObjects.length && exemplaryActivity) {
      const selectedTenantObject = humanResourceStore.selectedTenantObjects.find(
        (value) => String(value.value) === String(exemplaryActivity.tenantObjectId),
      );
      if (selectedTenantObject) setValue('tenantObjectId', selectedTenantObject);
    }
  }, [humanResourceStore.selectedTenantObjects]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: ExemplaryActivityFormData,
  ) => {
    try {
      const newExemplaryActivity: ExemplaryActivity = {
        date: formatDateToShortDate(values.date),
        tenantObjectId: values.tenantGroupId.value,
        tenantGroupId: values.tenantGroupId.value,
        evidence: values.evidence,
        schoolYearId: values.schoolYearId.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as ExemplaryActivity;

      if (exemplaryActivity?.id) {
        await dispatch(editExemplaryActivity(
          { id: +exemplaryActivity.id, data: newExemplaryActivity },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Exemplary activity successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getExemplaryActivities(`userId=${exemplaryActivity?.userId}&schoolYearIds=${schoolYearIds}`));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createExemplaryActivity(newExemplaryActivity)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Exemplary activity successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            const { data } = res.payload as {data: ExemplaryActivity};
            dispatch(getExemplaryActivities(`userId=${data?.userId}&schoolYearIds=${schoolYearIds}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            required
            options={{ minDate: undefined }}
            defaultValue={watch('date')}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Year')} htmlFor="schoolYearId" required />
          <Controller
            name="schoolYearId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.schoolYears}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.schoolYearId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Object')} htmlFor="tenantObjectId" required />
          <Controller
            name="tenantObjectId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.selectedTenantObjects}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.tenantObjectId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Group')} htmlFor="tenantGroupId" required />
          <Controller
            name="tenantGroupId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.selectedTenantGroups}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.tenantGroupId?.message} />
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Evidence')} for="evidence" />
          <Controller
            name="evidence"
            control={control}
            render={({ field }) => (
              <Input id="evidence" type="textarea" invalid={!!errors.evidence} {...field} />
            )}
          />
          {errors?.evidence?.message
          && <CustomFormFeedback message={errors.evidence.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {exemplaryActivity?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ExemplaryActivityForm;
