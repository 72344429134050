// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios from 'axios';
import { getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import { handleErrorMessage } from '@src/utility/Utils';
import SelectedOptions from '@src/types/SelectedOptions';
import SubmitData from '../types/SubmitData';
import TenantGroup from '../types/TenantGroup';
import PersonInCharge from '../types/PersonInCharge';

export const getAllTenantGroups = createAsyncThunk('goKinder/getTenantGroups', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenant-groups`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAllTenantGroupsAdministration = createAsyncThunk('goKinder/getAllTenantGroupsAdministration', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/tenant-groups/administration`, { headers: { Version: 'new' } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const deleteTenantGroup = createAsyncThunk('goKinder/deleteTenantGroup', async (tenantGroupId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/tenant-groups/${tenantGroupId}`, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Group deleted successfully!']);
    return {
      data: { tenantGroupId, ...response.data },
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addNewTenantGroup = createAsyncThunk('goKinder/addNewTenantGroup', async (data: SubmitData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/tenant-groups`, data, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['New group added!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const editTenantGroup = createAsyncThunk('goKinder/editTenantGroup', async (data: SubmitData & { id: number }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/tenant-groups/${data.id}`, data, { headers: { Version: 'new' } });
    successToast(getMessagesForCurrentLanguage()['Group successfully updated!']);
    return {
      data: { ...data, ...response.data },
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAllPersonsInCharge = createAsyncThunk('goKinder/getAllTeachers', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/users/authors`);
    return { data: response.data };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const tenantGroupsSlice = createSlice({
  name: 'tenantGroups',
  initialState: {
    allTenantGroups: [] as TenantGroup[],
    allPersonsInCharge: [] as PersonInCharge[],
    selectedObjects: [] as SelectedOptions[],
    selectedPersonsInCharge: [] as SelectedOptions[],
  },
  reducers: {
    selectedPersonsInCharge: (state, action) => {
      state.selectedPersonsInCharge = action.payload;
    },
    selectedObjects: (state, action) => {
      state.selectedObjects = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTenantGroups.fulfilled, (state, action) => {
      state.allTenantGroups = action.payload.data;
    });
    builder.addCase(getAllTenantGroupsAdministration.fulfilled, (state, action) => {
      state.allTenantGroups = action.payload.data;
    });
    builder.addCase(deleteTenantGroup.fulfilled, (state, action) => {
      state.allTenantGroups = state.allTenantGroups.filter(
        (tenantGroup) => tenantGroup.id !== action.payload.data.tenantGroupId,
      );
    });
    builder.addCase(addNewTenantGroup.fulfilled, (state, action) => {
      state.allTenantGroups = [...state.allTenantGroups, action.payload.data];
    });
    builder.addCase(editTenantGroup.fulfilled, (state, action) => {
      const tenantGroupIndex = state.allTenantGroups.findIndex(
        (tenantGroup) => tenantGroup?.id === action?.payload?.data.id,
      );
      if (tenantGroupIndex >= 0) {
        state.allTenantGroups[tenantGroupIndex] = action?.payload?.data;
      }
    });
    builder.addCase(getAllPersonsInCharge.fulfilled, (state, action) => {
      state.allPersonsInCharge = action.payload.data;
    });
  },
});

export const {
  selectedObjects,
  selectedPersonsInCharge,
} = tenantGroupsSlice.actions;

export default tenantGroupsSlice.reducer;
