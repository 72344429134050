import SelectedOptions from '@src/types/SelectedOptions';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import * as yup from 'yup';
import { REPUBLIKA_SRBIJA_LABEL } from '../components/ChildJispGroupModal';

const childrenValidationSchema = (
  selectedObject: SingleValue<SelectedOptions> | undefined,
  selectedGroup: SingleValue<SelectedOptions> | undefined,
  selectedTypeOfService: SingleValue<SelectedOptions> | undefined,
  selectedDocumentIdentifier: SingleValue<SelectedOptions> | undefined,
  selectedFamilyStatus: SingleValue<SelectedOptions> | undefined,
  selectedPredefinedPackageId: SingleValue<SelectedOptions> | undefined,

) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    name: yup.string().required(t('Name is required')),
    surname: yup.string().required(t('Surname is required')),
    object: yup.mixed().test('required', t('Object is required'), () => !!selectedObject?.value),
    group: yup.mixed().test('required', t('Group is required'), () => !!selectedGroup?.value),
    typeOfService: yup.mixed().test('required', t('Type of service is required'), () => !!selectedTypeOfService?.value),
    familyStatus: yup.mixed().test('required', t('Family status is required'), () => !!selectedFamilyStatus?.value),
    predefinedPackageId: yup.mixed().test('required', t('Package is required'), () => !!selectedPredefinedPackageId?.value),
    embg: yup.string().nullable().matches(/^.{0}$|^\d{13}$/, t('JMBG must be exactly 13 numbers long')),
    documentNumber: yup.mixed().test('required', t('Document number is requierd'), (value) => {
      if (!!selectedDocumentIdentifier?.value && (value === '' || value === undefined || value === null)) {
        return false;
      }

      return true;
    }),
  });
  return validation;
};

const childrenFileValidationSchema = (
  files: File[],
  isEdit: boolean,
) => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    title: yup.string().required(t('Name is required')),
    file: yup.mixed().test('required', t('File is required'), () => isEdit || files.length === 1),
  });
  return validation;
};

const groupEnrolmentValidationSchema = () => {
  const { t } = useTranslation();
  const validation = yup.object().shape({
    job: yup.string().required('Job is required'),
    gender: yup.mixed().test('required', t('Gender is required'), (gender) => gender?.value !== undefined),
    dateOfBirth: yup.string().required(t('Date of birth is required')),
    placeOfBirth: yup.mixed().test('required', t('Place of birth is required'), (placeOfBirth, context) => placeOfBirth?.value !== undefined || context.parent?.countryOfBirth?.label !== REPUBLIKA_SRBIJA_LABEL),
    placeOfBirthAbroad: yup.string().test('required', t('Place of birth abroad is required'), (placeOfBirthAbroad, context) => !!placeOfBirthAbroad?.length || context.parent?.countryOfBirth?.label === REPUBLIKA_SRBIJA_LABEL),
    municipalityOfBirth: yup.mixed().test('required', t('Municipality of birth is required'), (municipalityOfBirth, context) => municipalityOfBirth?.value !== undefined || context.parent?.countryOfBirth?.label !== REPUBLIKA_SRBIJA_LABEL),
    countryOfBirth: yup.mixed().test('required', t('Country of birth is required'), (countryOfBirth) => countryOfBirth?.value !== undefined),
    placeOfResidence: yup.mixed().test('required', t('Place of residence is required'), (placeOfResidence, context) => placeOfResidence?.value !== undefined || context.parent?.countryOfResidence?.label !== REPUBLIKA_SRBIJA_LABEL),
    placeOfResidenceAbroad: yup.string().test('required', t('Place of residence abroad is required'), (placeOfResidenceAbroad, context) => !!placeOfResidenceAbroad?.length || context.parent?.countryOfResidence?.label === REPUBLIKA_SRBIJA_LABEL),
    municipalityOfResidence: yup.mixed().test('required', t('Municipality of residence is required'), (municipalityOfResidence, context) => municipalityOfResidence?.value !== undefined || context.parent?.countryOfResidence?.label !== REPUBLIKA_SRBIJA_LABEL),
    countryOfResidence: yup.mixed().test('required', t('Country of residence is required'), (countryOfResidence) => countryOfResidence?.value !== undefined),
    nationalAffiliation: yup.mixed().test('required', t('Nationality is required'), (nationalAffiliation) => nationalAffiliation?.value !== undefined),
    motherTongue: yup.mixed().test('required', t('Mother tongue is required'), (motherTongue) => motherTongue?.length),
    citizenship: yup.mixed().test('required', t('Citizenship is required'), (citizenship) => citizenship?.length),
    schoolYear: yup.mixed().test('required', t('School year is required'), (schoolYear) => schoolYear?.value !== undefined),
    educationalGroup: yup.mixed().test('required', t('Educational group is required'), (educationalGroup) => educationalGroup?.value !== undefined),
    dateOfEnrollment: yup.string().required('Date of enrolment is required'),
    typeOfEnrollment: yup.mixed().test('required', t('Type of enrollment is required'), (typeOfEnrollment) => typeOfEnrollment?.value !== undefined),
    ppp: yup.mixed().test('required', t('PPP is required'), (ppp) => ppp?.value !== undefined),
    theRightToCareAndAssistance: yup.mixed().test('required', t('Right to care and assistance is required'), (theRightToCareAndAssistance) => theRightToCareAndAssistance?.value !== undefined),
    theRightOfAPersonalCompanion: yup.mixed().test('required', t('Right of a personal companion is required'), (theRightOfAPersonalCompanion) => theRightOfAPersonalCompanion?.value !== undefined),
  });
  return validation;
};

export { childrenValidationSchema, childrenFileValidationSchema, groupEnrolmentValidationSchema };
