import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash, BrandGoogleAnalytics } from 'tabler-icons-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { isViewPermissionActive, isManagePermissionActive, isDeletePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { Poll } from '../types';
import PollsForm from './PollsForm';

const PollsColumns = ({ handleDeleteClick }: { handleDeleteClick: (poll:Poll) => void}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pollPermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<Poll>[] = [
    {
      name: t('Question'),
      minWidth: '150px',
      selector: ({ question }) => question,
    },
    {
      name: t('Total votes'),
      minWidth: '150px',
      center: true,
      selector: ({ totalVotes }) => totalVotes,
    },
    {
      name: t('Created by'),
      minWidth: '150px',
      center: true,
      selector: ({ createdBy }) => `${createdBy.name} ${createdBy.surname}`,
    },
    {
      name: t('Created at'),
      minWidth: '150px',
      center: true,
      selector: ({ createdAt }) => moment(createdAt).format('MMM DD, YYYY'),
      sortable: true,
      sortFunction: (
        (a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          { isViewPermissionActive(pollPermission, 'Polls')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => navigate(`/polls/${row.id}/statistics`)}
              >
                <BrandGoogleAnalytics className="text-center" size={15} />
              </Button>
            ) : <></>}
          { isManagePermissionActive(pollPermission, 'Polls')
            ? (
              <Button
                disabled={row.totalVotes > 0}
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={row.totalVotes > 0 ? t('Edit unavailable - votes already submitted') : t('Edit poll')}
                onClick={() => {
                  dispatch(openModal({
                    title: t('Edit poll'),
                    open: true,
                    Component: PollsForm,
                    componentProps: {
                      poll: row,
                      edit: true,
                    },
                  }));
                }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          { isDeletePermissionActive(pollPermission, 'Polls')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];
  return columns;
};

export default PollsColumns;
