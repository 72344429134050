// ** React Imports
import { Outlet } from 'react-router-dom';

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout';
import { Home } from 'react-feather';

// ** Menu Items Array
import {
  IconBuildingSkyscraper,
  IconFileTypeDocx,
  IconQuestionMark,
  IconPencilQuestion,
  IconUserCog,
  IconUserEdit,
  IconUsersGroup,
  IconTool,
  IconMessage,
  IconUsers,
} from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import {
  BuildingBank,
  Users,
  BrandGoogleAnalytics,
  CameraSelfie,
  Briefcase,
  BuildingCommunity,
  Package,
  User,
  CalendarMinus,
  Report,
  Notes,
  Photo,
  Beach,
  Notification,
  FileInvoice,
  BrandCashapp,
  ReportAnalytics,
  BoxMultiple,
  BuildingWarehouse,
  ClipboardList,
  Category,
} from 'tabler-icons-react';
import NavigationItem from './NavigationItem';

const getInventoryModuleFlag = () => JSON.parse(localStorage.getItem('userData') ?? '{}').inventoryModule;
const getHumanResourcesFlag = () => JSON.parse(localStorage.getItem('userData') ?? '{}').humanResourcesModule;

const getNavigationItems = (): NavigationItem[] => [
  {
    id: 'home',
    title: 'Home',
    icon: <Home />,
    navLink: '/home',
  },
  {
    id: 'children',
    title: 'Children',
    icon: <User />,
    navLink: '/children',
    permissionName: 'Children',
  },
  {
    id: 'parents',
    title: 'Parents',
    icon: <Users />,
    navLink: '/parents',
    permissionName: 'Parents',
  },
  {
    id: 'gallery',
    title: 'Gallery',
    icon: <Photo />,
    navLink: '/gallery',
    permissionName: 'Gallery',
  },
  {
    id: 'posts',
    title: 'Posts',
    icon: <CameraSelfie />,
    navLink: '/posts',
    permissionName: 'Posts',
  },
  {
    id: 'announcements',
    title: 'Announcements',
    icon: <Notification />,
    navLink: '/announcements',
    permissionName: 'Announcements',
  },
  {
    id: 'polls',
    title: 'Polls',
    icon: <BrandGoogleAnalytics />,
    navLink: '/polls',
    permissionName: 'Polls',
  },
  {
    id: 'mass-invoicing',
    title: 'Mass invoicing',
    icon: <BoxMultiple />,
    navLink: '/invoice/bulk/add',
    permissionName: 'Invoices',
  },
  {
    id: 'human-resources',
    title: 'Staff',
    icon: <IconUsers />,
    navLink: '/staff',
    permissionName: getHumanResourcesFlag() ? 'humanResources' : 'locked',
  },
  {
    id: 'days-off',
    title: 'Days off',
    icon: <Beach />,
    navLink: 'days-off',
    permissionName: getHumanResourcesFlag() ? 'daysOffRequests' : 'locked',
  },
  {
    id: 'report',
    title: 'Report',
    icon: <Report />,
    navLink: '/report',
    children: [
      {
        id: 'payments',
        title: 'Payments',
        icon: <BrandCashapp />,
        navLink: '/payments',
        permissionName: 'Payments',
      },
      {
        id: 'invoices',
        title: 'Invoices',
        icon: <FileInvoice />,
        navLink: 'invoices',
        permissionName: 'Invoices',
      },
      {
        id: 'notes',
        title: 'Notes',
        icon: <Notes />,
        navLink: '/notes',
        permissionName: 'Notes',
      },
      {
        id: 'absences',
        title: 'Absences',
        icon: <CalendarMinus />,
        navLink: '/absences',
        permissionName: 'Absences',
      },
      {
        id: 'sms',
        title: 'Smses',
        icon: <IconMessage />,
        navLink: '/sms',
        permissionName: 'administrationSms',
      },
    ],
  },
  {
    id: 'administration',
    title: 'Administration',
    icon: <Briefcase />,
    navLink: '/administration',
    children: [
      {
        id: 'general',
        title: 'General',
        icon: <BuildingCommunity />,
        navLink: '/general',
        permissionName: 'General',
      },
      {
        id: 'objects',
        title: 'Objects',
        icon: <BuildingCommunity />,
        navLink: '/objects',
        permissionName: 'AdministrationObjects',
      },
      {
        id: 'groups',
        title: 'Groups',
        icon: <IconUsersGroup />,
        navLink: '/groups',
        permissionName: 'AdministrationGroups',
      },
      {
        id: 'packages',
        title: 'Packages',
        icon: <Package />,
        navLink: '/packages',
        permissionName: 'AdministrationPackages',
      },
      {
        id: 'nonWorkingDays',
        title: 'Non-working days',
        icon: <Beach />,
        navLink: '/non-working-days',
        permissionName: 'AdministrationNonWorkingDays',
      },
      {
        id: 'contracts',
        title: 'Contracts',
        icon: <IconFileTypeDocx />,
        navLink: '/contracts',
        permissionName: 'AdministrationContracts',
      },
      {
        id: 'evaluations',
        title: 'Evaluations',
        icon: <ReportAnalytics />,
        navLink: '/evaluation-groups',
        permissionName: 'AdministrationEvaluations',
      },
      {
        id: 'roles',
        title: 'Roles',
        icon: <IconUserCog />,
        navLink: '/roles',
        permissionName: 'AdministrationRoles',
      },
      {
        id: 'users',
        title: 'Users',
        icon: <IconUserEdit />,
        navLink: '/users',
        permissionName: 'AdministrationUsers',
      },
    ],
  },
  {
    id: 'maintenance',
    title: 'Maintenance',
    icon: <IconTool />,
    navLink: '/administration',
    children: [
      {
        id: 'faq-categories',
        title: 'FAQ categories',
        icon: <IconPencilQuestion />,
        navLink: '/faq-categories',
        permissionName: 'AdministrationFaqCategories',
      },
      {
        id: 'city-forms',
        title: 'City forms',
        icon: <IconBuildingSkyscraper />,
        navLink: '/city-forms',
        permissionName: 'AdministrationCityForms',
      },
    ],
  },
  {
    id: 'inventory',
    title: 'Inventory',
    icon: <BuildingWarehouse />,
    navLink: '/inventory',
    children: [
      {
        id: 'inventory-items',
        title: 'Items',
        icon: <ClipboardList />,
        navLink: '/inventory-items',
        permissionName: getInventoryModuleFlag() ? 'Inventory' : 'locked',
      },
      {
        id: 'inventory-categories',
        title: 'Categories',
        icon: <Category />,
        navLink: '/inventory-categories',
        permissionName: getInventoryModuleFlag() ? 'AdministrationInventoryCategories' : 'locked',
      },
    ],
  },
  {
    id: 'faq',
    title: 'FAQ',
    icon: <IconQuestionMark />,
    navLink: '/faq',
  },
  {
    id: 'tenants',
    title: 'Tenants',
    icon: <BuildingBank />,
    navLink: '/tenants',
    permissionName: 'Tenants',
  },
];

const VerticalLayout = () => {
  const [routes, setRoutes] = useState<NavigationItem[]>([]);

  useEffect(() => {
    setRoutes(getNavigationItems());
  }, []);

  return (
    <Layout menuData={routes}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
