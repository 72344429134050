import DataTable from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import NoTableData from '@src/components/wrappers/NoTableData';

import { HumanResourceDaysOffQuota } from '../types/HumanResourceDaysOffQuota';

const HumanResourceDaysOffQuotaModal = ({ quotas } : { quotas: HumanResourceDaysOffQuota[] }) => {
  const { t } = useTranslation();

  return (
    <div>
      <DataTable
        noHeader
        className="react-dataTable"
        columns={[
          {
            name: t('Name'),
            minWidth: '60px',
            cell: (row) => <p>{row.name}</p>,
          },
          {
            name: t('Total days'),
            minWidth: '60px',
            cell: (row) => <p>{row.numberOfDays}</p>,
          },
          {
            name: t('Days used'),
            minWidth: '60px',
            cell: (row) => <p>{row.numberOfDaysUsed}</p>,
          },
          {
            name: t('Year'),
            minWidth: '60px',
            cell: (row) => <p>{row.year.value}</p>,
          },
        ]}
        noDataComponent={<NoTableData searched />}
        progressComponent={<div />}
        data={quotas}
      />

    </div>
  );
};

export default HumanResourceDaysOffQuotaModal;
