/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useRef } from 'react';
import { Input } from 'reactstrap';
import '../../assets/scss/input-file-translation.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  accept: string,
  selectedFile: File | null,
  onFileChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
  name?: string,
  id?: string,
}

const FileInputWithTranslation = ({
  selectedFile, onFileChangeHandler, accept, name, id,
} : Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  return (
    <div className="file-input-translation-container">
      <div role="button" onClick={() => { if (fileInputRef.current) fileInputRef.current.click(); }} style={{ width: '100%', display: 'flex' }}>
        <label htmlFor="file" className="file-input-translation-label">
          {t('Choose File')}
        </label>
        <label htmlFor="file" className="file-input-translation-file" id="selected-datoteka">
          {selectedFile ? selectedFile.name : t('No file chosen')}
        </label>
      </div>

      {/* Hidden File Input */}
      <Input
        type="file"
        name={name ?? 'file'}
        id={id ?? 'file'}
        innerRef={fileInputRef}
        accept={accept}
        style={{ display: 'none' }}
        onChange={onFileChangeHandler}
      />
    </div>
  );
};

export default FileInputWithTranslation;
