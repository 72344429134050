import { TableColumn } from 'react-data-table-component';
import { Button } from 'reactstrap';
import { Edit, Trash, X } from 'tabler-icons-react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@store/store';
import { openModal } from '@store/modal';
import { isDeletePermissionActive, isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import { useTranslation } from 'react-i18next';
import { Check } from 'react-feather';
import Package from '../types/Package';
import PackagesForm from './PackagesForm';

const PackagesColumns = ({ handleDeleteClick }:
  { handleDeleteClick: (pack: Package) => void }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const packagePermission = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const columns: TableColumn<Package>[] = [
    {
      name: t('Name'),
      minWidth: '150px',
      selector: ({ name }) => name,
    },
    {
      name: t('Price'),
      minWidth: '150px',
      center: true,
      selector: ({ price, currency }) => `${price} ${currency.cc}`,
      sortable: true,
      sortFunction: ((a, b) => (a.price > b.price ? 1 : -1)),
    },
    {
      name: t('City report'),
      minWidth: '150px',
      center: true,
      selector: ({ cityReport }) => cityReport,
      cell: (row) => (
        <div>
          {row.cityReport ? <Check /> : <X />}
        </div>
      ),
    },
    {
      name: t('Actions'),
      width: '135px',
      center: true,
      cell: (row) => (
        <div>
          {isManagePermissionActive(packagePermission, 'AdministrationPackages')
            ? (
              <Button
                style={{ borderColor: 'transparent', pointerEvents: 'auto' }}
                size="sm"
                color="transparent"
                className="btn btn-icon"
                title={t('Edit')}
                onClick={() => {
                  dispatch(openModal({
                    Component: PackagesForm,
                    title: t('Edit package'),
                    open: true,
                    componentProps: {
                      pack: row,
                      edit: true,
                    },
                  }));
                }}
              >
                <Edit className="text-center" size={15} />
              </Button>
            ) : <></>}
          {isDeletePermissionActive(packagePermission, 'AdministrationPackages')
            ? (
              <Button
                size="sm"
                color="transparent"
                className="btn btn-icon"
                onClick={() => { handleDeleteClick(row); }}
              >
                <Trash className="text-center" size={15} />
              </Button>
            ) : <></>}
        </div>
      ),
    },
  ];
  return columns;
};

export default PackagesColumns;
