import { useEffect } from 'react';
import Flatpickr from 'react-flatpickr';
import '@styles/react/libs/flatpickr/flatpickr.scss';
import {
  Control,
  Controller,
  FieldValues,
  FieldPath,
  UseFormSetValue,
  PathValue,
} from 'react-hook-form';
import { Button } from 'reactstrap';
import moment from 'moment';
import { BaseOptions } from 'flatpickr/dist/types/options';
import CustomLabel from '@src/components/forms/CustomLabel';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import { Serbian } from 'flatpickr/dist/l10n/sr.js';
import { useTranslation } from 'react-i18next';
import { IconX } from '@tabler/icons-react';

interface DatePickerProps<T extends FieldValues> {
  control: Control<T>;
  error?: string;
  setValue: UseFormSetValue<T>;
  label: string;
  name: FieldPath<T>;
  defaultValue?: PathValue<T, FieldPath<T>>;
  options?: Partial<BaseOptions>;
  required?: boolean;
  dateFormat?: string;
  isMonthSelect?: boolean;
  disabled?: boolean;
  clear?: boolean,
  useDefaultValue?: boolean,
}

export const todayDate = new Date().toString();

const CustomDatePicker = <T extends FieldValues>({
  control,
  error,
  setValue,
  label,
  name,
  defaultValue = todayDate as unknown as PathValue<T, FieldPath<T>>,
  useDefaultValue = true,
  options = {
    allowInput: false,
    dateFormat: 'd. m. Y.',
    altInput: true,
    altFormat: 'F j, Y',
    minDate: 'today',
  },
  required = false,
  dateFormat = 'd. m. Y.',
  isMonthSelect = false,
  disabled = false,
  clear = false,
}: DatePickerProps<T>) => {
  const { i18n } = useTranslation();
  const handleChange = (date: Date[]) => {
    const newValue = date[0];
    setValue(
      name,
      newValue as PathValue<T, FieldPath<T>>,
      {
        shouldValidate: true,
        shouldDirty: true,
      },
    );
  };

  const handleClear = () => {
    setValue(name, '' as PathValue<T, FieldPath<T>>);
  };

  const handleKeyPress = (e: any) => {
    const { key } = e.nativeEvent;
    if (key === 'Backspace' || key === 'Delete') {
      handleClear();
    }
  };

  useEffect(() => {
    if (useDefaultValue) setValue(name, defaultValue);
  }, []);

  const locale = i18n.language === 'rs' ? Serbian : null;

  return (
    <>
      <CustomLabel name={label} required={required} />
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div style={{ position: 'relative' }}>
            <Flatpickr
              readOnly={false}
              onKeyDown={(e) => handleKeyPress(e)}
              disabled={disabled}
              className="form-control full-opacity"
              options={{
                ...options,
                dateFormat: options.dateFormat ? options.dateFormat : dateFormat,
                plugins: isMonthSelect ? [monthSelect({ shorthand: true })] : [],
                locale: {
                  ...locale,
                  firstDayOfWeek: 1,
                },
              }}
              value={field.value ? moment(field.value).toDate() : undefined}
              onChange={handleChange}
            />
            {(clear && field.value) ? (
              <Button
                style={{
                  padding: 0, position: 'absolute', right: '8px', top: '10px', border: '0px', boxShadow: 'unset',
                }}
                className="flatpickr-backgroundColor-white"
                onClick={() => handleClear()}
              >
                <IconX size={18} color="#6E6B7B" stroke={4} />
              </Button>
            ) : <></>}
            {error && <CustomFormFeedback message={error} />}
          </div>
        )}
      />
    </>
  );
};

export default CustomDatePicker;
