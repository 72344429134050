import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import Select, { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { handleApiErrorResponse } from '@src/utility/Utils';
import toast from 'react-hot-toast';
import RequestStatus from '@src/types/RequestStatus';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';
import {
  EditorState, convertToRaw, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useSkin } from '@src/utility/hooks/useSkin';

import HumanResourcesState from '../../types/HumanResourceState';
import {
  createWorkEvaluations,
  editWorkEvaluations,
  getWorkEvaluations,
  getSchoolYears,
} from '../../store';
import { WorkEvaluations, WorkEvaluationsFormData } from '../../types/portfolio/WorkEvaluations';

const WorkEvaluationsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SingleValue<SelectedOptions>>();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const { skin } = useSkin();

  const handleGetHtml = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    return htmlContent.toString();
  };

  useEffect(() => {
    dispatch(getSchoolYears());
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkEvaluationsFormData>({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      setSelectedSchoolYear(
        humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1],
      );
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (selectedSchoolYear) {
      dispatch(getWorkEvaluations(
        `userId=${humanResourceStore.currentHumanResource.id}&schoolYearId=${selectedSchoolYear.value}`,
      ));
    }
  }, [selectedSchoolYear]);

  useEffect(() => {
    if (humanResourceStore.currentWorkEvaluations?.id) {
      const contentState = htmlToDraft(
        humanResourceStore.currentWorkEvaluations.evaluation,
      );
      const newEditorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentState.contentBlocks),
      );

      setEditorState(newEditorState);
    } else {
      setEditorState(() => EditorState.createEmpty());
    }
  }, [humanResourceStore.currentWorkEvaluations?.id]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async () => {
    try {
      const newWorkEvaluations: WorkEvaluations = {
        evaluation: handleGetHtml(),
        schoolYearId: selectedSchoolYear?.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as WorkEvaluations;

      if (humanResourceStore.currentWorkEvaluations?.id) {
        await dispatch(editWorkEvaluations(
          {
            id: +humanResourceStore.currentWorkEvaluations.id,
            data: newWorkEvaluations,
          },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('External evaluations successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getWorkEvaluations(`userId=${humanResourceStore.currentWorkEvaluations?.userId}&&schoolYearId=${selectedSchoolYear?.value}`));
          }
        });
      } else {
        await dispatch(createWorkEvaluations(newWorkEvaluations))
          .then((res) => {
            if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
              toast.success(t('External evaluations successfully added'), { position: 'top-right', duration: 3000 });
              const { data } = res.payload as { data: WorkEvaluations };
              dispatch(getWorkEvaluations(`userId=${data?.userId}&schoolYearId=${selectedSchoolYear?.value}`));
            }
          });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  const canEdit = isManagePermissionActive(humanResourcePermissions, 'portfolioWorkEvaluations');

  return (
    <div>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <CardTitle tag="h4">{t('External evaluations of work impact')}</CardTitle>
          <div>
            <CustomLabel name={t('Year')} htmlFor="schoolYearId" />
            <Select
              options={humanResourceStore.schoolYears}
              className="react-select"
              classNamePrefix="select"
              placeholder={t('Select')}
              value={selectedSchoolYear}
              onChange={(value) => setSelectedSchoolYear(value)}
            />
          </div>
        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
            <Row>
              <Col md={12}>
                <CustomLabel name={t('Evaluation')} for="evaluation" required />
                <Controller
                  name="evaluation"
                  control={control}
                  render={() => (
                    <Editor
                      wrapperStyle={{ borderRadius: '10px', border: '1px solid #3b4253' }}
                      editorClassName="px-1"
                      editorStyle={{
                        minHeight: '350px',
                      }}
                      toolbarStyle={{ color: '#000', borderRadius: '10px' }}
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      toolbarClassName={skin === 'dark' ? 'toolbar-dark' : ''}
                      readOnly={!canEdit}
                    />
                  )}
                />
                {errors?.evaluation?.message
                && <CustomFormFeedback message={errors.evaluation.message} />}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <Button
                  type="submit"
                  className="me-1"
                  color="primary"
                >
                  {t('Save changes')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default WorkEvaluationsTab;
