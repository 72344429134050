import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';

import {
  createProfessionalDevelopmentEvaluations,
  editProfessionalDevelopmentEvaluations,
  getProfessionalDevelopmentEvaluations,
} from '../../store';
import HumanResourcesState from '../../types/HumanResourceState';
import { ProfessionalDevelopmentEvaluations, ProfessionalDevelopmentEvaluationsFormData } from '../../types/portfolio/ProfessionalDevelopmentEvaluation';
import { humanResourceProfessionalDevelopmentEvaluationValidationSchema } from '../../validation';

const ProfessionalDevelopmentEvaluationsForm = (
  { developmentEvaluationItem, schoolYearIds }: {
    developmentEvaluationItem?: ProfessionalDevelopmentEvaluations, schoolYearIds: number },
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const defaultValues: ProfessionalDevelopmentEvaluationsFormData = {
    date: developmentEvaluationItem ? new Date(developmentEvaluationItem.date) : new Date(),
    activityDescription: developmentEvaluationItem ? developmentEvaluationItem.activityDescription : '',
    role: developmentEvaluationItem ? developmentEvaluationItem.role : '',
    numberOfPoints: developmentEvaluationItem ? developmentEvaluationItem.numberOfPoints : '',
    evidence: developmentEvaluationItem ? developmentEvaluationItem.evidence : '',
  } as ProfessionalDevelopmentEvaluationsFormData;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProfessionalDevelopmentEvaluationsFormData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      humanResourceProfessionalDevelopmentEvaluationValidationSchema(),
    ),
  });

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      if (developmentEvaluationItem) {
        const selectedSchoolYear = humanResourceStore.schoolYears.find(
          (value) => String(value.value) === String(developmentEvaluationItem.schoolYearId),
        );
        if (selectedSchoolYear) setValue('schoolYearId', selectedSchoolYear);
      } else {
        setValue('schoolYearId', humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1]);
      }
    }
  }, [humanResourceStore.schoolYears]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: ProfessionalDevelopmentEvaluationsFormData,
  ) => {
    try {
      const newEvaluation: ProfessionalDevelopmentEvaluations = {
        date: formatDateToShortDate(values.date),
        activityDescription: values.activityDescription,
        role: values.role,
        numberOfPoints: values.numberOfPoints,
        evidence: values.evidence,
        schoolYearId: values.schoolYearId.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as ProfessionalDevelopmentEvaluations;

      if (developmentEvaluationItem?.id) {
        await dispatch(editProfessionalDevelopmentEvaluations(
          { id: +developmentEvaluationItem.id, data: newEvaluation },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Professional development evaluation successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getProfessionalDevelopmentEvaluations(`userId=${developmentEvaluationItem?.userId}&schoolYearIds=${schoolYearIds}`));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createProfessionalDevelopmentEvaluations(newEvaluation)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Professional development evaluation successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            const { data } = res.payload as {data: ProfessionalDevelopmentEvaluations};
            dispatch(getProfessionalDevelopmentEvaluations(`userId=${data?.userId}&schoolYearIds=${schoolYearIds}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            required
            options={{ minDate: undefined }}
            defaultValue={watch('date')}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Year')} htmlFor="schoolYearId" required />
          <Controller
            name="schoolYearId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.schoolYears}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.schoolYearId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Role')} for="role" required />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Input id="role" invalid={!!errors.role} {...field} />
            )}
          />
          {errors?.role?.message
          && <CustomFormFeedback message={errors.role.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Number of points')} for="numberOfPoints" required />
          <Controller
            name="numberOfPoints"
            control={control}
            render={({ field }) => (
              <Input id="numberOfPoints" invalid={!!errors.numberOfPoints} {...field} />
            )}
          />
          {errors?.numberOfPoints?.message
          && <CustomFormFeedback message={errors.numberOfPoints.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Evidence')} for="evidence" required />
          <Controller
            name="evidence"
            control={control}
            render={({ field }) => (
              <Input id="evidence" type="textarea" invalid={!!errors.evidence} {...field} />
            )}
          />
          {errors?.evidence?.message
          && <CustomFormFeedback message={errors.evidence.message} />}
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Activity description')} for="activityDescription" required />
          <Controller
            name="activityDescription"
            control={control}
            render={({ field }) => (
              <Input id="activityDescription" type="textarea" invalid={!!errors.activityDescription} {...field} />
            )}
          />
          {errors?.activityDescription?.message
          && <CustomFormFeedback message={errors.activityDescription.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {developmentEvaluationItem?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfessionalDevelopmentEvaluationsForm;
