// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** Axios Imports
import axios, { AxiosError } from 'axios';
import { errorToast, getMessagesForCurrentLanguage, successToast } from '@src/components/wrappers/ToastMessages';
import toast from 'react-hot-toast';
import SelectedOptions from '@src/types/SelectedOptions';
import { getHeaderVersion, handleErrorMessage } from '@src/utility/Utils';
import Post from '../types/Post';
import AddPostReq from '../types/AddPostReq';
import Child from '../types/Child';
import SelectedChildOptions from '../types/SelectedChildOptions';
import PostTag from '../types/PostTag';

export const getAllPosts = createAsyncThunk('goKinder/getPosts', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/posts`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getAllTags = createAsyncThunk('goKinder/getTags', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/posts/tags`);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const getChildrenByTenant = createAsyncThunk('goKinder/getChildrenByTenant', async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_URL_ENV}/childs/dashboard?childStatus=Aktivan`, { headers: { Version: getHeaderVersion() } });
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
});

export const addPost = async (req: AddPostReq) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('text', req.text);
    bodyFormData.append('tagBy', req.tagBy ?? '');
    bodyFormData.append('tagIds', req.tagIds);
    req?.files?.forEach((file) => {
      bodyFormData.append('files', file, file.name);
    });
    const response = await axios.post(`${process.env.REACT_APP_URL_ENV}/posts`, bodyFormData);
    successToast(getMessagesForCurrentLanguage()['Post successfully added!']);
    return {
      data: response.data,
    };
  } catch (err) {
    if (err instanceof AxiosError) {
      errorToast(err?.response?.data?.message);
    }
    return Promise.reject(err);
  }
};

export const editPostInfo = async (post: AddPostReq, postId: number) => {
  try {
    const id = postId;
    const bodyFormData = new FormData();
    bodyFormData.append('text', post.text);
    bodyFormData.append('tagBy', post.tagBy ?? '');
    bodyFormData.append('tagIds', post.tagIds);
    bodyFormData.append('deletedFiles', post.deletedFiles ?? '');
    post?.files?.forEach((file) => {
      bodyFormData.append('files', file, file.name);
    });
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/posts/${id}`, bodyFormData);
    toast.success(getMessagesForCurrentLanguage()['Post successfully updated!'], { position: 'top-right', duration: 3000 });
    return {
      data: response.data,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      errorToast(error?.response?.data?.message);
    }
    return Promise.reject(error);
  }
};

export const deletePost = async (postId: number) => {
  try {
    const response = await axios.delete(`${process.env.REACT_APP_URL_ENV}/posts/${postId}`);
    successToast(getMessagesForCurrentLanguage()['Post deleted successfully!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
};
export const deletePostFile = async (postId: number, fileId: number) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_URL_ENV}/posts/${postId}/delete_file`, { fileId });
    successToast(getMessagesForCurrentLanguage()['File deleted successfully!']);
    return {
      data: response.data,
    };
  } catch (err: any) {
    handleErrorMessage(err);
    return Promise.reject(new Error(err));
  }
};
export const postsSlice = createSlice({
  name: 'posts',
  initialState: {
    allPosts: [] as Post[],
    selectedChildren: [] as SelectedChildOptions[],
    childrenByTenant: [] as Child[],
    postTags: [] as PostTag[],
    selectedTags: [] as SelectedOptions[],
  },
  reducers: {
    selectedChildren: (state, action) => {
      state.selectedChildren = action.payload;
    },
    selectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPosts.fulfilled, (state, action) => {
      state.allPosts = action.payload.data;
    });
    builder.addCase(getChildrenByTenant.fulfilled, (state, action) => {
      state.childrenByTenant = action.payload.data;
    });
    builder.addCase(getAllTags.fulfilled, (state, action) => {
      state.postTags = action.payload.data;
    });
  },
});

export const {
  selectedChildren,
  selectedTags,
} = postsSlice.actions;

export default postsSlice.reducer;
