import { useEffect } from 'react';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { AppDispatch } from '@store/store';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import { formatDateToShortDate, handleApiErrorResponse } from '@src/utility/Utils';
import RequestStatus from '@src/types/RequestStatus';
import { closeModal } from '@store/modal';
import CustomLabel from '@src/components/forms/CustomLabel';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import CustomDatePicker from '@src/components/wrappers/CustomDatePicker';

import {
  createNewProfessionalDevelopment,
  editProfessionalDevelopment,
  getProfessionalDevelopments,
  setSelectedProfessionalDevelopmentCategories,
} from '../../store';
import { ProfessionalDevelopment, ProfessionalDevelopmentFormData, ProfessionalDevelopmentSubmitData } from '../../types/portfolio/ProfessionalDevelopment';
import HumanResourcesState from '../../types/HumanResourceState';
import { humanResourceProfessionalDevelopmentValidationSchema } from '../../validation';

const ProfessionalDevelopmentForm = (
  { professionalDevelopmentItem, schoolYearIds }: {
     professionalDevelopmentItem?: ProfessionalDevelopment, schoolYearIds: number },
) => {
  const { t, i18n } = useTranslation();
  const isSerbian = i18n.language === 'rs';
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const defaultValues: ProfessionalDevelopmentFormData = {
    title: professionalDevelopmentItem ? professionalDevelopmentItem.title : '',
    date: professionalDevelopmentItem ? new Date(professionalDevelopmentItem.date) : new Date(),
  } as ProfessionalDevelopmentFormData;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProfessionalDevelopmentFormData>({
    reValidateMode: 'onChange',
    defaultValues,
    resolver: yupResolver(
      humanResourceProfessionalDevelopmentValidationSchema(),
    ),
  });

  useEffect(() => {
    if (humanResourceStore.professionalDevelopmentCategories.length) {
      dispatch(setSelectedProfessionalDevelopmentCategories(
        humanResourceStore.professionalDevelopmentCategories.map(
          (object) => ({ label: t(object.name), value: object.id }),
        ),
      ));
      if (professionalDevelopmentItem) {
        const selectedCategory = humanResourceStore.professionalDevelopmentCategories.find(
          (value) => String(value.id) === String(professionalDevelopmentItem.categoryId),
        );
        if (selectedCategory) setValue('categoryId', { label: isSerbian ? selectedCategory.name : selectedCategory.name, value: selectedCategory.id });
      }
    }
  }, [humanResourceStore.professionalDevelopmentCategories]);

  useEffect(() => {
    if (humanResourceStore.schoolYears.length && professionalDevelopmentItem) {
      const selectedSchoolYear = humanResourceStore.schoolYears.find(
        (value) => String(value.value) === String(professionalDevelopmentItem.schoolYearId),
      );
      if (selectedSchoolYear) setValue('schoolYearId', selectedSchoolYear);
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (humanResourceStore.typesOfParticipation.length && professionalDevelopmentItem) {
      const selectedParticipationType = humanResourceStore.typesOfParticipation.find(
        (value) => String(value.value) === String(professionalDevelopmentItem.participationType),
      );
      if (selectedParticipationType) setValue('participationType', selectedParticipationType);
    }
  }, [humanResourceStore.typesOfParticipation]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: ProfessionalDevelopmentFormData,
  ) => {
    try {
      const newInventoryItem: ProfessionalDevelopmentSubmitData = {
        title: values.title,
        date: formatDateToShortDate(values.date),
        participationType: values.participationType.value,
        schoolYearId: values.schoolYearId.value,
        categoryId: values.categoryId.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as ProfessionalDevelopmentSubmitData;

      if (professionalDevelopmentItem?.id) {
        await dispatch(editProfessionalDevelopment(
          { id: +professionalDevelopmentItem.id, data: newInventoryItem },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Professional development successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getProfessionalDevelopments(`userId=${professionalDevelopmentItem?.userId}&schoolYearIds=${schoolYearIds}`));
            dispatch(closeModal());
          }
        });
      } else {
        await dispatch(createNewProfessionalDevelopment(newInventoryItem)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Professional development successfully added'), { position: 'top-right', duration: 3000 });
            dispatch(closeModal());
            const { data } = res.payload as {data: ProfessionalDevelopment};
            dispatch(getProfessionalDevelopments(`userId=${data?.userId}&schoolYearIds=${schoolYearIds}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  return (
    <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
      <Row>
        <Col md={6}>
          <CustomDatePicker
            label={t('Date')}
            name="date"
            control={control}
            setValue={setValue}
            error={errors?.date?.message}
            required
            options={{ minDate: undefined }}
            defaultValue={watch('date')}
          />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Category')} htmlFor="categoryId" required />
          <Controller
            name="categoryId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.selectedProfessionalDevelopmentCategories}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.categoryId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Year')} htmlFor="schoolYearId" required />
          <Controller
            name="schoolYearId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.schoolYears}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.schoolYearId?.message} />
        </Col>
        <Col md={6}>
          <CustomLabel name={t('Participation type')} htmlFor="participationType" required />
          <Controller
            name="participationType"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={humanResourceStore.typesOfParticipation}
                className="react-select"
                classNamePrefix="select"
                placeholder={t('Select')}
              />
            )}
          />
          <CustomFormFeedback message={errors?.participationType?.message} />
        </Col>
        <Col md={12}>
          <CustomLabel name={t('Name')} for="title" required />
          <Controller
            name="title"
            control={control}
            render={({ field }) => (
              <Input id="title" invalid={!!errors.title} {...field} />
            )}
          />
          {errors?.title?.message
          && <CustomFormFeedback message={errors.title.message} />}
        </Col>
        <Col xs={12} className="text-center mt-2 pt-50">
          <Button
            type="submit"
            className="me-1"
            color="primary"
          >
            {professionalDevelopmentItem?.id ? t('Save changes') : t('Add') }
          </Button>
          <Button
            type="reset"
            color="secondary"
            outline
            onClick={() => dispatch(closeModal())}
          >
            {t('Close')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProfessionalDevelopmentForm;
