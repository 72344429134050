/* eslint-disable no-return-assign */
import { useEffect, useState } from 'react';
import { AppDispatch } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from 'reactstrap';
import CustomLabel from '@src/components/forms/CustomLabel';
import Select, { SingleValue } from 'react-select';
import SelectedOptions from '@src/types/SelectedOptions';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import CustomFormFeedback from '@src/components/forms/CustomFormFeedback';
import { handleApiErrorResponse } from '@src/utility/Utils';
import toast from 'react-hot-toast';
import RequestStatus from '@src/types/RequestStatus';
import { isManagePermissionActive } from '@src/utility/context/ActivePermissions';

import HumanResourcesState from '../../types/HumanResourceState';
import {
  createNewPersonalDevelopmentPlan,
  editPersonalDevelopmentPlan,
  getPersonalDevelopmentPlan,
  getProfessionalDevelopments,
  getSchoolYears,
} from '../../store';
import { PersonalDevelopmentPlanFormData, PersonalDevelopmentPlan } from '../../types/portfolio/PersonalDevelopmentPlan';

const PersonalDevelopmentPlanTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const humanResourceStore = useSelector((store: HumanResourcesState) => store.humanResources);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState<SingleValue<SelectedOptions>>();
  const humanResourcePermissions = JSON.parse(localStorage.getItem('userData') ?? '{}').permissions;

  const defaultValues: PersonalDevelopmentPlan = {
    levelOfEducation: '',
    skills: '',
    developingSkills: '',
    note: '',
  } as PersonalDevelopmentPlan;

  useEffect(() => {
    dispatch(getSchoolYears());
  }, []);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PersonalDevelopmentPlanFormData>({
    reValidateMode: 'onChange',
    // resolver: yupResolver(
    //   inventoryValidationSchema(),
    // ),
  });

  useEffect(() => {
    if (humanResourceStore.schoolYears.length) {
      setSelectedSchoolYear(
        humanResourceStore.schoolYears[humanResourceStore.schoolYears.length - 1],
      );
    }
  }, [humanResourceStore.schoolYears]);

  useEffect(() => {
    if (selectedSchoolYear) {
      dispatch(getPersonalDevelopmentPlan(
        `userId=${humanResourceStore.currentHumanResource.id}&schoolYearId=${selectedSchoolYear.value}`,
      ));
    }
  }, [selectedSchoolYear]);

  useEffect(() => {
    if (humanResourceStore.currentPersonalDevelopmentPlan?.id) {
      reset({
        levelOfEducation: humanResourceStore.currentPersonalDevelopmentPlan.levelOfEducation,
        skills: humanResourceStore.currentPersonalDevelopmentPlan.skills,
        developingSkills: humanResourceStore.currentPersonalDevelopmentPlan.developingSkills,
        note: humanResourceStore.currentPersonalDevelopmentPlan.note,
      });
    } else {
      reset(defaultValues);
    }
  }, [humanResourceStore.currentPersonalDevelopmentPlan?.id]);

  const handleSuccessfulSubmit: SubmitHandler<any> = async (
    values: PersonalDevelopmentPlanFormData,
  ) => {
    try {
      const newInventoryItem: PersonalDevelopmentPlan = {
        developingSkills: values.developingSkills,
        levelOfEducation: values.levelOfEducation,
        note: values.note,
        skills: values.skills,
        schoolYearId: selectedSchoolYear?.value,
        userId: humanResourceStore.currentHumanResource.id,
      } as PersonalDevelopmentPlan;

      if (humanResourceStore.currentPersonalDevelopmentPlan?.id) {
        await dispatch(editPersonalDevelopmentPlan(
          { id: +humanResourceStore.currentPersonalDevelopmentPlan.id, data: newInventoryItem },
        )).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Personal development plan successfully updated'), { position: 'top-right', duration: 3000 });
            dispatch(getProfessionalDevelopments(`userId=${humanResourceStore.currentPersonalDevelopmentPlan?.userId}`));
          }
        });
      } else {
        await dispatch(createNewPersonalDevelopmentPlan(newInventoryItem)).then((res) => {
          if (res.meta.requestStatus === RequestStatus.REQUEST_FULFILLED) {
            toast.success(t('Personal development plan successfully added'), { position: 'top-right', duration: 3000 });
            const { data } = res.payload as { data: PersonalDevelopmentPlan };
            dispatch(getProfessionalDevelopments(`userId=${data?.userId}`));
          }
        });
      }
    } catch (error) {
      handleApiErrorResponse(error);
    }
  };

  const canEdit = isManagePermissionActive(humanResourcePermissions, 'portfolioPersonalDevelopment');

  return (
    <div>
      <Card>
        <CardHeader className="border-bottom d-flex">
          <CardTitle tag="h4">{t('Personal development plan')}</CardTitle>
          <div>
            <CustomLabel name={t('Year')} htmlFor="schoolYearId" />
            <Select
              options={humanResourceStore.schoolYears}
              className="react-select"
              classNamePrefix="select"
              placeholder={t('Select')}
              value={selectedSchoolYear}
              onChange={(value) => setSelectedSchoolYear(value)}
            />
          </div>
        </CardHeader>
      </Card>
      <Card>
        <CardBody>
          <Form onSubmit={handleSubmit(handleSuccessfulSubmit)}>
            <Row>
              <Col md={6}>
                <CustomLabel name={t('Level of education')} for="levelOfEducation" />
                <Controller
                  name="levelOfEducation"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="levelOfEducation" invalid={!!errors.levelOfEducation} {...field} />
                  )}
                />
                {errors?.levelOfEducation?.message
                && <CustomFormFeedback message={errors.levelOfEducation.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Skills')} for="skills" />
                <Controller
                  name="skills"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="skills" invalid={!!errors.skills} {...field} />
                  )}
                />
                {errors?.skills?.message
                && <CustomFormFeedback message={errors.skills.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Developing skills')} for="developingSkills" />
                <Controller
                  name="developingSkills"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="developingSkills" type="textarea" invalid={!!errors.developingSkills} {...field} />
                  )}
                />
                {errors?.developingSkills?.message
                && <CustomFormFeedback message={errors.developingSkills.message} />}
              </Col>
              <Col md={6}>
                <CustomLabel name={t('Note')} for="note" />
                <Controller
                  name="note"
                  control={control}
                  render={({ field }) => (
                    <Input disabled={!canEdit} id="note" type="textarea" invalid={!!errors.note} {...field} />
                  )}
                />
                {errors?.note?.message
                && <CustomFormFeedback message={errors.note.message} />}
              </Col>
              <Col xs={12} className="text-center mt-2 pt-50">
                <Button
                  type="submit"
                  className="me-1"
                  color="primary"
                >
                  {t('Save changes')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default PersonalDevelopmentPlanTab;
